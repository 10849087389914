import axios from "axios";
import authHeader from "../auth/auth-header";
import { BASE_URL } from "../constants";
import axiosAPI from "../axiosApi";

const API_URL = "schemes/";


// For expired token
axios.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 201) {
           
       }
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl);
        }
    }
 );

const getAllSchemes = (offset='',limit=50) => {
    return axiosAPI
        .get(API_URL + `scheme/?limit=${limit}&offset=${offset}`)
        .then((response) => {
            return response.data;
        });
};
const getScheme = (id) => {
    return axiosAPI
        .get(API_URL + `scheme/${id}`)
        .then((response) => {
            return response.data;
        });
};
const getAllSubSchemes = (offset='',limit=50) => {
    return axiosAPI
        .get(API_URL + `subscheme/?limit=${limit}&offset=${offset}`)
        .then((response) => {
            return response.data;
        });
};

const searchSubSchemes = (params) => {
    return axiosAPI
        .get(API_URL + `subscheme/`, {params:params })
        .then((response) => {
            return response.data;
        });
};

const getSubScheme = (id) => {
    return axiosAPI
        .get(API_URL + `subscheme/${id}/`)
        .then((response) => {
            return response.data;
        });
};
const getAllFamilies = (params={}) => {
    return axiosAPI.get(`schemes/family/`,
            { params:params }
        );
};

const getFamily = (id) => {
    return axiosAPI.get(`schemes/family/${id}/`);
};

const getFamilySummary = (params={}) => {
    return axiosAPI.get(`schemes/family/summary/`,
            { params:params }
        );
};

const printFamilyCard = (id) => {
    // print_cards
    return axios({
        url: BASE_URL + API_URL + `family/${id}/print_cards/`,
        method: 'GET',
        responseType: 'blob', // important
        headers: authHeader()
    }).then((response) => {
        //const url = window.URL.createObjectURL(new Blob([response.data]));
        if (response.status === 200){
            window.open(URL.createObjectURL(response.data));
        }
        
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'cards.pdf'); //or any other extension
        // document.body.appendChild(link);
        //link.click();
    });
     
};

const getAllMembers = (params={}) => {
    return axiosAPI
        .get("schemes/member/", { params:params });
};
const getMember = (id) => {
    return axiosAPI
        .get(`schemes/member/${id}/`);
};

const setMemberStatus = (id, status) => {
    if(status == 'activate'){
        return axiosAPI
        .get(`schemes/member/${id}/activate/`);
    }else{
        return axiosAPI
        .get(`schemes/member/${id}/suspend/` );
    } 
};

const getMemberSummary = (params) => {
    return axiosAPI
        .get(API_URL + `member/summary/`, {params })
        .then((response) => {
            return response.data;
        });
};

const getSchemeMemberGenderSummary = () => {
    return axiosAPI
        .get(API_URL + `scheme/schemes_by_member_gender/`)
        .then((response) => {
            return response.data;
        });
};

const getSchemeMemberStatusSummary = () => {
    return axiosAPI
        .get(API_URL + `scheme/schemes_by_member_status`)
        .then((response) => {
            return response.data;
        });
};

const addScheme = (scheme) => {
    return axiosAPI
        .post(API_URL + "scheme/", scheme)
        .then((response) => {
            return response.data;
        });
};
const addSubScheme = (scheme) => {

    return axiosAPI
        .post(API_URL + "subscheme/", {
            name: scheme.name,
            shortname: scheme.shortname,
            scheme: scheme.scheme,
            year_formed: scheme.year_formed,
            target_population: scheme.target_population,
            district: scheme.district,
            subcounty: scheme.subcounty,
            parish: scheme.parish,
            village: scheme.village,
        })
        .then((response) => {
            return response.data;
        });
};

const addFamily = (family) => {
    return axiosAPI
        .post(API_URL + "family/", {
            name: family.name,
            subscheme: family.subscheme,
            posted_on: family.posted_on,
            guid: family.guid,
            owner: family.owner,
            village: family.village,
            joined_on: family.joined_on,
        })
        .then((response) => {
            return response.data;
        });
};

const addMember = (member) => {
    
    const user = JSON.parse(localStorage.getItem("user"));
    axiosAPI.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    axiosAPI.defaults.headers.common["Content-Type"] = "multipart/form-data";
    
    return axiosAPI
        .post(API_URL + "member/", member);
};

const updateScheme = (scheme, schemeId) => {
    
    return axiosAPI
        .put(API_URL + `scheme/${schemeId}/`, {
            name: scheme.name,
            shortname: scheme.shortname,
            target_population: scheme.target_population,
            body: scheme.body,
            fouded_on: scheme.founded_on,
            //owner: scheme.owner,
        })
        .then((response) => {
            return response.data;
        });
};

const updateFamily = (family, familyId) => {

    return axiosAPI
        .put(API_URL + `family/${familyId}/`, {
            name: family.name,
            subscheme: family.subscheme,
            posted_on: family.posted_on,
            guid: family.guid,
            // owner: family.owner,
            village: family.village,
            joined_on: family.joined_on,
        })
        .then((response) => {
            return response.data;
        });
};
const updateMember = (member, memberId) => {
    return axiosAPI
        .put(`schemes/member/${memberId}/`, member)
        .then((response) => {
            return response.data;
        });
};

const updateMemberPhoto = (data, memberId) => {
   
    return axiosAPI
        .post(API_URL + `member/photo/`, data)
        .then((response) => {
            return response.data;
        });
};

const updateSubScheme = (scheme, schemeId) => {
    
    return axiosAPI
        .put(API_URL + `subscheme/${schemeId}/`, scheme)
        .then((response) => {
            return response.data;
        });
};

const deleteScheme = (schemeId) => {
    return axiosAPI
        .delete(API_URL + `scheme/${schemeId}/`)
        .then((response) => {
            return response.data;
        });
};

const deleteSubScheme = (schemeId) => {
    return axiosAPI
        .delete(API_URL + `subscheme/${schemeId}/`)
        .then((response) => {
            return response.data;
        });
};

const suspendSubScheme = (schemeId) => {
    return axiosAPI
        .post(API_URL + `subscheme/${schemeId}/suspend/`);
};

const activateSubScheme = (schemeId) => {
    return axiosAPI
        .post(API_URL + `subscheme/${schemeId}/activate/`);
};

const deleteFamily = (familyId) => {
    return axiosAPI
        .delete(API_URL + `family/${familyId}/`)
        .then((response) => {
            return response.data;
        });
};

const deleteMember = (memberId) => {
    return axiosAPI
        .delete(API_URL + `member/${memberId}/`);
};

const disableMember = (memberId) => {
    return axiosAPI
        .get(API_URL + `member/${memberId}/disable`);
};

const enableMember = (memberId) => {
    return axiosAPI
        .get(API_URL + `member/${memberId}/enable`, );
};

export default {
    getAllSchemes,
    getScheme,
    getSchemeMemberStatusSummary,
    getSchemeMemberGenderSummary,
    getAllSubSchemes,
    searchSubSchemes,
    getSubScheme,
    getAllFamilies,
    getFamilySummary,
    getFamily,
    printFamilyCard,
    getAllMembers,
    getMember,
    getMemberSummary,
    setMemberStatus,
    addScheme,
    addSubScheme,
    addFamily,
    addMember,
    updateScheme,
    updateSubScheme,
    updateFamily,
    updateMember,
    updateMemberPhoto,
    deleteScheme,
    deleteSubScheme,
    deleteFamily,
    deleteMember,
    disableMember,
    enableMember,
    suspendSubScheme,
    activateSubScheme,
};
