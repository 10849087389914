import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button, Row, Col, Form, FloatingLabel,Spinner, Alert } from 'react-bootstrap';
import schemeService from '../../services/scheme/scheme-service';
import { formatHttpErrorMessage, humanFileSize } from '../common/helper_functions';
import AlertContext from '../widgets/alertPopup/AlertContext';

function MemberPhotoForm(props) {
    const memberId = props.memberId;
    const [member, setMember] = useState();
    const [loading, setLoading] = useState();
    const [memberPhoto, setMemberPhoto] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState();
    const [photoSize, setPhotoSize] = React.useState();
    let {setAlert} = useContext(AlertContext)
    
    useEffect(() => {
        getSelectedMember();
    },[]);

    const getSelectedMember = () => {
        schemeService.getMember(memberId).then((response) => {
            setMember(response.data)
        })
    }

    const handleFileSelect = (event) => {
        var size = event.target.files[0] ? event.target.files[0].size:0;
      setPhotoSize(size)
      if(size > 1000000){
        setAlert("Uploaded file is too big!", "danger")
        setErrorMessage("File size is greater than maximum limit of 1MB");
        return;
      }
		setMemberPhoto(event.target.files[0]);
        
	}

    const renderSelectedPhoto = (photo) => {
        return (<>
                  <Row>
                    <Col xs={7}>
                      <img alt="" src={URL.createObjectURL(photo)} width={200} />
                    </Col>
                    <Col xs={5}>
                        Size: {humanFileSize(photoSize)}
                    </Col>
                  </Row>
                  
                  </>
                )
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        
        const MAX_FILE_SIZE = 2048 // 5MB
        const fileSizeKiloBytes = memberPhoto.size / 1024
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            setErrorMessage("File size is greater than maximum limit of 2MB");
            setLoading(false);
            return
          }
		const item = new FormData();
        item.append("guid", member.guid);
        item.append("photo", memberPhoto);

        schemeService.updateMemberPhoto(item, memberId).then((response) => {
            setMemberPhoto(undefined);
            setLoading(false);
            props.onClose();
        }).catch((error) => {
            let message = formatHttpErrorMessage(error);
            setErrorMessage(message);
            setLoading(false);
        });
    }

    return (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
         
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
                Photo {}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {errorMessage && <Alert>{errorMessage}</Alert>}
            {loading ==true && (
                                        <center><Spinner
                                            animation="border"
                                            variant="warning"
                                        /></center>
                                    )}
            			
			<Form 	onSubmit={handleSubmit}
							>
                                {memberPhoto && renderSelectedPhoto(memberPhoto)}
								<Row className="g-3">
										
									<Col md="12">
                                        
                                    <FloatingLabel
																	controlId="floatingInput"
																	label="Photo"
																	className=""
																>
																	<Form.Control
																		type="file"
																		accept=".jpg,.png,.jpeg"
																		onChange={
																			handleFileSelect
																		}
																	/>
																	<Form.Control.Feedback type="invalid">
																		Please
																		provide
																		a valid
																		photo.
																	</Form.Control.Feedback>
																</FloatingLabel>
									</Col>

									<Col xs="12" className="d-grid gap-2">
										<Button
											variant="primary"
											type="submit"
											size="md"
                                            disabled={loading || photoSize >= 1000000}
										>
											Upload
										</Button>
									</Col>
								</Row>
							</Form>
					
          </Modal.Body>
        </Modal>
      )
}

export default MemberPhotoForm