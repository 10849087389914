import { useContext, useEffect, useState } from "react";
import {
	Button,
	Form,
	FloatingLabel,
	ButtonGroup,
	Card,
	Breadcrumb,
	Toast,
	Table,
	Row,
	Col,
	Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import helperService from "../../services/helpers/helper-service";
import schemeService from "../../services/scheme/scheme-service";
import MemberForm from "./MemberForm";
import Moment from "react-moment";
import BadgeWidget from "../widgets/BadgeWidget";
import Pager from "../common/Pager";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
import SummaryWidget from "../widgets/SummaryWidget";

const Members = () => {
  const currentUser = useContext(AuthDetailContext);
  const { setAlert } = useContext(AlertContext);
	const [members, setMembers] = useState([]);
	const [showForm, setShowForm] = useState(false);
	const [validated, setValidated] = useState(false);
	const [resultCount, setResultCount] = useState(0);
    const [districts, setDistricts] = useState([]);
	const [schemes, setSchemes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(50);
	const [activeParams, setActiveParams] = useState({})
  const [summary, setSummary] = useState({})
	const history = useHistory();

	useEffect(() => {
		loadMembers();
		//loadFamilies();
		loadDistricts();
		loadSchemes();
    fetchSummary({})
	}, []);
	const toggleForm = () => {
		setShowForm(!showForm);
	};

	const loadMembers = (nextPage=1, formParams={}) => {
		setLoading(true);
		const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
		let params = {offset:offset, ...formParams}
		if(Object.keys(formParams).length > 0){
			setActiveParams(formParams);
			params = {offset:offset, ...formParams}
		}else{
			params = {offset:offset, ...activeParams}
		}
		
		schemeService.getAllMembers(params).then(
			(members) => {
				setMembers(members.data.results);
				setResultCount(members.data.count);
				const pages = (members.data.count > 0) ? Math.floor(members.data.count/entriesPerPage) : 0
                setNumberOfPages(pages);               
                setActivePage(nextPage);
				setLoading(false);
			}
		).catch(error => {
        const resMessage = formatHttpErrorMessage(error);
				setAlert(resMessage, "danger");
				setLoading(false);
    });
	};

	const goToPage = (event, page) => {
        loadMembers(page);
    }



	function getFormValues(event) {
        const data = new FormData(event.currentTarget);
        return Object.fromEntries(data.entries());
      }

	const handleSearch = (event) => {
		const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        var nextPage=1;
        const params = getFormValues(event);
		setActiveParams(params);
		loadMembers(nextPage, params);
    fetchSummary(params)
	}

	const loadDistricts = () => {
        helperService.getAllDistricts().then(
            (districts) => {
                setDistricts(districts);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };

    const loadSchemes = () => {
            schemeService.getAllSubSchemes('1',300).then(
                (result) => {
                    setSchemes(result.results);
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
    
                    setAlert(resMessage, "danger");
                    setLoading(false);
                }
            );
        
    };

	const generateUniqueId = () => {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);
	};

	const handleDelete = (event, id) => {
		var result = window.confirm("are you sure you want to delete? ");
		if (result == true) {
			schemeService.deleteMember(id).then(
				() => {
					setAlert("Successfully Deleted Member");
					loadMembers();
				},
				(error) => {
					const resMessage = formatHttpErrorMessage(error);
					setAlert(resMessage, "danger");
					setLoading(false);
				}
			);
		} else {
		}
	};

  const fetchSummary = (params={}) => {
    schemeService.getMemberSummary(params).then(
      response => {
        setSummary(response)
      }
    )
  }


	return (
    <Row className="g-3">
      <Col xs="12" className="pt-3">
        <h4 className="m-0">Members </h4>
        <Breadcrumb className="border-bottom mb-3">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/schemes">Schemes</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/sub-schemes">Sub Schemes</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/families">Families</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Members</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col>
        <Card>
          <Card.Body className="bg-white d-flex justify-content-between align-content-center">
            <Form noValidate validated={validated} onSubmit={handleSearch}>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    required
                  />
                </Col>
               
                <Col>
                  <Form.Select
                    aria-label="select Sub Scheme"
                    name="family__subscheme"
                  >
                    <option value="">Choose Sub-Scheme</option>
                    {schemes.map((parent) => (
                      <option key={parent.id} value={parent.id}>
                        {parent.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    aria-label="select District"
                    name="family__district"
                  >
                    <option value="">Choose District</option>
                    {districts.map((district) => (
                      <option key={district.id} value={district.name}>
                        {district.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="select District" name="status">
                    <option value="">Choose Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Suspended">Suspended</option>
                    <option value="Pending">Pending</option>
                  </Form.Select>
                </Col>
                <Col xs="1">
                  <Button variant="primary" type="submit" size="md">
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12}>
         {summary &&  <SummaryWidget
                  primary={
                    <>
                      <h6>Total</h6>
                      <h4>{summary.total}</h4>
                    </>
                  }
                  secondary={
                    <>
                      <h6>Active</h6>
                      <h4>{summary.active_members}</h4>
                    </>
                  }
                  end={
                    <>
                      <h6>Inactive or Suspended</h6>
                      <h4>{summary.pending_members}</h4>
                    </>
                  }
                />}      
              </Col>
      
      <Col xs="12">
        <Card>
          <Card.Body>
            <Row>
              <Col className="text-end">
                <div>
                  <Button
                    variant="outline-info"
                    onClick={history.goBack}
                    size="sm"
                  >
                    <FiArrowLeft></FiArrowLeft>
                  </Button>
                </div>
              </Col>
            </Row>
            <Card.Title> Beneficiaries ({resultCount})</Card.Title>
            {loading ==false && <ReactHtmlTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button btn btn-success mb-3"
              table="table-to-xls"
              filename="member_export"
              sheet="tablexls"
              buttonText="Export Data to Excel Sheet"
            /> }
            <div className="table-responsive">
              <Table striped hover id="table-to-xls">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Phone</th>
                    <th>Posted On</th>
                    <th>Relationship</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading == false &&
                    members.map((member, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1 + (entriesPerPage * (activePage - 1))}
                        </td>
                        <td>
                          <Link to={`members/` + member.id}>
                            {member.firstname} {member.othername}
                            {member.lastname}
                          </Link>
                        </td>
                        <td>{member.gender}</td>
                        <td>{member.phone}</td>
                        <td>{member.posted_on}</td>
                        <td>{member.relationship}</td>
                        <td>
                          <BadgeWidget text={member.status} />
                        </td>
                        <td>
                          <Moment format="LLL">{member.created}</Moment>
                        </td>
                        <td>
                        {currentUser && (currentUser['is_superuser'] || currentUser['type'] ==='Org' || currentUser['id'] == member.owner) && (<>

                          <ButtonGroup aria-label="Actions" size="sm">
                            <Button variant="warning">
                              <Link
                                to={`/members/${member.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                {" "}
                                View
                              </Link>
                            </Button>
                            {member.status =='Pending' && 
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                handleDelete(e, member.id);
                              }}
                            >
                              Delete
                            </Button>}
                          </ButtonGroup>
                          </>)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {loading == false && (
                <Pager
                  numberOfPages={numberOfPages}
                  activePage={activePage}
                  goToPage={goToPage}
                />
              )}
              {loading && <Spinner animation="border" variant="warning" />}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default Members;
