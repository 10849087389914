import { useEffect, useState } from "react";
import {
	Button,
	Form,
	FloatingLabel,
	ButtonGroup,
	Card,
	Breadcrumb,
	Toast,
	Table,
	Row,
	Col,
	Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import importService from "../../services/scheme/family_import-service";
import schemeService from "../../services/scheme/scheme-service";

const ImportFamily = ({ currentUser }) => {
	const [importItems, setImportItems] = useState([]);
	const [subSchemes, setSubSchemes] = useState([]);
	const [ToastMessage, setToastMessage] = useState("");
	const [showToast, setShowToast] = useState(false);
	const [toastVariant, setToastVariant] = useState("light");
	const [showForm, setShowForm] = useState(false);
	const [subScheme, setSubScheme] = useState("");
	const [importFile, setImportFile] = useState("");
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(true);
	const history = useHistory();

	useEffect(() => {
		loadImports();
		loadSubschemes();
		setLoading(false);
	}, []);
	const toggleForm = () => {
		setShowForm(!showForm);
	};

	const loadImports = () => {
		importService.getAllImports().then(
			(importItems) => {
				setImportItems(importItems);
			},
			(error) => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setToastVariant("danger");
				setShowToast(true);
				setToastMessage(resMessage);
				setLoading(false);
			}
		);
	};

	const loadSubschemes = () => {
		schemeService.getAllSubSchemes().then(
			(subschemes) => {
				setSubSchemes(subschemes.results);
			},
			(error) => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setToastVariant("danger");
				setShowToast(true);
				setToastMessage(resMessage);
				setLoading(false);
			}
		);
        console.log(subSchemes);
	};

	const generateUniqueId = () => {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);
	};

	const handleDelete = (event, id) => {
		var result = window.confirm("are you sure you want to delete? ");
		if (result == true) {
			importService.deleteImport(id).then(
				() => {
					setToastVariant("light");
					setShowToast(true);
					setToastMessage("Successfully Deleted Import");
					loadImports();
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setToastVariant("danger");
					setShowToast(true);
					setToastMessage(resMessage);
					setLoading(false);
				}
			);
		} else {
		}
	};

	const activateImport = (event,id) => {
        var result = window.confirm("Are you sure you want to start this import? ");
        if (result == true) {
            importService.activateImport(id).then(
                (result) => {
                    setToastVariant("light");
                    setShowToast(true);
                    const output = (result.message !== undefined) ? result.message : "error"
                    setToastMessage(output);
                    loadImports();
                },
                (error) => {

                }
            )
        }
        else{

        }
    }

	const handleFileSelect = (event) => {
		setImportFile(event.target.files[0]);
	};

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		let invalid = false;
		event.preventDefault();
		if (form.checkValidity() === false) {
			//event.preventDefault();
			event.stopPropagation();
			invalid = true;
		}

		setValidated(true);

		if (invalid == false) {
			
			const item = new FormData();
			item.append("subscheme", subScheme);
			item.append("file", importFile);
			//item.append("fileName", importFile.name );

			importService.addImport(item).then(
				(result) => {
					setToastVariant("light");
					setShowToast(true);
					setToastMessage("Successfully created import");

					setShowForm(false);
					loadImports();
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setToastVariant("danger");
					setShowToast(true);
					setToastMessage(resMessage);
					setLoading(false);
				}
			);
		}
	};

	return (
		<Row className="g-3">
			<Col xs="12" className="pt-3">
				<Breadcrumb className="border-bottom mb-3">
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/schemes">Schemes</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/sub-schemes">Sub Schemes</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/families">Families</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active>Import</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col>
				<Card>
					<Card.Body className="bg-white d-flex justify-content-between align-content-center">
						<h4 className="m-0">Family Import </h4>
						<div>
							<Button
								variant="outline-info"
								onClick={history.goBack}
								size="sm"
							>
								<FiArrowLeft></FiArrowLeft>
							</Button>
							<Button
								variant="primary"
								onClick={toggleForm}
								size="sm"
							>
								Add
							</Button>
						</div>
					</Card.Body>
				</Card>
			</Col>
			{showForm && (
				<Col xs="12">
					<Card>
						<Card.Body>
							<Card.Subtitle className="mb-3">
								Import Family Form
							</Card.Subtitle>
							<Form
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
							>
								<Row className="g-3">
									<Col md="3">
										<FloatingLabel
											controlId="floatingSelect"
											label="Select Scheme"
										>
											<Form.Select
												aria-label="select Family"
												value={subScheme}
												onChange={(e) =>
													setSubScheme(
														e.target.value
													)
												}
												required
											>
												<option value="">None</option>
												{subSchemes.map((parent) => (
													<option
														key={parent.id}
														value={parent.id}
													>
														{parent.name}
													</option>
												))}
											</Form.Select>
											<Form.Control.Feedback type="invalid">
												Please select family.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>						
								
									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="CSV File"
											className=""
										>
											<Form.Control
																		type="file"
																		accept=".csv"
																		onChange={
																			handleFileSelect
																		}
																	/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid file.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>

									<Col xs="12">
										<Button
											variant="primary"
											type="submit"
											size="sm"
										>
											Save
										</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			)}
			<Col xs="12">
				<Card>
					<Card.Body>
						<Card.Title>Current Imports </Card.Title>
						<div className="table-responsive">
							<Table striped hover>
								<thead>
									<tr>
										<th>#</th>
										<th>FileName</th>
										<th>Sub Scheme</th>
										<th>Created</th>
										<th>status</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{loading && (
										<Spinner
											animation="border"
											variant="warning"
										/>
									)}
									{importItems.map((item, index) => (
										<tr key={item.id}>
											<td>{index + 1}</td>
											<td>{item.file}</td>
											<td>{item.subscheme_shortname}</td>
											<td>{item.created}</td>
											<td>{item.status}</td>

											<td>
												<ButtonGroup
													aria-label="Actions"
													size="sm"
												>

													{item.status =='Draft' && <Button
                                                        variant="success"
                                                        onClick={(e) => {
                                                            activateImport(
                                                                e,
                                                                item.id
                                                            );
                                                        }}
                                                    >
                                                        Import Data
                                                    </Button>}
													<Button
														variant="danger"
														onClick={(e) => {
															handleDelete(
																e,
																item.id
															);
														}}
													>
														Delete
													</Button>
												</ButtonGroup>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</Card.Body>
				</Card>
			</Col>
			<Toast
				onClose={() => setShowToast(false)}
				show={showToast}
				delay={6000}
				autohide
				bg={toastVariant}
			>
				<Toast.Header>
					<img
						src="holder.js/20x20?text=%20"
						className="rounded me-2"
						alt=""
					/>
					<strong className="me-auto">Infomation</strong>
					<small>now</small>
				</Toast.Header>
				<Toast.Body>{ToastMessage}</Toast.Body>
			</Toast>
		</Row>
	);
};
export default ImportFamily;
