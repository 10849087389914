export default function authHeader() {
    const basicauth = localStorage.getItem("user");

    if (basicauth) {
        const user = JSON.parse(basicauth);
        return { Authorization: "Bearer " + user.access };
        //axios.defaults.headers.common['Authorization'] = 'Authorization: Basic ' + basicauth ;
        // return { Authorization: 'Basic ' + basicauth };
        // return { 'x-access-token': user.accessToken };
    } else {
        return false;
    }
}
