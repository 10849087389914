import { Row, Col, Card, Table, Badge, Button, Form } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import schemeService from "../services/scheme/scheme-service";
import fundService from "../services/fund/fund-service";
import subscriptionService from "../services/subscription/subscription-service";
import providerService from "../services/provider/provider-service";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import AuthDetailContext from "./common/AuthDetailContext";
import React from "react";
import useAlert from "./widgets/alertPopup/useAlert";
import { formatNumber } from "./common/helper_functions";
import { EyeFill } from "react-bootstrap-icons";
import RecentClaimsList from "./widgets/RecentClaimsList";
import RateWidget from "./widgets/RateWidget";

function StaffDashboard() {
  const  currentUser = useContext(AuthDetailContext);
    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [funds, setFunds] = useState([]);
    const [memberSummary, setMemberSummary] = useState();
    const [paymentSummary, setPaymentSummary] = useState();
    const [policySummary, setPolicySummary] = useState();
    const [claimSummary, setClaimSummary] = useState();
    const [year, setYear] = useState("");
    const variant = [
        'Primary',
        'Secondary',
        'Success',
        'Info',
        'Warning',
        'Light',
        'Dark',
      ]
   
      useEffect(() => {
        loadFunds(year);
        loadMemberSummary(year);
        loadPaymentSummary(year);
        loadPolicySummary(year);
        loadClaimSummary(year);
    }, [year]);

    const loadMemberSummary = (year) =>{
        let params= {};
        if(year){
            params={year:year}
        }
        schemeService.getMemberSummary(params).then(
            (data) => {
                setMemberSummary(data)
            }
        )
    }

    const loadPaymentSummary = (year) =>{
        let params= {};
        if(year){
            params={year:year}
        }
        subscriptionService.getPaymentSummary(params).then(
            (data) => {
                setPaymentSummary(data)
            }
        )
    };

    const loadPolicySummary = (year) =>{
        let params= {};
        if(year){
            params={year:year}
        }
        subscriptionService.getPolicySummary(params).then(
            (data) => {
                setPolicySummary(data)
            }
        )
    };

    const loadClaimSummary = (year) =>{
        let params= {};
        if(year){
            params={year:year}
        }
        providerService.getClaimSummary(params).then(
            (data) => {
                setClaimSummary(data)
            }
        )
    };

    const loadFunds = (filterYear) => {
        const offset = '';
        let params = {limit:3, year}
        if(currentUser && currentUser['is_staff']){
            params = {limit:5, category:'Insurance', year:filterYear}
        }
    
        fundService.getAllFunds(params).then(
            (result) => {
                setFunds(result.results);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setAlert(resMessage)
            }
        );
    };
   

    return (
        <Row className="g-3">
          <Col md={3}>
            <Form.Select onChange={(e) => setYear(e.target.value)}>
                <option value="">Current Year</option>
                <option value="2023">2023</option>
            </Form.Select>
          </Col>
            <Col md='12'>
                <Row>
                    
                <Col md="3">
                    {funds.map((fund, index)  => (
                        <Card className="mb-2" text="white" bg={variant[index].toLowerCase()}>
                        <Card.Body>
                            <Card.Title className="fs-5 fw-bold">
                                {fund.balance}
                            </Card.Title>
                            <Row>
                              <Col xs={7}>
                              <Card.Subtitle className="mb-2">{fund.scheme_name} {fund.category=='Insurance' ? 'Claims Fund' : fund.category}</Card.Subtitle>
                              </Col>
                              {fund.category =='Insurance' &&
                              <Col>
                                <Card.Subtitle className="mb-2">{claimSummary && ((claimSummary.scheme_summary.find(item => item.subscheme__scheme === fund.scheme)?.total_sum/fund.balance)*100).toFixed(2)}%</Card.Subtitle>                              </Col>}
                            </Row>
                            
                        </Card.Body>
                        <Card.Footer><Moment format="lll">{fund.updated}</Moment></Card.Footer>
                    </Card>
                    ))}
                    
                </Col>
            
                <Col md="9">
                    <Row>
                        <Col>
                            <Card className="mb-2">
                                <Card.Body>
                                <Card.Text>Total Members</Card.Text>
                                    <Card.Title className="fs-5 fw-bold">
                                      {memberSummary && memberSummary.total} 
                                    </Card.Title>
                                    {memberSummary && <Row>
                                        <Col><strong>Pending</strong>: {formatNumber(memberSummary.pending_members)}</Col>
                                        <Col><strong>Active</strong>: {formatNumber(memberSummary.active_members)}</Col>
                                    </Row>}
                                   
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="mb-2">
                                <Card.Body>
                                <Card.Text>Total Payments</Card.Text>
                                    <Card.Title className="fs-5 fw-bold">
                                    {paymentSummary ? formatNumber(paymentSummary.amount) : 0}
                                    </Card.Title>
                                    Count: {paymentSummary ? formatNumber(paymentSummary.total) : 0}
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="mb-2 ">
                                <Card.Body>
                                <Card.Text>Total Policies</Card.Text>
                                    <Card.Title className="fs-5 fw-bold">
                                        {policySummary ? formatNumber(policySummary.total_sum) : 0}
                                    </Card.Title>
                                    Count: {policySummary ? formatNumber(policySummary.total) : 0}
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="mb-2">
                                <Card.Body>
                                <Card.Text>Total Claims</Card.Text>
                                    <Card.Title className="fs-5 fw-bold">
                                       {claimSummary ? formatNumber(claimSummary.total_sum) : 0 }
                                    </Card.Title>
                                    <Row>
                                        <Col><strong>No. Claims:</strong> {claimSummary ? formatNumber(claimSummary.total) : 0 }</Col>
                                        <Col><strong>Bills:</strong> {claimSummary ? formatNumber(claimSummary.total_claim_bill) : 0 }</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={4} xs={6}>
                            {memberSummary && <RateWidget rate={((memberSummary.active_members/memberSummary.target_population)*100).toFixed(2) +"%"} label="Penetration Rate"/>}
                        </Col>
                        <Col md={4} xs={6}>
                            {claimSummary && <RateWidget rate={((claimSummary.total_sum/claimSummary.total_claim_bill)*100).toFixed(2) +"%"} label="Protection Rate"/>}
                        </Col>
                        <Col md={4} xs={6}>
                        {claimSummary && memberSummary && <Card>
                            <Card.Body>
                                
                                <Row>
                                    <Col md={5}>
                                    <h3> {((claimSummary.total/memberSummary.active_members)*100).toFixed(2) +"%"}</h3>
                                    </Col>
                                    <Col md={7}>
                                    <h4>Frequency Rate</h4>
                                    </Col>
                                    <Col md={6}>
                                    <strong>InPatient:</strong> {((claimSummary.claims_ipc_count/memberSummary.active_members)*100).toFixed(2)}
                                    </Col>
                                    <Col md={6}>
                                    <strong>OutPatient:</strong> {((claimSummary.claims_opc_count/memberSummary.active_members)*100).toFixed(2)}
                                    </Col>
                                </Row>
                            
                            
                        
                            </Card.Body>
                            
                            </Card>}
                        </Col>
                        
                    </Row>
                    <Row>

                        <Col md='12' className="mt-5">
                            <Card>
                                <Card.Header>
                                    Recent Claims 
                                </Card.Header>
                        
                                <RecentClaimsList />
                            </Card>
                        </Col>
                    </Row>
                </Col>

                </Row>
            </Col>
        </Row>
    );
}

export default StaffDashboard