import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, FloatingLabel,Spinner, Alert } from 'react-bootstrap';
import subscriptionService from "../../services/subscription/subscription-service";
import { useHistory } from "react-router-dom";
import { formatHttpErrorMessage } from "../common/helper_functions";

export default function PaymentForm(props) {
    const [showForm, setShowForm] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0.0);
    const [paymentName, setPaymentName] = useState("");
    const [paymentNarration, setPaymentNarration] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("Cash");
    const [paymentPostDate, setPaymentPostDate] = useState("");
    const [paymentReference, setPaymentReference] = useState("");
    const [paymentOffer, setPaymentOffer] = useState("");
    const [paymentFundSource, setPaymentFundSource] = useState("Pocket");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const [message, setMessage] = useState();
    const history = useHistory();

    useEffect(() => {
        loadOffers();
        
    }, []);

    const loadOffers = () => {
        let familyGuid = props.familyGuid;
        if (familyGuid == undefined){
            subscriptionService.getAllOffers().then(
                (payload) => {
                    setOffers(payload);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                }
            );
        }else{
            subscriptionService.filterOffers({family:familyGuid, status:"Open"}).then(
                (payload) => {
                    setOffers(payload.data.results);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                }
            );
        }
       
    };

    const generateUniqueId = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);
        
        if (invalid == false) {
            setLoading(true);
            let payload = {
                post_date: paymentPostDate,
                reference: paymentReference,
                amount: paymentAmount,
                name: paymentName,
                narration: paymentNarration,
                method: paymentMethod,
                offer: paymentOffer,
                guid: generateUniqueId(),
                fund_source: paymentFundSource,
            };
            subscriptionService.addPayment(payload).then(
                (result) => {
                    setPaymentAmount(0.0);
                    setPaymentMethod("");
                    setPaymentName("");
                    setPaymentOffer("");
                    setPaymentNarration("");
                    setPaymentReference("");
                    setPaymentMethod("Cash");
                    setLoading(false);
                    setShowForm(false);
                    setValidated(false);
                    props.onClose();
                },
                (error) => {
                    let message = formatHttpErrorMessage(error);
                    setMessage(message);
                    setLoading(false);
                }
            );
        }
    };

  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
   
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter" >
        Payment Form
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Please fill in the form below to create a Payment entry</p>
      {message && <Alert variant="danger">{message}</Alert>}
      {loading ==true && (<center><Spinner animation="border" variant="warning" /></center>)}
      <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="g-3">
                                <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Offer"
                                        >
                                            <Form.Select
                                                aria-label="select Offer"
                                                value={paymentOffer}
                                                onChange={(e) =>
                                                    setPaymentOffer(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {offers.map((offer, i) => (
                                                    <option value={offer.guid} key={i}>
                                                        { offer.product_name + " (" + offer.start_date + ")" }
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select offer
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                value={paymentAmount}
                                                onChange={(e) =>
                                                    setPaymentAmount(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Narration"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="payment Description"
                                                value={paymentNarration}
                                                onChange={(e) =>
                                                    setPaymentNarration(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid
                                                description.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Source of Funds"
                                        >
                                            <Form.Select
                                                aria-label="select Fund source"
                                                value={paymentFundSource}
                                                onChange={(e) =>
                                                    setPaymentFundSource(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Pocket">
                                                    Pocket
                                                </option>
                                                <option value="Savings">
                                                    Savings
                                                </option>
                                                <option value="Prepaid">
                                                    Prepaid
                                                </option>
                                                <option value="Frozen">
                                                Frozen
                                                </option>
                                                <option value="Institution">
                                                Institution
                                                </option>
                                                <option value="Loan">
                                                Loan
                                                </option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Paid By"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Paid By"
                                                value={paymentName}
                                                onChange={(e) =>
                                                    setPaymentName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name .
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                   
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Payment Method"
                                        >
                                            <Form.Select
                                                aria-label="select Payment Method"
                                                value={paymentMethod}
                                                onChange={(e) =>
                                                    setPaymentMethod(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Cash">
                                                    Cash
                                                </option>
                                                <option value="Cheque">
                                                    Cheque
                                                </option>
                                                <option value="Bank">
                                                    Bank
                                                </option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Reference/Receipt"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Reference"
                                                value={paymentReference}
                                                onChange={(e) =>
                                                    setPaymentReference(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid
                                                reference/Receipt.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Payment Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                value={paymentPostDate}
                                                onChange={(e) =>
                                                    setPaymentPostDate(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    
                                    <Col xs="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="md"
                                            disabled={loading}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
    </Modal.Body>
  </Modal>
  )
}
