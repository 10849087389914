import React, { useState } from 'react'
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import subscriptionService from "../../services/subscription/subscription-service";

function PolicyStatusForm(props) {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        status: "",
        reason: "",
        notes: "",
        policy: props.policyId
    })

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }
        setLoading(true);
        setValidated(true);
        await subscriptionService.changePolicyStatus(props.policyId, formData).then(response => {
            props.onSuccess("Policy " + props.policyId + " status has changed to " + formData.status);
        }).catch(error => {
            alert("An error has occurred!")
        })
    }

  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
   
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter" >
        Policy Status Form
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Please fill in the form below</p>
      <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
            <Row className="g-3">
            <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Status"
                                        >
                                            <Form.Select
                                                aria-label="select Status"
                                                name='status'
                                                value={formData.status} 
                                                onChange={(e) => handleChange(e)}
                                                required
                                            >
                                                <option></option>
                                                <option value="Active">Active</option>
                                                <option value="Suspended">Suspended</option>
                                                <option value="Expired">Expired</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Status
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
            </Col>
            <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Reason for action"
                                        >
                                            <Form.Select
                                                aria-label="select Reason"
                                                name='reason'
                                                value={formData.reason} 
                                                onChange={(e) => handleChange(e)}
                                                required
                                            >
                                                <option value="Limit">Exceeded minimum cover limit</option>
                                                <option value="Fraud">Committed Fraud</option>
                                                <option value="Consumption">Over consumption</option>
                                                <option value="Payment">Over consumption</option>
                                                <option value="Waiting">Waiting period</option>
                                                <option value="Error">System Error</option>
                                                <option value="Other">Other</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select reason
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
            </Col>

            <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Notes"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                name='notes'
                                                value={formData.notes} 
                                                onChange={(e) => handleChange(e)}
                                                as="textarea" rows={3}
                                                required
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="md"
                                            disabled={loading}
                                        >
                                            Save
                                        </Button>
                                    </Col>

            </Row>
    </Form>
    </Modal.Body>
    </Modal>
  )
}

export default PolicyStatusForm