import { useContext, useEffect, useState } from "react";
import {
    Grid,
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Table,
    Row,
    Col,
    Spinner,
    Pagination
} from "react-bootstrap";
import reportService from "../../services/reports/reports-service";
import schemeService from "../../services/scheme/scheme-service";
import { Link, useHistory } from "react-router-dom";
import { formatHttpErrorMessage } from "../common/helper_functions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ReportForm from "./ReportForm";
import AlertContext from "../widgets/alertPopup/AlertContext";

const Reports = ({ currentUser }) => {
    const [parentSchemes, setParentSchemes] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage,setEntriesPerPage] = useState(50);
    const history = useHistory();
    const queryClient = useQueryClient()
    let {setAlert} = useContext(AlertContext);

    const { isLoading, isError, error, data:reports, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["reports"],
      queryFn: () => reportService.getAllReports(),
      keepPreviousData: true,
      refetchInterval: 18000,
    });

    const toggleForm = () => {
        setShowForm(!showForm);
    };



    const goToPage = (event,page) => {
        //loadReports(page);
    }

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            reportService.deleteReport(id).then(
                (response) => {
                    //loadReports();
                }
            );
        }
    };
  
    const handleOnSuccess = (message="Report is being processed!") => {
        setShowForm(false);
        queryClient.invalidateQueries({ queryKey: ['reports'] });
        setAlert(message)
    }

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">Report</h4>
                        <Button
                            variant="primary"
                            size="sm"
                            className="m-0"
                            onClick={toggleForm}
                        >
                            Add Report
                        </Button>
                        <ReportForm show={showForm} onHide={() => setShowForm(false)} onSuccess={handleOnSuccess} />
                    </Card.Body>
                </Card>
            </Col>
        
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Card.Title className="fs-6">
                            Current Schemes
                        </Card.Title>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th> 
                                        <th>Scheme</th> 
                                        <th>Type</th> 
                                        <th>Status</th> 
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ):isError ? <></> :
                                     reports.data.results.map((report, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{report.name}</td>
                                            <td>{report.start_date}</td>   
                                            <td>{report.end_date}</td>
                                            <td>{report.scheme}</td>    
                                            <td>{report.report_type}</td>
                                            <td>{report.status}</td>                          
                                            <td>
                                               {report.status =='Complete' ? <a className="btn btn-sm btn-success" href={report.status =='Complete' ? report.file_path : ""} size="sm" >
                                                        Download
                                                </a>:<Button variant="success" size="sm" disabled>Download</Button>}
                                                    
                                                   &nbsp;
                                                    <Button
                                                        size="sm"
                                                        variant="danger"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                report.id
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                            
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <Pagination>
                        {(() => { let items = [];
                        for (let number = 1; number <= numberOfPages; number++) {
                            items.push(
                                <Pagination.Item onClick={(e) => {
                                    goToPage(
                                        e,
                                        number
                                    );
                                }} key={number} active={number === activePage}>
                                {number}
                                </Pagination.Item>
                                );
                            }
                            return items;
                        })()
                        }
                        </Pagination>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};
export default Reports;
