import { useEffect, useState, useContext } from "react";
import {
  Button,
  Form,
  FloatingLabel,
  ButtonGroup,
  Card,
  Breadcrumb,
  Toast,
  Table,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { PencilSquare, Trash, EyeFill, Plus } from "react-bootstrap-icons";
import helperService from "../../services/helpers/helper-service";
import schemeService from "../../services/scheme/scheme-service";
import FamilyForm from "./FamilyForm";
import Moment from "react-moment";
import BadgeWidget from "../widgets/BadgeWidget";
import Pager from "../common/Pager";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
import SummaryWidget from "../widgets/SummaryWidget";

const Families = (props) => {
  const { subSchemeId, name } = props.match.params;
  const { setAlert } = useContext(AlertContext);
  const currentUser = useContext(AuthDetailContext);
  const [schemes, setSchemes] = useState([]);
  const [families, setFamilies] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [familyScheme, setFamilyScheme] = useState("");
  const [familyPostedOn, setFamilyPostedOn] = useState("");
  const [familyVillage, setFamilyVillage] = useState("");
  const [validated, setValidated] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const [districts, setDistricts] = useState([]);
  const [parentSchemes, setParentSchemes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFamilyForm, setShowFamilyForm] = useState(false);
  const [summary, setSummary] = useState({})
  const [numberOfPages,setNumberOfPages] = useState(1);
  const [activePage,setActivePage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [activeParams, setActiveParams] = useState({})

  const history = useHistory();

  useEffect(() => {
    loadParentSchemes();
    loadSchemes();
    loadFamilies();
    loadDistricts();
    fetchSummary({});
    if (subSchemeId !== undefined) {
      //modify to pick only one subscheme by id.
      setFamilyScheme(subSchemeId);
    }
  }, []);
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const loadParentSchemes = () => {
    schemeService.getAllSchemes().then(
      (result) => {
        setParentSchemes(result.results);
      },
      (error) => {
        const resMessage = formatHttpErrorMessage(error)
        setAlert(resMessage, "danger");
        setLoading(false);
      }
    );
  };

  const loadDistricts = () => {
    helperService.getAllDistricts().then(
      (districts) => {
        setDistricts(districts);
      },
      (error) => {
        const resMessage = formatHttpErrorMessage(error);
        setAlert(resMessage, "danger");
        setLoading(false);
      }
    );
  };

  const loadSchemes = () => {
    if (subSchemeId !== undefined) {
      schemeService.getSubScheme(subSchemeId).then(
        (result) => {
          let r = [];
          r.push(result);
          setSchemes(r);
        },
        (error) => {}
      );
    } else {
      schemeService.getAllSubSchemes("0", 300).then(
        (result) => {
          setSchemes(result.results);
        },
        (error) => {
          const resMessage = formatHttpErrorMessage(error);
          setAlert(resMessage, "danger");
          setLoading(false);
        }
      );
    }
  };
  const loadFamilies = (nextPage=1, formParams={}) => {
    const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
    let params = {offset:offset, ...formParams}
    if(Object.keys(formParams).length > 0){
        setActiveParams(formParams);
        params = {offset:offset, ...formParams}
    }else{
        params = {offset:offset, ...activeParams}
    }

    schemeService.getAllFamilies(params).then(
      (response) => {
        setFamilies(response.data.results);
        setResultCount(response.data.count);
        const pages = (response.data.count > 0) ? Math.floor(response.data.count/entriesPerPage) : 0
        setNumberOfPages(pages);               
        setActivePage(nextPage);
        setLoading(false);
        
      },
      (error) => {
        const resMessage = formatHttpErrorMessage(error);
        setAlert(resMessage, "danger");
        setLoading(false);
      }
    );
  };

  function getFormValues(event) {
    const data = new FormData(event.currentTarget);
    return Object.fromEntries(data.entries());
  }

  const goToPage = (event, page) => {
    loadFamilies(page);
  };

  const fetchSummary = async (params) => {
    await schemeService.getFamilySummary(params).then(response =>{
       setSummary(response.data)
    }).catch(error => {
        
    }) 
  }

  const handleSearch = (event) => {
    const form = event.currentTarget;
    let invalid = false;
    event.preventDefault();
    var nextPage = 1;
    setLoading(true);
    setFamilies([]);
    const params = getFormValues(event);
    loadFamilies(nextPage, params);
    fetchSummary(params)
    setLoading(false);
  };

  const generateUniqueId = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  const handleDelete = (event, id) => {
    var result = window.confirm("are you sure you want to delete? ");
    if (result == true) {
      schemeService.deleteFamily(id).then(
        () => {
          setAlert("Successfully Deleted Family", "success");
          loadFamilies();
        },
        (error) => {
          const resMessage = formatHttpErrorMessage(error);
          setAlert(resMessage, "danger");
          setLoading(false);
        }
      );
    } else {
    }
  };


  return (
    <Row className="g-3">
      {!props.basic && (
        <Col xs="12" className="pt-3">
          <h4 className="m-0">Families</h4>
          <Breadcrumb className="border-bottom mb-3">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/schemes"> Schemes </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/sub-schemes"> Sub Schemes </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Families</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      )}
      <Col xs="12">
        <Card>
          <Card.Body className="bg-white d-flex justify-content-between align-content-center">
            <Form noValidate validated={validated} onSubmit={handleSearch}>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    required
                  />
                </Col>
                <Col md="2">
                  <Form.Select
                    aria-label="select Scheme"
                    name="subscheme__scheme"
                  >
                    <option value="">Choose Scheme</option>
                    {parentSchemes.map((parent) => (
                      <option key={parent.id} value={parent.id}>
                        {parent.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="select Sub Scheme" name="subscheme">
                    <option value="">Choose Sub-Scheme</option>
                    {schemes.map((parent) => (
                      <option key={parent.id} value={parent.id}>
                        {parent.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="select District" name="district">
                    <option value="">Choose District</option>
                    {districts.map((district) => (
                      <option key={district.id} value={district.name}>
                        {district.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select aria-label="Choose Status" name="status">
                    <option value="">Choose Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Suspended">Suspended</option>
                    <option value="Pending">Pending</option>
                  </Form.Select>
                </Col>
                <Col xs="1">
                  <Button variant="primary" type="submit" size="md">
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12}>
         {summary &&  <SummaryWidget
                  primary={
                    <>
                      <h6>Total</h6>
                      <h4>{summary.total}</h4>
                    </>
                  }
                  secondary={
                    <>
                      <h6>Active Families</h6>
                      <h4>{summary.active}</h4>
                    </>
                  }
                  end={
                    <>
                      <h6>Inactive or Suspended</h6>
                      <h4>{summary.Inactive}</h4>
                    </>
                  }
                />}      
              </Col>            
      <Col xs="12">
        <Card>
          <Card.Body>
            <Row>
              <Col xs="12" className="text-end">
                <Button
                  variant="success"
                  onClick={() => setShowFamilyForm(true)}
                >
                  <Plus />
                  Create Family
                </Button>
                <FamilyForm
                  show={showFamilyForm}
                  onHide={() => setShowFamilyForm(false)}
                />{" "}
                &nbsp; &nbsp;
                <Button variant="danger" size="md">
                  <Link
                    to={`/member/import`}
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    {" "}
                    Import
                  </Link>
                </Button>
              </Col>
              
              <Col xs="12">
                <Card.Title>Current Families ({resultCount}) </Card.Title>
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Sub Scheme</th>
                        <th>District</th>
                        <th>Sub-County</th>
                        <th>Parish</th>
                        <th>Members</th>
                        <th>Created</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {families.map((family, index) => (
                        <tr key={index}>
                          <td>
                            {index + 1 + entriesPerPage * (activePage - 1)}
                          </td>
                          <td>
                            <Link to={`/family/${family.id}`}>
                              {family.name}
                            </Link>
                          </td>
                          <td>{family.sub_scheme_name}</td>
                          <td>{family.district}</td>
                          <td>{family.subcounty}</td>
                          <td>{family.parish}</td>
                          <td>{family.members}</td>
                          <td>
                            <Moment format="LLL">{family.created}</Moment>
                          </td>
                          <td>
                            <BadgeWidget text={family.status} />
                          </td>
                          <td>
                            {currentUser &&
                              (currentUser["is_superuser"] ||
                                currentUser["is_staff"] === false) && (
                                <>
                                  <Link to={`/family/${family.id}`}>
                                    <EyeFill />
                                  </Link>{" "}
                                  &nbsp;
                                  <Link to={`/family/${family.id}/edit`}>
                                    <PencilSquare />
                                  </Link>{" "}
                                  &nbsp;
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      handleDelete(e, family.id);
                                    }}
                                  >
                                    <Trash />
                                  </a>
                                </>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {loading == false && (
                    <Pager
                      numberOfPages={numberOfPages}
                      activePage={activePage}
                      goToPage={goToPage}
                    />
                  )}
                  {loading == true && (
                    <center>
                      <Spinner animation="border" variant="warning" />
                    </center>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default Families;
