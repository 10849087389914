import { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { PencilSquare, Trash, EyeFill, Calculator, Power} from "react-bootstrap-icons";
import productService from "../../services/product/product-service";
import schemeService from "../../services/scheme/scheme-service";
import subscriptionService from "../../services/subscription/subscription-service";
import fundService from "../../services/fund/fund-service";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
import Pager from "../common/Pager";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import BadgeWidget from "../widgets/BadgeWidget";

const Offers = (props) => {
    const { familyId, productId } = props.match.params;
    const currentUser = useContext(AuthDetailContext);
    const {setAlert} = useContext(AlertContext);
    const [products, setProducts] = useState([]);
    const [families, setFamilies] = useState([]);
    const [offers, setOffers] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [offerStartDate, setOfferStartDate] = useState("");
    const [offerEndDate, setOfferEndDate] = useState("");
    const [offerFamily, setOfferFamily] = useState("");
    const [offerProduct, setOfferProduct] = useState("");
    const [offerPostDate, setOfferPostDate] = useState("");
    const [offerMembers, setOfferMembers] = useState(1);
    const [offerRegFees, setOfferRegFees] = useState(0);
    const [offerSubFees, setOfferSubFees] = useState(0);
    const [offerMemberFees, setOfferMemberFees] = useState(0);
    const [offerOtherFees, setOfferOtherFees] = useState(0);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [parentSchemes, setParentSchemes] = useState([]);
    const [schemes, setSchemes] = useState([]);
    const [memberFees, setMemberFees] = useState([])
    const [subFees, setSubFees] = useState([]);
    const [regFees, setRegFees] = useState([]);
    const [otherFees, setOtherFees] = useState([]);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(50);
    const [activeParams, setActiveParams] = useState({})
    const [selectedItems, setSelectedItems] = useState([]);
    const [summary, setSummary] = useState({})
    const history = useHistory();

    useEffect(() => {
        loadProducts();
        loadOffers();
        loadParentSchemes();
        loadFees();
        loadSchemes();
        fetchSummary();
        if (familyId !== undefined){
            const params= {guid: familyId};
            loadFamilies(params);
            setOfferFamily(familyId);
        }else{
            loadFamilies();
        }
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const changeSelectedItem = (e, index) => {
        console.log(e.target.value)
        const activeData = document.getElementById(index).checked
        console.log(activeData,"activeData")
        if(activeData == true){
            setSelectedItems (oldData=>[...oldData,e.target.value])
        }else{
            setSelectedItems(selectedItems.filter(values=>values !==e.target.value))
        }
    }

    const generateUniqueId = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };

    const loadParentSchemes = () => {
        schemeService.getAllSchemes().then(
            (result) => {
                setParentSchemes(result.results);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };

    const getFormValues = (event) => {
        const data = new FormData(event.currentTarget);
        return Object.fromEntries(data.entries());
      }

    const loadSchemes = () => {
    
        schemeService.getAllSubSchemes('0',300).then(
                (result) => {
                    setSchemes(result.results);
                },
            ).catch(error => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage, "danger");
                    setLoading(false);
            });
        
    };


    const loadProducts = () => {
        productService.getAllProducts().then(
            (payload) => {
                setProducts(payload);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };
    const loadFamilies = (items={}) => {
        schemeService.getAllFamilies(items).then(
            (payload) => {
                setFamilies(payload.data.results);
            },
        ).catch( (error) => {
            const resMessage = formatHttpErrorMessage(error);
            setAlert(resMessage, "danger");
            setLoading(false);
        });
    };

    const loadOffers = (nextPage=1, formParams={}) => {
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
		let params = {offset:offset, ...formParams}
		if(Object.keys(formParams).length > 0){
			setActiveParams(formParams);
			params = {offset:offset, ...formParams}
		}else{
			params = {offset:offset, ...activeParams}
		}
        if(familyId){
            params={family:familyId}
        }
        subscriptionService.filterOffers(params).then(
            (payload) => {
                setOffers(payload.data.results);
                setLoading(false);
                //setResultCount(payload.count);
				const pages = (payload.data.count > 0) ? Math.ceil(payload.data.count/entriesPerPage) : 0
                setNumberOfPages(pages);               
                setActivePage(nextPage);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };

    const goToPage = (event, page) => {
        loadOffers(page);
    }

    const loadFees = () => {
        fundService.getAllFees({category:'Member'}).then((response) => setMemberFees(response));
        fundService.getAllFees({category:'Registration'}).then((response) => setRegFees(response));
        fundService.getAllFees({category:'Subscription'}).then((response) => setSubFees(response));
        fundService.getAllFees({category:'Other'}).then((response) => setOtherFees(response));
                
    }

    const fetchSummary = (params) => {
        subscriptionService.getFilteredOfferSummary(params).then(
            (payload) => {
                setSummary(payload);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setAlert(resMessage,"danger");
                setLoading(false);
            }
        );
    }

    const handleSearch = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        var nextPage=1;
        setLoading(true);
        setFamilies([]);
        const params = getFormValues(event);
        loadOffers(1, params);
        /* subscriptionService.getAllOffers(params).then(
            (payload) => {
                setOffers(payload);
                setLoading(false);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        ); */
        setLoading(false);
    }

    const activateOffer = (event,id) => {
        var result = window.confirm("Are you sure you want to activate this offer? ");
        if (result == true) {
            subscriptionService.activateOffer(id).then(
                (result) => {
                    const output = (result.data.message !== undefined) ? result.data.message : "error"
                    setAlert(output);
                    console.log(result)
                    loadOffers();
                }
            ).catch((error) => {
                let msg = formatHttpErrorMessage(error);
                setAlert("An error occurred when activating offer! " + msg);
                loadOffers();
            })
        }
        else{

        }
    }

    const calculateOffer = (event,id) => {
        var result = window.confirm("are you sure you want to calculate this offer? ");
        if (result == true) {
            subscriptionService.calculateOffer(id).then(
                (result) => {
                    const output = (result.message !== undefined) ? result.message : "error"
                    setAlert(output);
                    loadOffers();
                },
                (error) => {

                }
            )
        }
        else{

        }
    }

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result === true) {
            subscriptionService.deleteOffer(id).then(response =>{
                setAlert("Successfully Deleted Offer", "success");
                loadOffers();
            }).catch(error => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            });
        } 
    };
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                start_date: offerStartDate,
                end_date: offerEndDate,
                post_date: offerPostDate,
                product: offerProduct,
                family: offerFamily,
                guid: generateUniqueId(),
                owner: currentUser.id,
                members: offerMembers,
                reg_fee_id:offerRegFees,
                member_fee_id: offerMemberFees,
                subscription_fee_id: offerSubFees,
                other_fee_id: offerOtherFees
            };
            subscriptionService.addOffer(payload).then(
                (result) => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully created offer");
                    setOfferEndDate("");
                    setOfferProduct("");
                    setOfferStartDate("");
                    setOfferPostDate("");
                    setShowForm(false);
                    loadOffers();
                }
                
            ).catch((error) => {
                const resMessage = formatHttpErrorMessage(error);
                console.log(error)
                setAlert(resMessage, "danger");
            });
        }
    };

    const activateSelectedItems = () => {
        var result = window.confirm("Are you sure you want to activate the selected offers? ");
        var params = {
            items:selectedItems
        }
        if (result == true) {
            subscriptionService.bulkActivateOffer(params).then(
                (result) => {
                    const output = (result.data.message !== undefined) ? result.data.message : "error"
                    setAlert(output, "danger");
                    loadOffers();
                }
            ).catch((error) => {
                let message = formatHttpErrorMessage(error)
                setAlert("An error occurred when activating offer! " + message, "danger");
                loadOffers();
            })
        }
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Number of Beneficiaries
        </Tooltip>
      );

    return (
        <Row className="g-3">
            {!props.basic &&
            <Col xs="12" className="pt-3">
                <h4 className="m-0">Offers</h4>
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/products"> Products</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Offers</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            }
           {!props.basic && <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                    <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSearch}
                            >
                            <Row>
                                <Col>
                                <Form.Select
                                                aria-label="Product"
                                                name='product'
                                                
                                            >
                                                <option value="">Choose Product</option>
                                                {products.map((product) => (
                                                    <option
                                                        key={product.id}
                                                        value={product.id}
                                                    >
                                                        {product.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                <Col md="2">
                                <Form.Select
                                                aria-label="select Scheme"
                                                name='family__subscheme__scheme'
                                                
                                            >
                                                <option value="">Choose Scheme</option>
                                                {parentSchemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                <Col>
                                <Form.Select
                                                aria-label="select Sub Scheme"
                                                name='family__subscheme'
                                            >
                                                <option value="">Choose Sub-Scheme</option>
                                                {schemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                
                             
                                <Col>
                                <Form.Select
                                                aria-label="select Status"
                                                name="status"
                                            >
                                                <option value="">Choose Status</option>
												<option value="Closed">Closed</option>
												<option value="Open">Open</option>
												<option value="Suspended">Suspended</option>
												<option value="Pending">Pending</option>
                                            </Form.Select>
                                </Col>
                                <Col>
                                <Form.Control
                                                type="number"
                                                placeholder="Balance"
                                                name='balance'
                                            />
                                </Col>
                                <Col xs="1">
                                <Button
                                            variant="primary"
                                            type="submit"
                                            size="md"
                                        >
                                            Search
                                        </Button>
                                </Col>
                            </Row>
                    </Form>
                    </Card.Body>
                </Card>
            </Col>}
            {showForm && (
                <Col xs="12">
                    <Card>
                        <Card.Body>
                            <Card.Subtitle className="mb-2">
                                Add new Offer{" "}
                            </Card.Subtitle>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="g-3">
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Offer start Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                value={offerStartDate}
                                                onChange={(e) =>
                                                    setOfferStartDate(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Offer end Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                value={offerEndDate}
                                                onChange={(e) =>
                                                    setOfferEndDate(
                                                        e.target.value
                                                    )
                                                }
                                                min={new Date().toISOString().split('T')[0]} 
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Posting Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                value={offerPostDate}
                                                onChange={(e) =>
                                                    setOfferPostDate(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Product"
                                        >
                                            <Form.Select
                                                aria-label="select Product"
                                                value={offerProduct}
                                                onChange={(e) =>
                                                    setOfferProduct(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {products.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select product.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Family"
                                        >
                                            <Form.Select
                                                aria-label="select Family"
                                                value={offerFamily}
                                                onChange={(e) =>
                                                    setOfferFamily(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {families.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.guid}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select family.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Registration Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Registration Fees"
                                                value={offerRegFees}
                                                onChange={(e) =>
                                                    setOfferRegFees(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                                <option value="">None</option>
                                                {regFees.map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Registration Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Subscription Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Subscription Fees"
                                                value={offerSubFees}
                                                onChange={(e) =>
                                                    setOfferSubFees(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option>None</option>
                                                {subFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Subscription Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Member Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Member Fees"
                                                value={offerMemberFees}
                                                onChange={(e) =>
                                                    setOfferMemberFees(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option>None</option>
                                                {memberFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Subscription Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="otherfeeSelect"
                                            label="Select Other Fee"
                                        >
                                            <Form.Select
                                                aria-label="select Other Fees"
                                                value={offerOtherFees}
                                                onChange={(e) =>
                                                    setOfferOtherFees(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value={0}>None</option>
                                                {otherFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}({item.amount})
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Other Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                  
                                    
                                    <Col xs="12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            )}
            <Col md={12}>
                {summary && <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <Card>
                                <Card.Body>
    
                                    <h3>{summary.total ? summary.total.toLocaleString() : "0"}</h3>
                                    <Row>
                                        {summary.summary && summary.summary.map((item, i) =>(
                                            <>
                                                <Col md={6} key={i}><strong>{item.status}</strong>: {item.total}</Col>
                                            </>
                                        ))}
                                    </Row>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                        <Card>
                                <Card.Body>
                                    <h6>Premium</h6>
                                    <h4>{summary?.premium?.toLocaleString()}</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                        <Card>
                                <Card.Body>
                                    <h6>Amount Paid</h6>
                                    <h4>{summary?.premiumPaid?.toLocaleString()}</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                                   
                </Col>}
            </Col>
            <Col className="col-12">
                <Card>
                    <Card.Body>
                        <Card.Title>Current Offers </Card.Title>
                        <Row>
                            <Col className="text-right">
                            <a href="#" onClick={(e) => {
                                                            loadOffers();
                                                        }}>Refresh</a>&nbsp;&nbsp;

{currentUser && (currentUser['is_superuser'] || currentUser['type'] !='Provider') && (<>
                                                          <Button
                                size="sm"
                                variant="primary"
                                onClick={toggleForm}
                            >
                                Add Offer
                            </Button>

                            </>)} &nbsp;&nbsp;
                            <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-success btn-sm"
                    table="offer-table-xls"
                    filename="offers_export"
                    sheet="offers"
                    buttonText="Export "/>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {selectedItems.length >0 && <Button onClick={() => activateSelectedItems()}> Activate Selected Items</Button>}
                            </Col>
                        </Row>
                       <br/>
                       
                        <div className="table-responsive">
                            <Table striped bordered hover id="offer-table-xls">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Family</th>
                                        <th> <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    ><span>Ben.</span></OverlayTrigger></th>
                                        <th>Product</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Status</th>
                                        <th>Premium</th>
                                        <th>Fees</th>
                                        <th>Total</th>
                                        <th>P. Paid</th>
                                        <th>Bal</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <Spinner
                                            animation="border"
                                            variant="warning"
                                        />
                                    )}
                                    {offers.map((offer, index) => (
                                        <tr key={index}>
                                            <td><input type="checkbox" id={index} value={offer.id} onChange={(e) => changeSelectedItem(e,index)} /></td>
                                            <td><strong>{offer.id}</strong></td>
                                            <td>
                                               <Link to={`/family/` + offer.family.id}>{offer.family.name}</Link> 
                                            </td>
                                            <td>
                                                {offer.members}
                                            </td>
                                            <td>
                                                {offer.product_name}
                                            </td>
                                            <td>{offer.start_date}</td>
                                            <td>{offer.end_date}</td>
                                            <td><BadgeWidget text={offer.status} /></td>
                                            <td>{offer.premium}</td>
                                            <td>{offer.fees}</td>
                                            <td>{offer.total_amount}</td>
                                            <td>{offer.premiumPaid}</td>
                                            <td>{offer.total_amount - offer.premiumPaid}</td>
                                            <td>
                                            {currentUser && (currentUser['is_superuser'] || currentUser['is_staff'] ===false) && (<>

                                                    {!offer.is_approved && (currentUser['type'] =='Org' || currentUser['is_superuser']) &&
                                                    <a href="#"
                                                        onClick={(e) => {
                                                            activateOffer(
                                                                e,
                                                                offer.id
                                                            );
                                                        }}
                                                    >
                                                        <Power />
                                                    </a>
                                                    }
                                                    
                                                    
                                                &nbsp;&nbsp;
                                                {!offer.is_approved && <>
                                                    <a href="#" onClick={(e) => {
                                                        calculateOffer(
                                                            e,
                                                            offer.id
                                                        );
                                                    }}
                                                >
                                                   <Calculator />
                                                </a>
                                                
                                                 &nbsp;&nbsp;
                                                <Link to={`/offer/${offer.id}`} >
                                                            <EyeFill/>
                                                        </Link> &nbsp;&nbsp;
                                                <a href="#"
                                                        
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                offer.id
                                                            );
                                                        }}
                                                    >
                                                       <Trash />
                                                    </a>
                                                    </>}
                                                    </>)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                          
                <Pager
                  numberOfPages={numberOfPages}
                  activePage={activePage}
                  goToPage={goToPage}
                />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            
        </Row>
    );
};
export default Offers;
