import axios from "axios";
import authHeader from "../auth/auth-header";
import { API_URL } from "../constants";


// For expired token
axios.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 200) {
          //console.log('Posted Successfully');
       }
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl);
        }
    }
 );

const getAllDistricts = (params={}) => {
    return axios
        .get(API_URL + "helpers/district/", { headers: authHeader() , params:params})
        .then((response) => {
            return response.data.results;
        });
};
const getDistrictByName = (name) => {
    return axios
        .get(API_URL + "helpers/district/?name="+name, { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};

const getAllCounties = (params={}) => {
    return axios
        .get(API_URL + "helpers/county/", { headers: authHeader(), params:params })
        .then((response) => {
            return response.data.results;
        });
};

const getCountyByDistrictId = (id) => {
    return axios
        .get(API_URL + "helpers/county/?name=&district="+id, { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};

const getSubcountyByCountyId = (id) => {
    return axios
        .get(API_URL + "helpers/subcounty/?name=&county="+id, { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};

const filterSubcounty = (name='',countyId='') => {
    return axios
        .get(API_URL + `helpers/subcounty/?name=${name}&county=${countyId}`, { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};

const getAllSubcounties = (params={}) => {
    return axios
        .get(API_URL + "helpers/subcounty/", { headers: authHeader(), params:params })
        .then((response) => {
            return response.data.results;
        });
};

const getSubcounty = (id) => {
    return axios
        .get(API_URL + `helpers/subcounty/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getUserCategoryReferences = (type,inputValue) => {
    if(type ==="Org" || type === "Sales" || type ==="Clerk"){
        return axios
        .get(API_URL + `schemes/scheme/`, {headers: authHeader() });
    }
    else if(type === "Group"){
        return axios
        .get(API_URL + `schemes/subscheme/`, { params:{limit:300}, headers: authHeader() });
    }
    else if(type === "Provider" || type==='ProviderFinance'){
        return axios
        .get(API_URL + `providers/provider/`, { headers: authHeader() });
    }else{
        return axios
        .get(API_URL + `schemes/subscheme/`, {params:{limit:300},  headers: authHeader() });
    }
    
    return [];
};


const getUserCategoryReferences2 = (type) => {
    if(type.includes("Org","Sales","clerk")){
        return axios
        .get(API_URL + `schemes/scheme/`, { headers: authHeader() });
    }
    else if(type === "Group"){
        return axios
        .get(API_URL + `schemes/subscheme/`, { headers: authHeader() });
    }
    else if(type === "Provider"){
        return axios
        .get(API_URL + `providers/provider/`, { headers: authHeader() });
    }else{
        return axios
        .get(API_URL + `providers/provider/`, { headers: authHeader() });
    }
    
    return [];
};

export default {
    getAllDistricts,
    getDistrictByName,
    getAllCounties,
    getCountyByDistrictId,
    getSubcountyByCountyId,
    getAllSubcounties,
    getSubcounty,
    filterSubcounty,
    getUserCategoryReferences,
};
