import React, { useRef, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner, Table } from 'react-bootstrap'
import { UpcScan } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom';
import { BASE_URL } from '../services/constants';
import schemeService from '../services/scheme/scheme-service';
import subscriptionService from '../services/subscription/subscription-service';
import { formatHttpErrorMessage } from './common/helper_functions';

function ProviderDashboard() {
  const searchInput = useRef();
  const [searchData, setSearchData] = useState();
  const [member, setMember] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const searchForMember = (q) => {
    setSearchData(q);
    setErrorMessage()
    setMember()
    setErrorMessage("")
      if(q.length < 36){
        return
      }
      
      let memberGuid = q;
      const qrparams= q.split(';');
      console.log(qrparams.length)
      if (qrparams.length >= 3){
          memberGuid = qrparams[2];
          //setErrorMessage(memberGuid);
      }
      setLoading(true);
      subscriptionService.validateMemberPolicy(memberGuid).then(response => {
          //let results = response.data;
          setSearchData("");
          setMember(response.data)
          console.log(response)
      }).catch(error => {
        console.log("Policy error", error)
        let msg = formatHttpErrorMessage(error)
        setErrorMessage(msg)
      });
      setLoading(false);
  }
  return (
    <>
      <Container md>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Card.Title>
                    <center>Search</center>
                   
                </Card.Title>
                 {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
                <InputGroup className="mb-3">
    <InputGroup.Text id="basic-addon1"><UpcScan /></InputGroup.Text>
    <FormControl
      placeholder="Scan Card"
      aria-label="qrcode"
      value={searchData}
      aria-describedby="basic-addon1"
      readOnly={loading}
      onChange={(e) => searchForMember(e.target.value)}
    />
  </InputGroup>
    {loading && <center><Spinner animation="border"/></center>}
              </Card.Body>
            </Card>
          </Col>
          
        </Row>
        <Row className='mt-3'>
          <Col md={12}>
            {member && 
            <Card>
              <Row>
              <Col md={3}>
              <img src={`${BASE_URL}/${member.photo}`} className="img-fluid" alt={member.firstname + ' ' + member.othername + ' ' + member.lastname}  />
              </Col>
              <Col md={6}>
                <Card.Body>
                  <Card.Title>{member.firstname} {member.othername} {member.lastname}</Card.Title>
                <Table>
                  <tbody>
                    <tr> <th>Member Code</th><td>{member.code}</td></tr>
                    <tr> <th>Gender</th><td>{member.gender}</td></tr>
                    <tr>  <th>Status</th><td>{member.status}</td>  </tr>
                    <tr>  <th>Relationship</th><td>{member.relationship}</td>  </tr>
                    <tr>  <th>Joined</th><td>{member.joined_on}</td>  </tr>
                  </tbody>
                </Table>
                 
                </Card.Body>
              </Col>
              <Col md={3}>
              <Card.Body pt={5}>
              <Button className="mt-5" variant='success' as={Link} to={`/members/${member.id}`}>View Details</Button>
              </Card.Body>
              </Col>
              </Row>
              
            </Card>
            }
          </Col>
        </Row>
        </Container>
    </>
  )
}

export default ProviderDashboard