import React from 'react'
import { fetchSchemes } from '../../services/scheme/schemes-service-new';
import { useQuery } from '@tanstack/react-query';
import { Form } from 'react-bootstrap';

function SchemeSelectField(props) {
    const { isLoading, isError, error, data:schemes, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["schemes"],
      queryFn: () => fetchSchemes(),
      keepPreviousData: true
    });
  return (
    <>
      <Form.Select aria-label="select Scheme" {...props}>
        <option>None</option>
        {schemes?.data.results.map((item, i) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
    </>
  );
}

export default SchemeSelectField