import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import schemeService from "../../services/scheme/scheme-service";
import ProviderSelectWidget from "./ProviderSelectWidget";

function InvoiceFilter(props) {
  const [schemes, setSchemes] = useState([]);
  const [subschemes, setSubschemes] = useState([]);

  useEffect(() => {
    getSearchSchemes();
  }, []);

  const getSearchSchemes = async () => {
    setSubschemes([]);
    await schemeService
      .getAllSchemes()
      .then((response) => setSchemes(response.results));
  };

  const getSubSchemes = async (scheme) => {
    setSubschemes([]);
    await schemeService
      .searchSubSchemes({ scheme: scheme })
      .then((response) => setSubschemes(response.results));
  };

  function getFormValues(event) {
    const data = new FormData(event.currentTarget);
    return Object.fromEntries(data.entries());
  }

  const handleSearch = (event) => {
    event.preventDefault();
    const params = getFormValues(event);
    props.search(params);
  };

  return (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleSearch}>
            <Row>
              <Col>
                <Form.Control
                  type="date"
                  placeholder="Admission"
                  name="created"
                />
              </Col>

              <Col md="2">
                <Form.Select
                  aria-label="select Scheme"
                  name="scheme"
                  onChange={(e) => getSubSchemes(e.target.value)}
                >
                  <option value="">Choose Scheme</option>
                  {schemes.map((parent) => (
                    <option key={parent.id} value={parent.id}>
                      {parent.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2}>
                <Form.Select aria-label="select Sub Scheme" name="subscheme">
                  <option value="">
                    {subschemes.length <= 0
                      ? "No Subschemes"
                      : "Choose Sub-Scheme"}
                  </option>
                  {subschemes.map((parent) => (
                    <option key={parent.id} value={parent.id}>
                      {parent.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <ProviderSelectWidget name="provider" />
              </Col>
              <Col md={2}>
                <Form.Select aria-label="select Sub Scheme" name="status">
                  <option value="">Choose Status</option>
                  <option value="Paid">Paid</option>
                  <option value="Draft">Draft</option>
                  <option value="Pending">Pending</option>
                  <option value="Review">Review</option>
                  <option value="Submitted">Submitted</option>
                  <option value="Closed">Closed</option>
                  <option value="Rejected">Rejected</option>
                </Form.Select>
              </Col>

              <Col xs="1">
                <Button variant="primary" type="submit" size="md">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default InvoiceFilter;
