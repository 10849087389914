import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import productService from '../../services/product/product-service';
import providerService from '../../services/provider/provider-service';
import subscriptionService from '../../services/subscription/subscription-service';

function FamilyProviderForm(props) {
    const formRef = useRef(null);
    const [level, setLevel] = useState();
    const [minCover, setMinCover] = useState();
    const [minCoverIpd, setMinCoverIpd] = useState(0);
    const [ceilingOpd, setCeilingOpd] = useState(0);
    const [ceilingIpd, setCeilingIpd] = useState(0);
    const [offer, setOffer] = useState();
    const [provider, setProvider] = useState("");
    const [exclusions, setExclusions] = useState([]);
    const [providerList, setProviderList] = useState([]);
    const [offers, setOffers] =useState([]);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [message, setMessage] = useState();
    const [validated, setValidated] = useState();
    const [loadingProduct, setLoadingProduct] = useState(true);
    let fGuid = props.fGuid;


    useEffect(() => {
        loadOffers();
        loadProviderOptions();
        
    },[])

    const handleReset = () => {
        formRef.current.reset();
        setValidated(false);
      };


    const loadOffers = (params={}) => {
        
        if(fGuid){
            params={family:fGuid};
        }
        subscriptionService.filterOffers(params).then(
            (payload) => {
                setOffers(payload.data.results);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
            }
        );
    };

    const loadProviderOptions = async () => {
       //get values based on client type chosen
      await providerService.getAllProviders().then((response) => {
         setProviderList(response.results);
       });
   };

   const loadProductDetails = async (offerId) => {

       if(offerId === undefined || offerId ==='None'){
            return false;
       }
        var productId = offers.find(
            ({ guid }) =>
                guid ===
                offerId
        ).product;
        if(productId){
            await productService.getProduct(productId).then(response => {
                setCeilingIpd(response.ceiling_ipd);
                setCeilingOpd(response.ceiling_opd);
                setMinCover(response.copay_opd);
                setMinCoverIpd(response.copay_ipd);
            });
            setLoadingProduct(false);
    }
   }


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }
    
        setValidated(true);
        setLoading(true);
        if (invalid == false) {
            let user = localStorage.getItem("user"); 
            let item = {
                level,
                min_cover:minCover,
                min_cover_ipd:minCoverIpd,
                ceiling_opd:ceilingOpd,
                ceiling_ipd:ceilingIpd,
                offer,
                family:fGuid,
                provider,
                exclusions:exclusions.toString()
    
            };
            
            subscriptionService.attachProviderToFamily(item).then(
                (result) => {
                    //redirect to family 
                    const fId = result.id;
                    setLevel('');
                    setProvider('');
                    setOffer('');
                    setCeilingIpd(0);
                    setCeilingOpd(0);
                    setMinCover(0);
                    setMinCoverIpd(0);
                    handleReset();
                   props.onClose();
                   
                }
            ).catch(error => {
                console.log(error);
                setLoading(false);
                    setShowForm(true);
                    setMessage("An Error occurred!");
                
            });
        }
        setLoading(false);
    };
    
    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          bg="primary"
         
        >
          <Modal.Header closeButton bg="primary">
            <Modal.Title id="contained-modal-title-vcenter" >
              Facility Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message && <Alert variant='error'>{message}</Alert>}
            {showForm && <Form
                                    noValidate
                                    ref={formRef} 
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                >
                                    <p>Fill in the form below</p>
                                    <Row className="gx-3 gy-3">
                                    <Col md="12">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Select Health Facility"
                                                className=""
                                            >
                                               <Form.Select
                                                aria-label="Select Health Facility"
                                                value={provider}
                                                onChange={(e) =>
                                                    setProvider(e.target.value)
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {providerList.length && providerList.map((item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                    </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid date.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md="12">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Choice Level"
                                                className=""
                                            >
                                             <Form.Select
                                                aria-label="select level"
                                                value={level}
                                                onChange={(e) =>
                                                    setLevel(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="First">First</option>
                                                <option value="Second">Second</option>
                                                <option value="Referral">Referral</option>
                                               
                                            </Form.Select>
                                               
                                            </FloatingLabel>
                                        </Col>
                                    <Col md="12">
                                            <FloatingLabel
                                                controlId="floatingSelect"
                                                label="Select Offer"
                                            >
                                                  <Form.Select
                                                aria-label="select Offer"
                                                value={offer}
                                                onChange={(e) =>{
                                                    setOffer(
                                                        e.target.value
                                                    );
                                                    loadProductDetails(e.target.value);
                                                }
                                                    
                                                }
                                                required
                                            >
                                                <option>None</option>
                                                {offers.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.guid}
                                                    >
                                                        {
                                                    parent.product_name +"( starts on " + parent.start_date +")"
                                                            }
                                                    </option>
                                                ))}
                                            </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please select offer.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md="6">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Minimum Cover/copayment"
                                                className=""
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Minimum Cover or Co-payment"
                                                    value={minCover}
                                                    onChange={(e) =>
                                                        setMinCover(
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                    readOnly={loadingProduct}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Amount
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>

                                        <Col md="6">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Minimum Cover IPD"
                                                className=""
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Minimum Cover IPD"
                                                    value={minCoverIpd}
                                                    onChange={(e) =>
                                                        setMinCoverIpd(
                                                            e.target.value
                                                        )
                                                    }
                                                    readOnly={loadingProduct}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Amount
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
    
                                      
                                      <Col md="6">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Ceiling OPD"
                                                className=""
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="OPD Ceiling"
                                                    value={ceilingOpd}
                                                    onChange={(e) =>
                                                        setCeilingOpd(
                                                            e.target.value
                                                        )
                                                    }
                                                    readOnly={loadingProduct}
                                                />
                                               
                                            </FloatingLabel>
                                        </Col>
                                       
                                      
                                        <Col md="6">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Ceiling IPD"
                                                className=""
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Ceiling IPD"
                                                    value={ceilingIpd}
                                                    onChange={(e) =>
                                                        setCeilingIpd(
                                                            e.target.value
                                                        )
                                                    }
                                                    readOnly={loadingProduct}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Amount.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>    
                                        <Col md="12">
                                            <Form.Label>Select Exclusions</Form.Label>
                                             <Form.Select
                                                aria-label="select Exclusions"
                                                value={exclusions}
                                                onChange={e => setExclusions([].slice.call(e.target.selectedOptions).map(item => item.value))}
                                                multiple
                                                
                                            >
                                                <option value="Maternity">Maternity services</option>
                                                <option value="Chronics">Chronic illnesses</option>
                                                <option value="National referral">National referral</option>
                                                <option value="OutPatient">Out patient</option>
                                                <option value="Admissions">Admissions</option>
                                               
                                            </Form.Select>
                                               
                                            
                                        </Col>             
    
                                        <Col md="12" className="d-grid gap-2">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                size="md"
                                                disabled={loading }
                                            >
                                                Add Provider
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form> }
                    {loading && <center><Spinner
                                                animation="border"
                                                role="status"
                                            >
                                                <span className="visually-hidden">
                                                    Loading...
                                                </span>
                                            </Spinner></center>}
          </Modal.Body>
        </Modal>
      )
}

export default FamilyProviderForm