import { useEffect, useState } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
} from "react-bootstrap";
import helperService from "../../services/helpers/helper-service";
import networkService from "../../services/network/network-service";
const Networks = ({ currentUser }) => {
    const [networks, setNetworks] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [networkName, setNetworkName] = useState("");
    const [networkDistrict, setNetworkDistrict] = useState("");
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        loadNetworks();
        helperService.getAllDistricts().then(
            (districts) => {
                setDistricts(districts);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setToastMessage(resMessage);
                setShowToast(true);
            }
        );
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };
    const loadNetworks = () => {
        networkService.getAllNetworks().then(
            (networks) => {
                setNetworks(networks);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);
        if (invalid == false) {
            let network = {
                name: networkName,
                district: networkDistrict,
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                email: currentUser.email,
                username: currentUser.username,
                password: "admin@123",
            };
            networkService.addNetwork(network).then(
                (result) => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully created network");
                    setNetworkDistrict("");
                    setNetworkName("");
                    setShowForm(false);
                    loadNetworks();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        }
    };
    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            networkService.deleteNetwork(id).then(
                () => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully Deleted Network");
                    loadNetworks();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        } else {
        }
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Networks</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Header>
                    Networks{" "}
                    <Button
                        className="float-end"
                        variant="primary"
                        onClick={toggleForm}
                    >
                        Add
                    </Button>
                </Card.Header>
                <Card.Body>
                    {showForm && (
                        <Card.Subtitle>Add new Network </Card.Subtitle>
                    )}
                    {showForm && (
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                        >
                            <Row className="g-2">
                                <Col md>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="networkName"
                                            value={networkName}
                                            onChange={(e) =>
                                                setNetworkName(e.target.value)
                                            }
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid name for a
                                            network.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel
                                        controlId="floatingSelect"
                                        label="Select District"
                                    >
                                        <Form.Select
                                            aria-label="select district"
                                            value={networkDistrict}
                                            onChange={(e) =>
                                                setNetworkDistrict(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">None</option>
                                            {districts.map((district) => (
                                                <option
                                                    key={district.id}
                                                    value={district.name}
                                                >
                                                    {district.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a district.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    <Card.Title>Current Networks </Card.Title>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>District</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {networks.map((network, index) => (
                                <tr>
                                    <td>{network.id}</td>
                                    <td>{network.name}</td>
                                    <td>{network.district}</td>
                                    <td>
                                        <ButtonGroup
                                            aria-label="Actions"
                                            size="sm"
                                        >
                                            <Button variant="secondary">
                                                View
                                            </Button>
                                            <Button variant="warning">
                                                Edit
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={(e) => {
                                                    handleDelete(e, network.id);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </>
    );
};
export default Networks;
