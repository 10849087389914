import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import schemeService from "../../services/scheme/scheme-service";

export default function SchemeForm(props) {
    const [schemeName, setSchemeName] = useState("");   
    const [validated, setValidated] = useState(false);
    const [schemeShortName, setSchemeShortName] = useState("");
    const [schemeFoundingDate, setSchemeFoundingDate] = useState(null);
    const [schemeTargetPopulation, setSchemeTargetPopulation] = useState(0);
    const [schemeBody, setSchemeBody] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {     
            setLoading(true);
            let scheme = {
                name: schemeName,
                shortname: schemeShortName,                
                body: schemeBody,
                founded_on: schemeFoundingDate,
                target_population: schemeTargetPopulation
            };
            schemeService.addScheme(scheme).then(
                (result) => {                  
                    setSchemeName("");
                    setSchemeBody("");
                    setSchemeShortName("");
                    props.onHide();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                }
            );
        }
    };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Scheme Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Fill in the form below</h4>
        <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="g-3">
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="schemeName"
                                                value={schemeName}
                                                onChange={(e) =>
                                                    setSchemeName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name for
                                                a scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Short Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="schemeName"
                                                value={schemeShortName}
                                                onChange={(e) =>
                                                    setSchemeShortName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid short
                                                name for a scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Founded On"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                value={schemeFoundingDate}
                                                onChange={(e) =>
                                                    setSchemeFoundingDate(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Target Population"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"                                                
                                                min={0}
                                                value={schemeTargetPopulation}
                                                onChange={(e) =>
                                                    setSchemeTargetPopulation(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col className="col-12">
                                        <FloatingLabel
                                            controlId="floatingTextarea2"
                                            label="Description"
                                        >
                                            <Form.Control
                                                as="textarea"
                                                value={schemeBody}
                                                onChange={(e) =>
                                                    setSchemeBody(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="You Can Describe Scheme"
                                                style={{ height: "100px" }}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className=""
                                            disabled={loading}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
      </Modal.Body>
    </Modal>
  )
}
