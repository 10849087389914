// export const API_URL = 'http://shubackend.umojalabs.com/';
// export const BASE_URL = 'http://shubackend.umojalabs.com/';

// export const API_URL = 'https://api.shumicroinsurancemanager.org.ug/';
// export const BASE_URL = 'https://api.shumicroinsurancemanager.org.ug/';
export const API_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// export const API_URL = 'http://127.0.0.1:8000/';
// export const BASE_URL = 'http://127.0.0.1:8000/';
