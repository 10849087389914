import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, FloatingLabel, Spinner } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import schemeService from "../../services/scheme/scheme-service";
import fundService from "../../services/fund/fund-service";

export default function FeesEditForm(props) {
    const [feeName, setFeeName] = useState("");
    const [feeCategory, setFeeCategory] = useState("");
    const [feeScheme, setFeeScheme] = useState("");
    const [feeBaseAmount, setBaseAmount] = useState(0);
    const [feeCriteria, setFeeCriteria] = useState(1);
    const [feeAmount, setFeeAmount] = useState("");
    const [schemes, setSchemes] = useState([]);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(true);

    const history = useHistory();
    
    useEffect(() => {
        loadFeeDetails();
        loadSchemes();
       
        
    }, []);

    const loadFeeDetails = () => {
        setLoading(true);
        setShowForm(false);
        fundService.getFee(props.id).then(
            (result) => {
                             
                setShowForm(true);
                setLoading(false);
                setFeeAmount(result.amount);
                setFeeScheme(result.scheme);
                setFeeName(result.name);
                setFeeCategory(result.category);
                setBaseAmount(result.base_amount);
                setFeeCriteria(result.criteria);
                setLoading(false);
                setShowForm(true);
               
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setShowForm(true);
            }
        );
    }

    const loadSchemes = () => {
        schemeService.getAllSchemes('1',300).then(
            (result) => {
                setSchemes(result.results);
                
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
            }
        );
    
};

const handleSubmit = (event) => {
    const form = event.currentTarget;
    let invalid = false;
    event.preventDefault();
    if (form.checkValidity() === false) {
        //event.preventDefault();
        event.stopPropagation();
        invalid = true;
    }

    setValidated(true);
    setLoading(true);
    setShowForm(false);
    if (invalid == false) {
        let item = {
            name: feeName,
            criteria: feeCriteria,
            base_amount: feeBaseAmount,
            amount: feeAmount,
            category: feeCategory,
            scheme: feeScheme
        };
        console.log(item);
        fundService.updateFee (item, props.id).then(
            (result) => {
                //redirect to family 
                const fId = result.id;
                form.reset();
                setShowForm(true);
                setLoading(false);
                setFeeAmount(0);
                setFeeScheme("");
                setFeeName("");
                props.onClose();
               
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setShowForm(true);
            }
        );
    }
};
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
     
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Fees Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Fill in the form below</p>
        {showForm && <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="gx-3 gy-3">
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" Name"
                                                value={feeName}
                                                onChange={(e) =>
                                                    setFeeName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name for
                                                fee.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                   

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Scheme"
                                        >
                                            <Form.Select
                                                aria-label="select Scheme"
                                                value={feeScheme}
                                                onChange={(e) =>
                                                    setFeeScheme(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {schemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select sub scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                    <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Category"
                                        >
                                            <Form.Select
                                                aria-label="select Category"
                                                value={feeCategory}
                                                onChange={(e) =>
                                                    setFeeCategory(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Registration">Registration Fees</option>
                                                <option value="Subscription">Family Fees</option>
                                                <option value="Member">Member Fees</option>
                                                <option value="Other">Other Fees</option>
                                               
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select sub scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    {feeCategory == "Member" && <>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Minimum Members"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Minimum Amount"
                                                value={feeCriteria}
                                                onChange={(e) =>
                                                    setFeeCriteria(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Member count.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Minimum Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Minimum Amount"
                                                value={feeBaseAmount}
                                                onChange={(e) =>
                                                    setBaseAmount(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Amount.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    </>}

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Amount"
                                                value={feeAmount}
                                                onChange={(e) =>
                                                    setFeeAmount(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Amount.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form> }
                {loading && <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>}
      </Modal.Body>
    </Modal>
  )
}
