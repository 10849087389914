import { useContext, useEffect, useState } from "react";
import {
	Button,
	Form,
	FloatingLabel,
	ButtonGroup,
	Card,
	Breadcrumb,
	Toast,
	Table,
	Row,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Spinner,
	Image,
	Tabs,
	Tab,
} from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";

import schemeService from "../../services/scheme/scheme-service";
import AuthDetailContext from "../common/AuthDetailContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
import FamilyProviders from "../providers/FamilyProviders";
import subscriptionService from "../../services/subscription/subscription-service";
import Providers from "../providers/Providers";
import Offers from "../subscriptions/Offers";
import Payments from "../subscriptions/Payments";
import Policies from "../subscriptions/Policies";
import AlertContext from "../widgets/alertPopup/AlertContext";
import MemberForm from "./MemberForm";
const baseURL = process.env.REACT_APP_BASE_URL;

const Family = ({match }) => {
	const currentUser = useContext(AuthDetailContext);
	const { setAlert } = useContext(AlertContext);
	const { familyId, mId } = match.params;
	const [family, setFamily] = useState(null);
	const [isEdit, setEdit] = useState(false);
	const [isMemberEdit, setMemberEdit] = useState(false);
	const [parentSchemes, setParentSchemes] = useState([]);
	const [showForm, setShowForm] = useState(false);
	const [familyName, setFamilyName] = useState("");
	const [familyScheme, setFamilyScheme] = useState("");
	const [familyPostedOn, setFamilyPostedOn] = useState("");
	const [familyVillage, setFamilyVillage] = useState("");
	const [validated, setValidated] = useState(false);
	const [validatedMember, setValidatedMember] = useState(false);
	const [memberJoinedOn, setMemberJoinedOn] = useState("");
	const [memberFirstName, setMemberFirstName] = useState("");
	const [memberLastName, setMemberLastName] = useState("");
	const [memberGender, setMemberGender] = useState("Male");
	const [memberDob, setMemberDob] = useState("");
	const [memberRelationship, setMemberRelationship] = useState("Head");
	const [memberId, setMemberId] = useState(null);
	const [memberGuid, setMemberGuid] = useState(null);
	const [memberNin, setMemberNin] = useState(undefined);
	const [memberPhone, setMemberPhone] = useState(undefined);
	const [memberPhoto, setMemberPhoto] = useState(undefined);
	const [showMemberForm, setShowMemberForm] = useState(false);
	const history = useHistory();

	useEffect(() => {
		checkForEditInUrl();
		loadParentSchemes();
		loadFamily(familyId);
	}, [mId, familyId]);

	const toggleForm = () => {
		setMemberEdit(false);
		setMemberLastName("");
		setMemberFirstName("");
		setMemberJoinedOn("");
		setMemberDob("");
		setShowForm(!showForm);
	};

	const toggleEdit = () => {
		setEdit(!isEdit);
	};

	const loadFamily = (familyId) => {
		schemeService.getFamily(familyId).then(
			(response) => {
				setFamily(response.data);
				setFamilyName(response.data.name);
				setFamilyScheme(response.data.subscheme);
				setFamilyPostedOn(response.data.posted_on);
				setFamilyVillage(response.data.village);
				if (mId !== undefined) {
					console.log("memberID", mId);
					showEditForm(mId);
				}
			}
		).catch(error => {
			const resMessage = formatHttpErrorMessage(error);
				setAlert(resMessage, "danger");
		});
	};

	const loadParentSchemes = () => {
		schemeService.getAllSubSchemes('',100).then(
			(result) => {
				setParentSchemes(result.results);
			},
			(error) => {
				const resMessage = formatHttpErrorMessage(error);
				setAlert(resMessage, "danger");
				
			}
		);
	};

	const handleDelete = (event, id, name="") => {
		var result = window.confirm("Are you sure you want to Delete member '"+name+"'? ");
		if (result == true) {
			schemeService.deleteMember(id).then(
				(response) => {	
					loadFamily(familyId);
					setAlert("Member Successfully Deleted!", "success");
				},
			).catch(error => {
				const resMessage = formatHttpErrorMessage(error);
				setAlert(resMessage, "danger");
			});
		}
	};

	const handleDisable = (event, id, name="") => {
		var result = window.confirm("Are you sure you want to Disable member '"+name+"'? ");
		if (result == true) {
			schemeService.disableMember(id).then(
				() => {
			
					loadFamily(familyId);
					setAlert("Member '"+name+"' Successfully disabled!", "success");
				},
				
			).catch(error => {
				const resMessage = formatHttpErrorMessage(error);
					setAlert(resMessage, "danger");
			});
		} 
	};

	const handleEnable = (event, id, name="") => {
		var result = window.confirm("Are you sure you want to Enable member '"+name+"'? ");
		if (result == true) {
			schemeService.enableMember(id).then(
				(response) => {
					loadFamily(familyId);
					setAlert("Member '"+name+"' Successfully Enable!", "success");
				},
			).catch(error => {
				const resMessage = formatHttpErrorMessage(error);
					setAlert(resMessage, "danger");
			});
		} 
	};

	const showEditForm = (memberId) => {
		const member =
			family !== null
				? family.family_members.find(({ id }) => id === memberId)
				: undefined;
		if (member !== undefined) {
			setMemberDob(member.birthdate);
			setMemberFirstName(member.firstname);
			setMemberLastName(member.lastname);
			setMemberGender(member.gender);
			setMemberRelationship(member.relationship);
			setMemberJoinedOn(member.joined_on);
			setMemberId(member.id);
			setMemberGuid(member.guid);
			setMemberPhone(member.phone);
			setMemberNin(member.nin);
			setMemberPhoto(member.photo);
			setMemberEdit(true);
			setShowForm(true);
		}
	};
	const generateUniqueId = () => {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);
	};

	const handleFileSelect = (event) => {
		setMemberPhoto(event.target.files[0]);
	};

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		let invalid = false;
		event.preventDefault();
		if (form.checkValidity() === false) {
			//event.preventDefault();
			event.stopPropagation();
			invalid = true;
		}

		setValidated(true);

		if (invalid == false) {
			let user = localStorage.getItem("user");
			let payload = {
				name: familyName,
				subscheme: familyScheme,
				posted_on: familyPostedOn,
				owner: JSON.parse(user).id,
				guid: family.guid,
				village: familyVillage,
				joined_on: familyPostedOn,
			};
			schemeService.updateFamily(payload, familyId).then(
				(result) => {
					loadFamily(familyId);
					setAlert("Successfully updated family");
					toggleEdit();
					setValidated(false);
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setAlert("An error occurred!", "danger")
				}
			);
		}
	};

	const closeMemberForm = () =>{
		setShowMemberForm(false);
		loadFamily(familyId);
		setAlert("Member Created Successfully!")
	}

	const printFamilyCard = (id) => {
		return schemeService.printFamilyCard(id);
	};


	const generateFamilyCard = async (id) => {
		await subscriptionService.regenerateFamilyCard(id).then( response => {
			alert("Regenerated QR code Successfully")
		}, error => {
			alert("An error occurred while generating QR code")
		}).catch(error => {
			alert("An error occurred while generating QR code")
		});
	};


	const checkForEditInUrl = () => {
		if (window.location.href.indexOf("edit") > -1) {
			setEdit(true);
		}
	};

	return (
		<Row className="g-3">
			<Col xs="12" className="pt-3">
				<Breadcrumb className="border-bottom mb-3">
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/schemes"> Schemes </Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/sub-schemes"> Sub Schemes </Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/families"> Families </Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{family ? family.name : ""}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Col>

			<Col xs="12">
				<Card>
					<Card.Body>
						<Row>
							<Col md={12} className="bg-white d-flex justify-content-between align-content-center mb-2">
							<h4 className="m-0">
							Family: <strong>{family ? family.name : ""}</strong>
						
						</h4>
						<div>
						<Button
								
								variant="outline-info"
								onClick={history.goBack}
								size="sm"
							>
								<FiArrowLeft></FiArrowLeft> Back
								
							</Button>&nbsp;&nbsp;
							{currentUser && (currentUser['is_superuser'] || currentUser['type'] !="Provider") && (<>
							<Button
								variant="primary"
								onClick={toggleEdit}
								size="sm"
							>
								Edit
							</Button>&nbsp;&nbsp;
							</>)}
							{family && currentUser && (currentUser['is_superuser'] || currentUser['type'] !="Provider") && <>
								<Button disabled={family.status !=='Active' ? 1:0}  variant="success" size="sm" onClick={ (e) => printFamilyCard(family.id)}>Print Card</Button>
								
								&nbsp;&nbsp;
								<Button disabled={family.status !=='Active' ? 1:0}  variant="secondary" size="sm" onClick={ (e) => generateFamilyCard(family.guid)}>Re-Generate QR</Button>
							</>} &nbsp;
							
						</div>
							</Col>
							<Col md={12}>
							<Col xs="12">
										{!family && (
											<Spinner
												animation="border"
												variant="warning"
											/>
										)}
										{family && (
											<Form
												noValidate
												validated={validated}
												onSubmit={handleSubmit}
												className="row g-3"
											>
												<Form.Group
													className="col-md-3"
													controlId="exampleForm.ControlInput1"
												>
													<Form.Label>
														Family name
													</Form.Label>
													<Form.Control
														required
														readOnly={!isEdit}
														type="text"
														value={familyName}
														onChange={(e) =>
															setFamilyName(
																e.target.value
															)
														}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a valid
														name for a family.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group
													className="col-md-3"
													controlId="exampleForm.ControlInput1"
												>
													<Form.Label>
														Joined On
													</Form.Label>
													<Form.Control
														required
														readOnly={!isEdit}
														type="text"
														value={familyPostedOn}
														onChange={(e) =>
															setFamilyPostedOn(
																e.target.value
															)
														}
													/>
													<Form.Control.Feedback type="invalid">
														Please provide a valid
														date.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group
													className="col-md-3"
													controlId="exampleForm.ControlInput1"
												>
													<Form.Label>
														Village
													</Form.Label>
													<Form.Control
														readOnly={!isEdit}
														type="text"
														value={familyVillage}
														onChange={(e) =>
															setFamilyVillage(
																e.target.value
															)
														}
													/>
												</Form.Group>
												<Form.Group
													className="col-md-3"
													controlId="parentfamilyId"
												>
													<Form.Label>
														Sub Scheme
													</Form.Label>
													<Form.Select
														aria-label="select Parent Scheme"
														disabled={!isEdit}
														value={familyScheme}
														onChange={(e) =>
															setFamilyScheme(
																e.target.value
															)
														}
														required
													>
														<option value="">
															None
														</option>
														{parentSchemes.map(
															(parent) => (
																<option
																	key={
																		parent.id
																	}
																	value={
																		parent.id
																	}
																>
																	{
																		parent.name
																	}
																</option>
															)
														)}
													</Form.Select>
													<Form.Control.Feedback type="invalid">
														Please select parent
														scheme.
													</Form.Control.Feedback>
												</Form.Group>

												{isEdit && (
													<div>
														<Button
															variant="primary"
															type="submit"
															size="sm"
														>
															Update
														</Button>
													</div>
												)}
											</Form>
										)}
									</Col>
							</Col>
						</Row>
						
					</Card.Body>
				</Card>
			</Col>
			<Col xs="12">
				<Card>
					<Card.Body>
						<Tabs
							defaultActiveKey="family"
							id="family-tab"
							className="mb-3"
						>
							<Tab eventKey="family" title="Beneficiaries">
								<Row className="g-3">
								

								<Col xs="12">
										<Card>
											<Card.Body>
												<Card.Title>
												Beneficiaries
													{currentUser && (currentUser['is_superuser'] || currentUser['type'] !="Provider") && (<>
													<Button
														className="float-end"
														variant="primary"
														onClick={() => setShowMemberForm(true)}
														size="sm"
													>
														Add Member
													</Button>
													
												<MemberForm show={showMemberForm}
									onHide={() => setShowMemberForm(false)} onClose={() => closeMemberForm()} family={family}/>
										</>)}
												</Card.Title>
												{family && (
													<div className="table-responsive">
														<Table striped hover>
															<thead>
																<tr>
																	<th>#</th>
																	
																	<th>
																		Name
																	</th>
																	
																	<th>
																		Relationship
																	</th>
																	<th>NIN</th>
																	<th>
																		Phone
																		Number
																	</th>
																	<th>
																		Date
																	</th>
																	<th>
																		Status
																	</th>
																	<th>
																		Actions
																	</th>
																</tr>
															</thead>
															<tbody>
																{family && family.family_member && family.family_member.map(
																	(
																		member,
																		index
																	) => (
																		<tr key={index}>
																			<td>
																				{ index + 1	}
																			</td>
																			
																			<td> {member.status !='Disabled' ? 
																				<Link to={`/members/` + member.id}>{ member.firstname } {member.lastname} {member.othername} </Link>
																				:<span>{ member.firstname } {member.lastname} {member.othername}</span>}</td>
																			<td> { member.relationship } </td>
																			<td> { member.nin } </td>
																			<td> { member.phone } </td>
																			<td> { member.posted_on } </td>
																			<td> { member.status } </td>
																			<td>
																			{currentUser && (currentUser['is_superuser'] || currentUser['type'] !="Provider") && (<>
																				<ButtonGroup
																					aria-label="Actions"
																					size="sm"
																				>
																				<Button
																						variant="info"
																						onClick={(
																							e
																						) => {
																							handleEnable(
																								e,
																								member.id,
																								member.firstname
																							);
																						}}
																						disabled={member.status!=='Disabled'}
																					>
																						Enable
																					</Button>
																				<Button
																						variant="warning"
																						onClick={(
																							e
																						) => {
																							handleDisable(
																								e,
																								member.id,
																								member.firstname
																							);
																						}}
																						disabled={member.status==='Disabled'}
																					>
																						Disable
																					</Button>
																					<Button
																						variant="danger"
																						onClick={(
																							e
																						) => {
																							handleDelete(
																								e,
																								member.id,
																								member.firstname
																							);
																						}}
																						disabled={member.status==='Disabled' || member.status==='Active'}
																					>
																						Delete
																					</Button>
																				</ButtonGroup>
																				</>)}
																			</td>
																		</tr>
																	)
																)}
															</tbody>
														</Table>
													</div>
												)}
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</Tab>
							{currentUser && currentUser.type !="Provider" && (<Tab eventKey="offers" title="Offers">
								{family && family.family_member.length && (
									<Offers
										match={{
											params: { familyId: family.guid },
										}}
										currentUser={currentUser}
										basic={true}
									/>
								)}
							</Tab>)}
							{currentUser && currentUser.type !="Provider" && (<Tab eventKey="payments" title="Payments"> 
								{family && (
									<Payments
										match={{
											params: { familyGuid: family.guid },
										}}
										currentUser={currentUser}
										basic={true}
									/>
								)}
							</Tab>)}
							{currentUser && currentUser.type !="Provider" && (<Tab eventKey="policies" title="Policies">
								{family && (
									<Policies
										match={{
											params: { familyId: family.id },
										}}
										currentUser={currentUser}
										basic={true}
									/>
								)}
							</Tab>)}
							<Tab eventKey="providers" title="Providers">
								{family && (
									<>
									<FamilyProviders
										familyId={family.id}
										familyGuid={family.guid}
										currentUser={currentUser}
										basic={true}
									/>
									</>
									
								)}
							</Tab>
						</Tabs>
					</Card.Body>
				</Card>
			</Col>

		</Row>
	);
};
export default Family;
