import { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { PencilSquare, Trash, EyeFill, Plus} from "react-bootstrap-icons";
import productService from "../../services/product/product-service";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";

const Products = (props) => {
    const currentUser = useContext(AuthDetailContext);
    const {setAlert} = useContext(AlertContext);
    const [products, setProducts] = useState([]);

    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [productName, setProductName] = useState("");
    const [productCriteria, setProductCriteria] = useState("");
    const [validated, setValidated] = useState(false);
    const [productBasePremium, setProductBasePremium] = useState("");
    const [productPremium, setProductPremium] = useState(null);
    const [productCopayIpd, setProductCopayIpd] = useState("");
    const [productCopayOpd, setProductCopayOpd] = useState("");
    const [productCeilingIpd, setProductCeilingIpd] = useState("");
    const [productCeilingOpd, setProductCeilingOpd] = useState("");
    const [productCeilingPharm, setProductCeilingPharm] = useState(0.0);
    const [productCeilingReferral, setProductCeilingReferral] = useState(0.0);
    const [productVisitMember, setProductVisitmember] = useState(1);
    const [productVisitFamily, setProductVisitFamily] = useState(1);
    const [productFacilityNumber, setProductFacilityNumber] = useState(1);
    const [productMaxFacilityLevel, setProductMaxFacilityLevel] = useState("");
    const [productChronicRate, setProductChronicRate] = useState(0.0);
    const [productDrugAccess, setProductDrugAccess] = useState("No");
    const [productExclusions, setProductExclusions] = useState("");
    const [productCoverage, setProductCoverage] = useState("");
    const [penaltyFee, setPenaltyFee] = useState(0);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        loadProducts();
        setLoading(false);
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const loadProducts = () => {
        productService.getAllProducts().then(
            (payload) => {
                setProducts(payload);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error)
                setAlert(resMessage, "danger");
            }
        );
    };

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            productService.deleteProduct(id).then(
                () => {
                    setAlert("Successfully Deleted Product", "success");
                    loadProducts();
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage, "danger");
                }
            );
        } else {
        }
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            
            let payload = {
                name: productName,
                criteria: productCriteria,
                base_premium: productBasePremium,
                premium: productPremium,
                copay_opd: productCopayOpd,
                copay_ipd: productCopayIpd,
                ceiling_opd: productCeilingOpd,
                ceiling_ipd: productCeilingIpd,
                ceiling_pharm: productCeilingPharm,
                ceiling_referral: productCeilingReferral,
                visit_member: productVisitMember,
                visit_family: productVisitFamily,
                facility_no: productFacilityNumber,
                max_facility_level: productMaxFacilityLevel,
                chronic_rate: productChronicRate,
                drug_access: productDrugAccess,
                coverage: productCoverage,
                exclusions: productExclusions,
                penalty_fee:penaltyFee,
            };
            productService.addProduct(payload).then(
                (result) => {
                    setToastVariant("light");
                    setShowToast(true);
                    setAlert("Product Successfully created", "success");
                    setProductName("");
                    setProductBasePremium("0.00");
                    setProductPremium("0.00");
                    setProductCopayIpd("0.00");
                    setProductCopayOpd("0.00");
                    setProductCeilingIpd("0.00");
                    setProductCeilingOpd("0.00");
                    setShowForm(false);
                    loadProducts();
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage);
                }
            );
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Products</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">Products</h4>
                        <div>
                            <Button
                                variant="outline-info"
                                size="sm"
                                onClick={history.goBack}
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            <Button
                                variant="success"
                                size="md"
                                onClick={toggleForm}
                            >
                               Create
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {showForm && (
                <Col xs="col-12">
                    <Card>
                        <Card.Body>
                            <Card.Subtitle className="mb-3">
                                Add new Product
                            </Card.Subtitle>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="gx-3 gy-3">
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="product Name"
                                                value={productName}
                                                onChange={(e) =>
                                                    setProductName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name .
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Criteria"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="schemeName"
                                                value={productCriteria}
                                                onChange={(e) =>
                                                    setProductCriteria(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid criteria.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Base Premium"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                min="0.01"
                                                value={productBasePremium}
                                                onChange={(e) =>
                                                    setProductBasePremium(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Premium"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                min="0.01"
                                                value={productPremium}
                                                onChange={(e) =>
                                                    setProductPremium(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Copay OPD"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                step="100"
                                                min="0.00"
                                                value={productCopayOpd}
                                                onChange={(e) =>
                                                    setProductCopayOpd(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Copay IPD"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                step="100"
                                                min="0.00"
                                                value={productCopayIpd}
                                                onChange={(e) =>
                                                    setProductCopayIpd(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Ceiling OPD"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                min="0.01"
                                                value={productCeilingOpd}
                                                onChange={(e) =>
                                                    setProductCeilingOpd(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Ceiling IPD"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                min="0.01"
                                                value={productCeilingIpd}
                                                onChange={(e) =>
                                                    setProductCeilingIpd(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Ceiling Pharmacy</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="0.00"
                                        value={productCeilingPharm}
                                        onChange={(e) =>
                                            setProductCeilingPharm(
                                                e.target.value
                                            )
                                        }
                                    
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>                                        
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Ceiling Referral</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="0.00"
                                        value={productCeilingReferral}
                                        onChange={(e) =>
                                            setProductCeilingReferral(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.PenaltyFee"
                                >
                                    <Form.Label>Penalty Fee</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="100.00"
                                        min="0.00"
                                        value={penaltyFee}
                                        onChange={(e) =>
                                            setPenaltyFee(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Visit Member</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="1"
                                        min="1"
                                        value={productVisitMember}
                                        onChange={(e) =>
                                            setProductVisitmember(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Visit Family</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="1"
                                        min="1"
                                        value={productVisitFamily}
                                        onChange={(e) =>
                                            setProductVisitFamily(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>    
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Facility Number</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="1"
                                        min="1"
                                        value={productFacilityNumber}
                                        onChange={(e) =>
                                            setProductFacilityNumber(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>    
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Max Facility Level</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={productMaxFacilityLevel}
                                        onChange={(e) =>
                                            setProductMaxFacilityLevel(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>  
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Chronic Rate</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="0.00"
                                        value={productChronicRate}
                                        onChange={(e) =>
                                            setProductChronicRate(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group> 
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Drug Access</Form.Label>
                                    <Form.Select
                                        aria-label="Drug Access"
                                        value={
                                            productDrugAccess
                                        }
                                        onChange={(
                                            e
                                        ) =>
                                            setProductDrugAccess(
                                                e
                                                    .target
                                                    .value
                                            )
                                        }
                                        required
                                    >
                                        <option value="No">
                                            No
                                        </option>
                                        <option value="Yes">
                                            Yes
                                        </option>
                                    </Form.Select>    
                                </Form.Group>  
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Exclusions</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        value={productExclusions}
                                        onChange={(e) =>
                                            setProductExclusions(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid .
                                    </Form.Control.Feedback>
                                </Form.Group> 
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Coverage</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        value={productCoverage}
                                        onChange={(e) =>
                                            setProductCoverage(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid .
                                    </Form.Control.Feedback>
                                </Form.Group>         


                                    <Col md="3">
                                        <Button variant="primary" type="submit">
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            )}
            <Col className="col-12">
                <Card>
                    <Card.Body>
                        <Card.Title>Current Products </Card.Title>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Criteria</th>
                                        <th>Base Premium</th>
                                        <th>Premium</th>
                                        <th>Copay OPD</th>
                                        <th>Copay IPD</th>
                                        <th>Ceiling OPD</th>
                                        <th>Ceiling IPD</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.length < 1 && (
                                        <Spinner
                                            animation="border"
                                            variant="warning"
                                            className="mt-3"
                                        />
                                    )}
                                    {products.map((product, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                            <Link
                                                to={`/product/${product.id}/edit`}
                                                        >
                                                            {product.name}
                                                        </Link></td>
                                            <td>{product.criteria}</td>
                                            <td>{product.base_premium}</td>
                                            <td>{product.premium}</td>
                                            <td>{product.copay_opd}</td>
                                            <td>{product.copay_ipd}</td>
                                            <td>{product.ceiling_opd}</td>
                                            <td>{product.ceiling_ipd}</td>
                                            <td>
                                                {currentUser && currentUser['is_superuser'] && <>
                                            <Link
                                                            to={`/product/${product.id}`}
                                                            style={{
                                                                textDecoration:
                                                                    "none"
                                                            }}
                                                        >
                                                            {" "}
                                                            <EyeFill />
                                                        </Link>&nbsp;&nbsp;
                                            <Link
                                                to={`/product/${product.id}/edit`}
                                                title="Edit"
                                                        >
                                                            <PencilSquare />
                                                        </Link>
                                                        &nbsp;&nbsp;
                                            <a href="#"  title="Delete" onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                product.id
                                                            );
                                                        }}><Trash/></a>
                                                        </>}
                                                
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default Products;
