import axios from "axios";
import authHeader from "../auth/auth-header";
import {API_URL} from "../constants";

// For expired token
axios.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 200) {
          //console.log('Posted Successfully');
       }
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl); 
        }
    }
 );

const getAllProviders = (offset='',limit=50) => {
    return axios
        .get(API_URL + `providers/provider/?limit=${limit}&offset=${offset}`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const filterProviders = (
    name = "",
    district = "",
    subcounty = "",
    parish = "",
    village = "",
    level ="",
    ownership ="",
    billMethod =""
) => {
    return axios
        .get(
            API_URL +
                `providers/provider/?name=${name}&district=${district}&subcounty=${subcounty}&parish=${parish}&village=${village}&level=${level}&ownership=${ownership}&bill_method=${billMethod}`,
            { headers: authHeader() }
        )
        .then((response) => {
            return response.data.results;
        });
};

const getAllClaims = () => {
    return axios
        .get(API_URL + "providers/claim/", { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};
const filterClaims = (subscheme='',member='',provider='',createdBy='',isReferral='') => {
    return axios
        .get(API_URL + `providers/claim/?subscheme=${subscheme}&member=${member}&provider=${provider}&created=${createdBy}&is_referral=${isReferral}`, { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};

const getClaimSummary = (params={}) => {
    return axios
        .get(API_URL + `providers/claim/summary/`, {params, headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getAllInvoices = (offset='',limit=50) => {
    return axios
        .get(API_URL + `providers/invoice/?limit=${limit}&offset=${offset}`, { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};

const addProvider = (provider) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "providers/provider/", {
            name: provider.name,
            status: provider.status,
            bed_no: provider.bed_no,
            level: provider.level,
            bill_method: provider.bill_method,
            district: provider.district,
            county: provider.county,
            subcounty: provider.subcounty,
            parish: provider.parish,
            village: provider.village,
            ownership: provider.ownership,
            first_contact: provider.first_contact,
            start_date: provider.start_date,
            expiry_date: provider.expiry_date
        })
        .then((response) => {
            return response.data;
        });
};
const addClaim = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "providers/claim/", {
            visit: payload.visit,
            guid: payload.guid,
            ward: payload.ward,
            diagnosis: payload.diagnosis,
            patient_state: payload.patient_state,
            is_referral: payload.is_referral,
            facility: payload.facility,
            admission_date: payload.admission_date,
            discharge_date: payload.discharge_date,
            amount: payload.amount,
            bill_amount: payload.bill_amount,
            copay: payload.copay,
            amount_paid: payload.amount_paid,
            member: payload.member,
            provider: payload.provider
        })
        .then((response) => {
            return response.data;
        });
};

const addInvoice = (invoice) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "invoice/", {
            name: invoice.name,
            subscheme: invoice.subscheme,
            posted_on: invoice.posted_on,
            guid: invoice.guid,
            owner: invoice.owner,
            village: invoice.village,
            joined_on: invoice.joined_on,
        })
        .then((response) => {
            return response.data;
        });
};

const updateProvider = (provider, providerId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .put(API_URL + `providers/provider/${providerId}/`, {            
            name: provider.name,
            status: provider.status,
            bed_no: provider.bed_no,
            level: provider.level,
            bill_method: provider.bill_method,
            district: provider.district,
            county: provider.county,
            subcounty: provider.subcounty,
            parish: provider.parish,
            village: provider.village,
            ownership: provider.ownership,
            first_contact: provider.first_contact,
            start_date: provider.start_date,
            expiry_date: provider.expiry_date
        })
        .then((response) => {
            return response.data;
        });
};

const getProvider = (id) => {
    return axios
        .get(API_URL + `providers/provider/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const deleteProvider = (providerId) => {
    return axios
        .delete(API_URL + `providers/provider/${providerId}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const deleteClaim = (claimId) => {
    return axios
        .delete(API_URL + `providers/claim/${claimId}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};
const deleteInvoice = (invoiceId) => {
    return axios
        .delete(API_URL + `providers/invoice/${invoiceId}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

export default {
    getAllProviders,
    getAllClaims,
    getAllInvoices,
    addProvider,
    addClaim,
    addInvoice,
    updateProvider,
    deleteProvider,
    deleteClaim,
    deleteInvoice,
    getProvider,
    filterClaims,
    getClaimSummary,
    filterProviders,
};
