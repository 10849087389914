import axios from "axios";
import authHeader from "../auth/auth-header";
import { API_URL } from "../constants";
import axiosAPI from '../axiosApi'

const getAllFunds = (params) => {
    return axiosAPI
        .get(`funds/fund/`, { headers: authHeader(), params:params })
        .then((response) => {
            return response.data;
        });
};
const getAllSplits = (params={}) => {
    return axiosAPI.get("funds/splits/", { params})
};


const addFund = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axiosAPI
        .post(API_URL + "funds/fund/", {
            name: payload.name,
            scheme: payload.scheme,
            
        })
        .then((response) => {
            return response.data;
        });
};
const addSplit = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axiosAPI
        .post(API_URL + "funds/splits/", {
            name: payload.name,
            shortname: payload.shortname,
            
        })
        .then((response) => {
            return response.data;
        });
};



const updateFund = (payload, payloadId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axiosAPI
        .put(API_URL + `funds/fund/${payloadId}/`, payload)
        .then((response) => {
            return response.data;
        });
};

const getFund = (id) => {
    return axiosAPI
        .get(`funds/fund/${id}/`, { headers: authHeader() });
};

const deleteFund = (id) => {
    return axiosAPI
        .delete(`funds/fund/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const deleteSplit = (id) => {
    return axiosAPI
        .delete(`funds/splits/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};


const getAllFees = (params={}) => {
    return axiosAPI
        .get("funds/fees/", { headers: authHeader(), params:params })
        .then((response) => {
            return response.data.results;
        });
};

const getFee = (id) => {
    return axiosAPI
        .get( `funds/fees/${id}`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const updateFee = (payload, payloadId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axiosAPI
        .put(  `funds/fees/${payloadId}/`, payload)
        .then((response) => {
            return response.data;
        });
};

const deleteFee = (id) => {
    return axiosAPI
        .delete(  `funds/fees/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const addFee = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axiosAPI
        .post("funds/fees/", payload)
        .then((response) => {
            return response.data;
        });
};

const getAllAdjustments = (params) => {
    return axiosAPI.get("funds/adjustments/",{params})
};

const postAdjustment = (formData) => {
    return axiosAPI.post("funds/adjustments/", formData)
}

export default {
    getAllFunds,
    getAllSplits,
    addFund,
    addSplit,
    updateFund,
    deleteFund,
    deleteSplit,
    getFund,
    getAllFees,
    getFee,
    updateFee,
    addFee,
    deleteFee,
    getAllAdjustments,
    postAdjustment,
};
