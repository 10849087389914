import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import { EyeFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import { getAllInvoices } from '../../services/provider/invoice-service';
import AuthDetailContext from '../common/AuthDetailContext';
import { formatHttpErrorMessage, formatNumber } from '../common/helper_functions';
import AlertContext from '../widgets/alertPopup/AlertContext';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import InvoiceFilter from './InvoiceFilter';
import InvoiceForm from './InvoiceForm';
import InvoiceAdmissionsForm from './InvoiceAdmissionsForm';

function Invoices() {
  const  currentUser = useContext(AuthDetailContext);
  const { setAlert } = useContext(AlertContext);
  const [invoices, setInvoices] = useState([]);
  const [showInvoiceForm, setShowInvoiceForm] = useState();
  const [showAdmissionInvoiceForm, setShowAdmissionInvoiceForm] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    getAllUserInvoices();
  }, []);

  const getAllUserInvoices = async (params={}) => {
      await getAllInvoices(params).then((response) => {
        setInvoices(response.data.results);
        
      }).catch((error) => {
         let message = formatHttpErrorMessage(error);
         setAlert(message, "danger");
      });
      setLoading(false);
  }

  return (
    <>
      <Row>     
            <Col xs="12" className="pt-3">
                <h4>Invoices</h4>
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/invoices"> Invoices </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs={12} className="mb-2">
              <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <InvoiceFilter search={(params) => getAllUserInvoices(params)} />
                      </Col>
                    </Row>
                    </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
                <Card>
                  <Card.Body>
                    
                    <Row className="mt-1 mb-2">
                      <Col className='text-end'>
                          {currentUser && currentUser['type']=='ProviderFinance' && <Button variant="success" onClick={() => setShowInvoiceForm(true)} size="md" >Create Invoice</Button>}
                          {currentUser && currentUser['is_superuser'] && <Button variant="success" onClick={() => setShowAdmissionInvoiceForm(true)} size="md" >Create Admissions Invoice</Button>}
                          {showInvoiceForm && <InvoiceForm show={showInvoiceForm} onHide={() => setShowInvoiceForm(false)} />}
                          {showAdmissionInvoiceForm && <InvoiceAdmissionsForm show={showAdmissionInvoiceForm} onHide={() => setShowAdmissionInvoiceForm(false)} />}
                      </Col>
                      <Col>
                      {loading ==false && <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button btn btn-success mb-3"
                            table="table-to-xls"
                            filename="claim_export"
                            sheet="tablexls"
                            buttonText="Export Data to Excel Sheet"
                            /> }
                      </Col>
                    </Row>
                    
                    <Table id="table-to-xls">
                      <thead>
                        <tr>
                          <th>#</th>
                          {currentUser && currentUser['type'] !='Provider'  && <th>Provider</th>}
                          <th>Invoice Number</th>
                          <th>Amount</th>
                          <th>Balance</th>
                          <th>Month</th>
                          <th>Close Date</th>
                          <th>Created</th>
                          <th>Status</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices.map((item, i) => (
                            <tr key={i}>
                              <td>{i+1}</td>
                              {currentUser && currentUser['type'] !='Provider' && <td> {item.provider} </td>}
                              <td><Link to={`/invoices/${item.id}`}>{item.inv_no}</Link></td>
                              <td>{formatNumber(item.amount)}</td>
                              <td>{formatNumber(item.amount - item.amount_paid)}</td>
                              <td>{item.inv_month}</td>
                              <td>{item.close_date && moment(item.close_date).format("lll")}</td>
                              <td>{moment(item.created).format("lll")}</td>
                              <td>{item.status}</td>
                              <td><Link to={`/invoices/${item.id}`}><EyeFill /></Link></td>
                            </tr>
                        ))}
                       
                      </tbody>
                    </Table>
                    {loading && <center><Spinner animation='border'/></center>}
                  </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
  )
}

export default Invoices