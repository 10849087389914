import { useEffect, useState } from "react";
import {
    Grid,
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import fundService from "../../services/fund/fund-service";

const Fund = ({ currentUser, match }) => {
    const { fundId, edit } = match.params;
    const [fund, setFund] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [fundName, setFundName] = useState("");
    const [fundShare ,setfundShare] = useState(0);
    const [validated, setValidated] = useState(false);
    const [fundCode, setFundCode] = useState("");
    const history = useHistory();

    useEffect(() => {
        checkForEditInUrl();
        loadFund(fundId);
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadFund = (fundId) => {
        fundService.getFund(fundId).then(
            (result) => {
                console.log("result", result);
                setFund(result);
                setFundName(result.name);
                setfundShare(result.share_rate);
                setFundCode(result.code);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let fund = {
                name: fundName,
                code: fundCode,
                share_rate: fundShare,
            };
            fundService.updateFund(fund, fundId).then(
                (result) => {
                    setToastVariant("light");
                    setShowForm(false);
                    loadFund(fundId)
                    setShowToast(true);
                    setToastMessage("Successfully updated Fund");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            setEdit(true);
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/funds"> Fund </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {fund ? fund.name : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="col-12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">
                            Fund: <strong>{fund ? fund.name : ""}</strong>
                        </h4>
                        <div>
                            <Button
                                variant="outline-info"
                                onClick={history.goBack}
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            <Button
                                variant="primary"
                                size="sm"
                                className="m-0"
                                onClick={toggleEdit}
                            >
                                Edit
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        {!fund && (
                            <Spinner animation="border" variant="warning" />
                        )}
                        {fund && (
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                className="row g-3"
                            >
                                <Form.Group
                                    className="col-md-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={fundName}
                                        onChange={(e) =>
                                            setFundName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name for a
                                        scheme.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={fundCode}
                                        onChange={(e) =>
                                            setFundCode(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid short name for a
                                        scheme.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Share Rate</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        min={0}
                                        value={fundShare}
                                        onChange={(e) =>
                                            setfundShare(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                {isEdit && (
                                    <div className="col-12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Update Fund
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Col>
          
        </Row>
    );
};
export default Fund;
