import { useContext, useEffect, useState } from "react";
import {
    Grid,
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import helperService from "../../services/helpers/helper-service";
import schemeService from "../../services/scheme/scheme-service";
import Families from "./Families";
import AsyncSelect from 'react-select/async';
import AuthDetailContext from "../common/AuthDetailContext";

const SubScheme = ({ match }) => {
    const currentUser = useContext(AuthDetailContext);
    const { schemeId, edit } = match.params;
    const [scheme, setScheme] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [parentSchemes, setParentSchemes] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [schemeName, setSchemeName] = useState("");
    const [schemeParent, setSchemeParent] = useState("");
    const [schemeDistrict, setSchemeDistrict] = useState("");
    const [schemeSubcounty, setSchemeSubcounty] = useState("");
    const [schemeParish, setSchemeParish] = useState("");
    const [schemeVillage, setSchemeVillage] = useState("");
    const [schemeTargetPopulation, setSchemeTargetPopulation] = useState(0);
    const [schemeYearFormed, setSchemeYearFormed] = useState("");
    const [schemeStatus, setSchemeStatus] = useState("");
    const [validated, setValidated] = useState(false);
    const [schemeShortName, setSchemeShortName] = useState("");
    const [schemeBody, setSchemeBody] = useState("");
    const [districts, setDistricts] = useState([]);
    const [counties, setCounties] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [searchDistrictInput,setSearchInput] = useState("");
    const [selectedDistrict,setDistrict] =useState(undefined);
    const [selectedCounty,setSelectedCounty] = useState(undefined);
    const [billType, setBillType] = useState();
    
    const history = useHistory();

    useEffect(() => {
        checkForEditInUrl();
        loadParentSchemes();
        loadDistricts({limit:200});
        loadSubcounties({limit:300});
        loadScheme(schemeId);
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadScheme = (schemeId) => {
        schemeService.getSubScheme(schemeId).then(
            (result) => {
                
                setScheme(result);
                setSchemeName(result.name);
                setSchemeShortName(result.shortname);
                setSchemeBody(result.body);
                setSchemeYearFormed(result.year_formed);
                setSchemeDistrict(result.district);
                setDistrict(result.district)
                setSchemeSubcounty(result.subcounty);
                setSelectedCounty(result.county)
                setSchemeParish(result.parish);
                setSchemeVillage(result.village);
                setSchemeParent(result.scheme);
                setSchemeTargetPopulation(result.target_population);
                findDistrictByName(result.district,result.subcounty);
                setSchemeStatus(result.status);
                setBillType(result.bill_type);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };
    const loadParentSchemes = () => {
        schemeService.getAllSchemes().then(
            (result) => {
                setParentSchemes(result.results);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setToastMessage(resMessage);
                setShowToast(true);
            }
        );
    };

    const loadDistricts = () => {
        helperService.getAllDistricts().then(
            (districts) => {
                setDistricts(districts);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };
    const loadSubcounties = () => {
        helperService.getAllSubcounties().then(
            (subCounties) => {
                setSubcounties(subCounties);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            schemeService.deleteScheme(id).then(
                () => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully Deleted scheme");
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        } else {
        }
    };

    const findDistrictByName = (name,subcountyName) => {
        helperService.getDistrictByName(name).then(
            (result) => {
                 setDistrict(result[0]);
                 helperService.getCountyByDistrictId(result[0].id).then(
                     (counties) => {
                         setCounties(counties)
                         //set selected subcounty
                         helperService.filterSubcounty(subcountyName).then(
                             (subcountyResults) => {
                                 const countyId = (subcountyResults.length > 0) ? subcountyResults[0].county : 0;
                                 setSelectedCounty(countyId);
                                 // get subcounties of a county
                                 helperService.getSubcountyByCountyId(countyId).then(
                                     (subs) =>{
                                         setSubcounties(subs);
                                     }
                                 )
                                 
                             },
                             (error) => {
 
                             }
                         )
                     },
                     (error) => {
         
                     }
                 )
            },
            (error) => {
 
            }
        )
         
         
        
     }
 
     const filterDistricts = (inputValue) => {
         return districts.filter(district =>
             district.name.includes(String(inputValue).toUpperCase())
           )
       };
 
     
       const loadOptions = (inputValue, callback) => {
         setTimeout(() => {
           callback(filterDistricts(inputValue));
         }, 100);
     };
 
     const handleInputChange = (newValue) => {
         const inputValue = newValue.replace(/\W/g, '');
         setSearchInput(inputValue);        
         return inputValue;
       };  
 
       const handleDistrictChange = (newValue) => {
         console.log(newValue);
         if(newValue !== null){
             setSchemeDistrict(newValue.name);
             //get subcounties by name
             helperService.getCountyByDistrictId(newValue.id).then(
                 (counties) => {
                     setCounties(counties)
                 },
                 (error) => {
 
                 }
             )
         }
         
        
       }; 
       
       const handleCountyChange = (event) => {
         console.log(event.target.options[event.target.selectedIndex].text)
        // setProviderCounty(event.target.options[event.target.selectedIndex].text)
        setSelectedCounty(event.target.value);
         //get subcounties by name
         helperService.getSubcountyByCountyId(event.target.value).then(
             (subcounties) => {
                 setSubcounties(subcounties)
             },
             (error) => {
 
             }
         )
         
       };


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let scheme = {
                name: schemeName,
                shortname: schemeShortName,
                scheme: schemeParent,
                year_formed: schemeYearFormed,
                target_population: schemeTargetPopulation,
                district: schemeDistrict,
                subcounty: schemeSubcounty,
                parish: schemeParish,
                village: schemeVillage,
                status:schemeStatus,
                bill_type:billType,
            };
            schemeService.updateSubScheme(scheme, schemeId).then(
                (result) => {
                    setToastVariant("light");

                    loadScheme(schemeId);
                    setShowToast(true);
                    setToastMessage("Successfully updated sub scheme");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            setEdit(true);
        }
    };

    return (
        <Row className="g-3">
            
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/schemes"> Schemes </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/sub-schemes"> Sub Schemes </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {scheme ? scheme.name : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">
                            Sub Scheme:{" "}
                            <strong>{scheme ? scheme.name : ""}</strong>
                        </h4>
                        <div>
                            <Button
                                variant="outline-info"
                                onClick={history.goBack}
                                size="sm"
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            {currentUser && (currentUser['is_superuser'] || currentUser['is_staff'] ===false) && (<>
                            <Button
                                variant="primary"
                                onClick={toggleEdit}
                                size="sm"
                            >
                                Edit
                            </Button></>)}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        {subcounties.length < 1 && (
                            <Spinner animation="border" variant="warning" />
                        )}
                        {subcounties.length > 0 && (
                            <Form
                                className="row gx-3 gy-2"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Scheme name</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={schemeName}
                                        onChange={(e) =>
                                            setSchemeName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name for a
                                        scheme.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Short name</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly
                                        type="text"
                                        value={schemeShortName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid short name for a
                                        scheme.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Founded on</Form.Label>
                                    <Form.Control
                                        readOnly={!isEdit}
                                        type="number"
                                        value={schemeYearFormed}
                                        onChange={(e) =>
                                            setSchemeYearFormed(e.target.value)
                                        }
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Target Population</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        min={0}
                                        value={schemeTargetPopulation}
                                        onChange={(e) =>
                                            setSchemeTargetPopulation(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="parentschemeId"
                                >
                                    <Form.Label>Parent Scheme</Form.Label>
                                    <Form.Select
                                        aria-label="select Parent Scheme"
                                        disabled={!isEdit}
                                        value={schemeParent}
                                        onChange={(e) =>
                                            setSchemeParent(e.target.value)
                                        }
                                        required
                                    >
                                        <option value="">None</option>
                                        {parentSchemes.map((parent) => (
                                            <option
                                                key={parent.id}
                                                value={parent.id}
                                            >
                                                {parent.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select parent scheme.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="districtchemeId"
                                >
                                    <Form.Label>District</Form.Label>
                                    <AsyncSelect
                                                        cacheOptions
                                                        isClearable
                                                        placeholder="select district"
                                                        defaultValue={selectedDistrict}
                                                        loadOptions={loadOptions}
                                                        defaultOptions={districts}
                                                        getOptionLabel={(option) => `${option.name}`}
                                                        getOptionValue={(option) => `${option.id}`}
                                                        onInputChange={handleInputChange}
                                                        onChange={handleDistrictChange}
                                                        />
                                            <Form.Control.Feedback type="invalid">
                                                Please select district.
                                            </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="countychemeId"
                                >
                                    <Form.Label>County</Form.Label>
                                    <Form.Select
                                        aria-label="select County"
                                        disabled={!isEdit}
                                        value={schemeSubcounty}                                        
                                        onChange={(e) =>
                                            handleCountyChange(
                                                e
                                            )
                                        }
                                        
                                    >
                                        <option value="">None</option>
                                        {counties.map(
                                            (county) => (
                                                <option
                                                    key={county.id}
                                                    value={county.name}
                                                >
                                                    {county.name}
                                                </option>
                                            )
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select subcounty.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="subcountychemeId"
                                >
                                    <Form.Label>Subcounty</Form.Label>
                                    <Form.Select
                                        aria-label="select Sub county"
                                        disabled={!isEdit}
                                        value={schemeSubcounty}
                                        onChange={(e) =>
                                            setSchemeSubcounty(e.target.value)
                                        }
                                        required
                                    >
                                        <option value="">None</option>
                                        {subcounties.map((subcounty) => (
                                            <option
                                                key={subcounty.id}
                                                value={subcounty.name}
                                            >
                                                {subcounty.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select subcounty.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="schemeparishid"
                                >
                                    <Form.Label>Parish</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={schemeParish}
                                        onChange={(e) =>
                                            setSchemeParish(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid parish
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="schemevillagehid"
                                >
                                    <Form.Label>Village</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={schemeVillage}
                                        onChange={(e) =>
                                            setSchemeVillage(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid village
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="subcountychemeId"
                                >
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        aria-label="select Scheme Status"
                                        disabled={!isEdit}
                                        value={schemeStatus}
                                        onChange={(e) =>
                                            setSchemeStatus(e.target.value)
                                        }
                                        required
                                    >
                                        <option value="Suspended">Suspended</option>
                                        <option value="Dropped">Dropped</option>
                                         <option value="Active"> Active </option>
                                        
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Status.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="subcountychemeId"
                                >
                                    <Form.Label>Billing Type</Form.Label>
                                    <Form.Select
                                        aria-label="select Billing type"
                                        disabled={!isEdit}
                                        value={billType}
                                        onChange={(e) =>
                                            setBillType(e.target.value)
                                        }
                                        required
                                    ><option>---Select---</option>
                                        <option value="min_cover">Minimum cover</option>
                                        <option value="copayment">Copayment</option>
                                         
                                        
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Billing Type.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {isEdit && (
                                    <div className="col-md-3 mt-3">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default SubScheme;
