import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Breadcrumb, Button, Card, Col, Row, Spinner, Table,  } from 'react-bootstrap';
import { Trash, Trash2Fill } from 'react-bootstrap-icons';
import { Link, useParams, useHistory } from 'react-router-dom';
import { deleteClaimAttachment, deleteInvoiceEntry, getInvoiceById, getInvoiceClaims, markAsPaid, markAsRejected, submitInvoice } from '../../services/provider/invoice-service';
import AuthDetailContext from '../common/AuthDetailContext';
import { formatHttpErrorMessage } from '../common/helper_functions';
import AlertContext from '../widgets/alertPopup/AlertContext';
import InvoicePaymentForm from './InvoicePaymentForm';
import ReactHtmlTableToExcel from "react-html-table-to-excel";

function InvoiceDetails() {
  let {id} = useParams();
  const currentUser = useContext(AuthDetailContext);
  const {setAlert} = useContext(AlertContext);
  const [invoice, setInvoice] = useState();
  const [claims, setClaims] = useState();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getSelectedInvoice();
    getSelectedInvoiceClaims();
  },[id]);

  const getSelectedInvoice = async () => {
      await getInvoiceById(id).then(response => {
        setInvoice(response.data)
      }).catch(
        err => {

        }
      );
      setLoading(false);
  }

  const getSelectedInvoiceClaims = async () => {
     await getInvoiceClaims(id).then(response => {
        setClaims(response.data)
     }).catch(err => {

     });
  }

  const submitCurrentInvoice = async () => {
      let check = window.confirm("Are you sure you want to submit this Invoice");
      if(check){
        await submitInvoice(id).then(resp => {
          getSelectedInvoice();
        }).catch(err => {
          let msg = formatHttpErrorMessage(err);
          setMessage(msg);
        });
      }
  }

  const markCurrentInvoicePaid = async () => {
    let check = window.confirm("Are you sure you want to mark this invoice has paid.");
    if(check){
      await markAsPaid(id).then(resp => {
        getSelectedInvoice();
      }).catch(err => {
        let msg = formatHttpErrorMessage(err);
        setMessage(msg);
      });
    }

  }

  const markCurrentInvoiceRejected = async () => {
    let check = window.confirm("Are you sure you want to mark this invoice has rejected.");
    if(check){
      await markAsRejected(id).then(resp => {
        getSelectedInvoice();
      }).catch(err => {
         let msg = formatHttpErrorMessage(err);
         setMessage(msg);
      });
    }

  }

  const deleteSelectedClaimAttachment = async (id) => {
      console.log(id);
     await deleteClaimAttachment(id).then(response => {
        getSelectedInvoice();
        getSelectedInvoiceClaims();
        setAlert("Claimitem '"+id+"' posted successfully", 'success');
     }).catch(error => {
        let msg = formatHttpErrorMessage(error);
        setMessage(msg);
        setAlert(msg, 'danger');
     });
  }

  const handlePaymentSuccess = () => {
    getSelectedInvoice();
    getSelectedInvoiceClaims();
    setShowPaymentForm(false);
    setAlert("Payment posted successfully", 'success');
  }

  const deleteCurrentInvoice = async () => {
    console.log("deleting Invoice");
    let check = window.confirm("Are you sure you want to delete this invoice.");
    if(check){
        await deleteInvoiceEntry(id).then(response => {
          console.log(response)
          setAlert("Invoice " + id +" Deleted Successfully", 'success');
          history.goBack()
        }).catch(error => {
          setAlert("Failed to delete invoice " + id, 'danger');
        });
      }
  }

  return (
    <>
      <Row className="g-3">
        <Col xs="12" className="pt-3 noprint">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/invoices">
                    Invoices
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {invoice && invoice.id}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
          <Col md={12} className="text-end noprint">
          {invoice && invoice.status == 'Draft' && (<>
            <Button variant='danger' onClick={() => deleteCurrentInvoice()}>
              Delete
            </Button>&nbsp;&nbsp;
            <Button variant="primary" onClick={() => submitCurrentInvoice()}>
                  Submit
                </Button>
          </>)}
                
                {currentUser && (currentUser['type']=='Org' || currentUser['is_staff']) && invoice && invoice.status != 'Paid' && (<>
                  <Button variant="success" onClick={() => setShowPaymentForm(true)}>
                    Make Payment
                  </Button> &nbsp;
                  {showPaymentForm && <InvoicePaymentForm show={showPaymentForm} onHide={() => setShowPaymentForm(false)} invoiceId={id} amount={invoice.amount - invoice.amount_paid} onSuccess={handlePaymentSuccess} />}
                <Button variant="danger" onClick={markCurrentInvoiceRejected}>
                  Reject
                </Button>
                </>
                 
                )}
                
          </Col>
          <Col md={12}>
              <Card>
                <Card.Body>
                   {message && <Alert variant='success'>{message}</Alert>}
                    {loading && <center><Spinner animation='border' /></center>}
                    {invoice && <Row>
                      <Col md={6}>
                      <Table>
                      <tbody>
                        <tr>
                        <th>Provider</th>
                        <td>{invoice.provider.name}</td>
                        </tr>
                        
                        <tr>
                        <th>Amount</th>
                        <td>{invoice.amount}</td>
                        </tr>
                        <tr>
                        <th>Invoice No</th>
                        <td>{invoice.inv_no}</td>
                        </tr>
                        <tr>
                        <th>Discount</th>
                        <td>{invoice.discount} ({invoice.discount_type})</td>
                        </tr>
                        <tr>
                        <th>Total</th>
                        <td>{invoice.discount_type=='Fixed' ? <>{invoice.amount - invoice.discount}</>:<>{invoice.amount - (invoice.amount*(invoice.discount/100))}</>}</td>
                        </tr>
                        <tr>
                        <th>Paid</th>
                        <td>{invoice.amount_paid}</td>
                        </tr>
                      </tbody>
                    </Table>
                      </Col>
                      <Col md={6}>
                      <Table>
                      <tbody>
                      <tr>
                        <th>Scheme</th>
                        <td>{invoice.scheme_name}</td>
                        </tr>
                        <tr>
                        <th>Invoice Month</th>
                        <td>{invoice.inv_month}</td>
                        </tr>
                        <tr>
                        <th>Due Date</th>
                        <td>{invoice.due_date}</td>
                        </tr>
                        <tr>
                        <th>Status</th>
                        <td>{invoice.status}</td>
                        </tr>
                        <tr>
                        <th>Author</th>
                        <td>{invoice.owner}</td>
                        </tr>
                        <tr>
                        <th>Created</th>
                        <td>{moment(invoice.created).format("LLL")}</td>
                        </tr>
                      </tbody>
                    </Table>
                      </Col>
                    </Row>}
                    
                </Card.Body>
              </Card>
              <Card className='mt-2 noprint'>
                <Card.Body>
                <Row>
                      <Col>
                      {loading ==false && <ReactHtmlTableToExcel
                              id = "test-table-xls-button"
                              className = "download-table-xls-button btn btn-success mb-3"
                              table = "table-to-xls"
                              filename = {`invoice${invoice.id}_export`}
                              sheet = "tablexls"
                              buttonText = "Export Data to Excel Sheet"
                            /> }
                      </Col>
                      <Col xs={12}>
                          <Table  id="table-to-xls">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Member</th>
                                <th>Ward</th>
                                <th>Visit</th>
                                <th>Amount</th>
                                <th>Created</th>
                              </tr>
                            </thead>
                            <tbody>
                            {claims && claims.map((claim, j) => (<>
                                <tr>
                                  <th colspan="6">{claim.scheme ? claim.scheme : 'No Scheme Registered'}</th>
                                </tr>
                                {claim.results.map((item, i) => (<tr key={i+1}>
                                  <td>{i + 1}</td>
                                  <td><Link to={`/claims/${item.claim.id}`}>{item.claim.member.firstname} {item.claim.member.lastname}</Link></td>
                                  <td>{item.claim.ward}</td>
                                  <td>{item.claim.visit}</td>
                                  <td>{item.amount}</td>
                                  <td>{moment(item.claim.created).format("lll")}</td>
                                  <td><a href="#" onClick={() => deleteSelectedClaimAttachment(item.id)}><Trash /></a></td>
                              </tr>))}
                                
                              </>))}
                            </tbody>
                          </Table>
                      </Col>
                    </Row>
                </Card.Body>
              </Card>
          </Col>
        </Row>
    </>
  )
}

export default InvoiceDetails