import { useEffect, useState, useRef, useContext } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import {EyeFill, PencilSquare} from "react-bootstrap-icons";
import productService from "../../services/product/product-service";
import schemeService from "../../services/scheme/scheme-service";
import subscriptionService from "../../services/subscription/subscription-service";
import BadgeWidget from "../widgets/BadgeWidget";
import { getFormValues } from "../common/helper_functions";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import Pager from "../common/Pager";
import PolicyStatusForm from "./PolicyStatusForm";

const Policies = (props) => {
    const { familyId, productId } = props.match.params;
    const currentUser = useContext(AuthDetailContext);
    const {setAlert} = useContext(AlertContext);
    const tableRef = useRef(null);
    const [products, setProducts] = useState([]);
    const [families, setFamilies] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [policyStartDate, setOfferStartDate] = useState("");
    const [policyEndDate, setOfferEndDate] = useState("");
    const [policyFamily, setOfferFamily] = useState("");
    const [policyProduct, setOfferProduct] = useState("");
    const [policyPostDate, setOfferPostDate] = useState("");
    const [schemes, setSchemes] = useState([])
    const [subschemes, setSubschemes] = useState([]);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(50);
    const [activeParams, setActiveParams] = useState({})
    const [selectedPolicy, setSelectedPolicy] = useState();
    const [showStatusForm, setShowStatusForm] = useState(false);
    const [summary, setSummary] = useState({})
    const history = useHistory();

    useEffect(() => {
        loadProducts();
        loadPolicies();
        getSearchSchemes();
        getPolicySummary();
        if (familyId !== undefined) {
            setOfferFamily(familyId);
        }
        
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const getSearchSchemes = async () => {
        await schemeService.getAllSchemes().then((response) => setSchemes(response.results));
    }

    const getSubSchemes = async (scheme) => {
        setSubschemes([]);
        await schemeService.searchSubSchemes({scheme:scheme}).then((response)=> setSubschemes(response.results));
    }
    

    const generateUniqueId = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };
    const loadProducts = () => {
        productService.getAllProducts().then(
            (payload) => {
                setProducts(payload);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setAlert(resMessage);
            }
        );
    };

    const loadPolicies = (nextPage=1, formParams={}) => {
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
		let params = {offset:offset, ...formParams}
		if(Object.keys(formParams).length > 0){
			setActiveParams(formParams);
			params = {offset:offset, ...formParams}
		}else{
			params = {offset:offset, ...activeParams}
		}

        if(familyId){
            params ={family:familyId}
        }
        subscriptionService.getAllPolicies(params).then(
            (payload) => {
                setPolicies(payload.data.results);
                setLoading(false);
                const pages = (payload.data.count > 0) ? Math.ceil(payload.data.count/entriesPerPage) : 0
                setNumberOfPages(pages);               
                setActivePage(nextPage);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };

    const getPolicySummary = (params={}) => {
        subscriptionService.getPolicySummary(params).then((response) => {
            setSummary(response)
        })
    }

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            subscriptionService.deleteOffer(id).then(
                () => {
                    
                    setAlert("Successfully Deleted Offer");
                    loadPolicies();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setAlert(resMessage,"danger");
                }
            );
        } else {
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let params = getFormValues(e);
        setLoading(true);
        loadPolicies(1,params);
        getPolicySummary(params)
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let user = localStorage.getItem("user"); 
            let payload = {
                start_date: policyStartDate,
                end_date: policyEndDate,
                post_date: policyPostDate,
                product: policyProduct,
                family: policyFamily,
                guid: generateUniqueId(),
                owner: JSON.parse(user).id,
            };
            subscriptionService.addPolicy(payload).then(
                (result) => {
                    
                    setAlert("Successfully created policy");
                    setOfferEndDate("");
                    setOfferProduct("");
                    setOfferStartDate("");
                    setOfferPostDate("");
                    setShowForm(false);
                    loadPolicies();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setAlert(resMessage,"danger");
                }
            );
        }
    };

    const goToPage = (event, page) => {
        loadPolicies(page);
    }

    const showPolicyEditForm = (selected) => {
        setSelectedPolicy(selected);
        setShowStatusForm(true);
    }


    const onStatusChangeSuccess = (message) => {
        setAlert(message)
        loadPolicies();
        setShowStatusForm(false);
        
    }

    return (
        <Row className="g-3">
            {!props.basic &&<>
            <Col xs="12" className="pt-3">
                <h4 className="m-0">Policies </h4>
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/products"> Products</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Policies</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
           
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        
                        <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSearch}
                            >
                            <Row>
                                <Col md={3}>
                                <Form.Select
                                                aria-label="Product"
                                                name='product'
                                                
                                            >
                                                <option value="">Choose Product</option>
                                                {products.map((product) => (
                                                    <option
                                                        key={product.id}
                                                        value={product.id}
                                                    >
                                                        {product.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                <Col md="3">
                                <Form.Select
                                                aria-label="select Scheme"
                                                name='family__subscheme__scheme'
                                                onChange={(e)=> getSubSchemes(e.target.value)}
                                                
                                            >
                                                <option value="">Choose Scheme</option>
                                                {schemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                <Col md={3}>
                                <Form.Select
                                                aria-label="select Sub Scheme"
                                                name='family__subscheme'
                                            >
                                                <option value="">{subschemes.length <=0 ? 'Loading...':'Choose Sub-Scheme'}</option>
                                                {subschemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                
                             
                                <Col>
                                <Form.Select
                                                aria-label="select Status"
                                                name="status"
                                            >
                                                <option value="">Choose Status</option>
												<option value="Active">Active</option>
												<option value="Suspended">Suspended</option>
												<option value="Expired">Expired</option>
                                            </Form.Select>
                                </Col>
                                <Col xs="1">
                                <Button
                                            variant="primary"
                                            type="submit"
                                            size="md"
                                        >
                                            Search
                                        </Button>
                                </Col>
                            </Row>
                    </Form>
                    </Card.Body>
                </Card>
            </Col>
            </>}
            {summary && <Col md={12}>
                 <Row>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <h6>Total Premium</h6>
                                <h4>{summary.total_sum ? summary.total_sum.toLocaleString() : "0"}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                    <Card>
                            <Card.Body>
                                <h6>No. Policies</h6>
                                <h4>{summary.total}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                    <Card>
                            <Card.Body>
                                <h6>No. Members</h6>
                                <h4>{summary.members}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                                   
            </Col>}
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Card.Title>Current Policies </Card.Title>
                        <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-success mb-3"
                    table="table-to-xls"
                    filename="policies_export"
                    sheet="tablexls"
                    buttonText="Export Data to Excel Sheet"/>

                
                        <div className="table-responsive">
                            <Table striped bordered hover  ref={tableRef}  id="table-to-xls">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Family</th>
                                        <th>Product</th>
                                        <th>Members</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Status</th>
                                       
                                        <th>Premium</th>                                        
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {loading==false && policies.map((policy, index) => (
                                        <tr key={index}>
                                            <td>{index + 1 + (entriesPerPage * (activePage - 1))}</td>
                                            <td>
                                               <Link to={`/family/` + policy.family.id}>{policy.family.name}</Link> 
                                            </td>
                                            <td>
                                                {policy.product_name}
                                            </td>
                                            <td>
                                                {policy.member_count}
                                            </td>
                                            <td>{policy.start_date}</td>
                                            <td>{policy.end_date}</td>
                                            <td><BadgeWidget text={policy.status} /></td>
                                            
                                            <td>{policy.premium}</td>                                            
                                            <td>
                                              
                                                   
                                                        <Button
                                                          onClick={() => showPolicyEditForm(policy.id)}
                                                            
                                                        >
                                                    
                                                            <PencilSquare />
                                                        </Button>
                                                   
                                                
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {loading && (
                                        <Spinner
                                            animation="border"
                                            variant="warning"
                                        />
                                    )}
                                     <Pager
                  numberOfPages={numberOfPages}
                  activePage={activePage}
                  goToPage={goToPage}
                />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
           {showStatusForm && <PolicyStatusForm policyId={selectedPolicy} show={showStatusForm} onHide={() => setShowStatusForm(false)} onSuccess={onStatusChangeSuccess} />}
        </Row>
    );
};
export default Policies;
