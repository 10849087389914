import React, { useEffect, useState } from 'react'
import subscriptionService from '../../services/subscription/subscription-service';
import { Form } from 'react-bootstrap';
import providerService from '../../services/provider/provider-service';

function ProviderSelectWidget(props) {
    const [providers, setProviders] = useState([]);

    useEffect(() => {
        loadProviders()
    },[]);
    const loadProviders = () => {
        providerService.getAllProviders().then(
            (payload) => {
           
                setProviders(payload.results);
            },
    );
   }
  return (
    <>
        <Form.Select
                  {...props}
                >
                  <option value="">Choose Provider</option>
                  {providers.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
    </>
  )
}

export default ProviderSelectWidget