import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

function SummaryWidget({primary, secondary, end}) {
  return (
    <div>
         <Row>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                {primary}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                    <Card>
                            <Card.Body>
                                {secondary}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                    <Card>
                            <Card.Body>
                                {end}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> 
    </div>
  )
}

export default SummaryWidget