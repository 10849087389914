import axios from "axios";
import authHeader from "../auth/auth-header";
import { BASE_URL } from "../constants";
const API_URL = BASE_URL + "schemes/";

// For expired token
axios.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 201) {
           
       }
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl);
        }
    }
 );



const getAllImports = () => {
    return axios
        .get(API_URL + "import/", { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};
const getImport = (id) => {
    return axios
        .get(API_URL + `import/${id}`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};



const addImport = (importForm) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    return axios
        .post(API_URL + "import/", importForm)
        .then((response) => {
            return response.data;
        });
};


const updateImport = (member, memberId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .put(API_URL + `import/${memberId}/`, {
            relationship: member.relationship,
            family: member.family,
            posted_on: member.posted_on,
            guid: member.guid,
            // owner: member.owner,
            firstname: member.firstname,
            lastname: member.lastname,
            gender: member.gender,
            birthdate: member.birthdate,
            joined_on: member.joined_on,
        })
        .then((response) => {
            return response.data;
        });
};


const deleteImport = (memberId) => {
    return axios
        .delete(API_URL + `import/${memberId}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};


const activateImport = (itemId) => {
    return axios
        .get(API_URL + `import/${itemId}/start_import`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

export default {
    getAllImports,
    getImport,
    addImport,
    updateImport,
    deleteImport,
    activateImport,
};
