import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ClaimService from '../../services/provider/claims-service';
import { formatHttpErrorMessage,getDayApart } from "../common/helper_functions";
import BadgeWidget from '../widgets/BadgeWidget';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ClaimFilterForm from './ClaimFilterForm';
import Pager from '../common/Pager';

function ClaimsList(props) {
    let memberId = props.memberGuid;
    const [claims, setClaims] = useState([]);
    const [loading, setLoading] = useState(true);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    let entriesPerPage = 50

    useEffect(() => {
        fetchAllClaims();
    },[]);
    const fetchAllClaims = async (params={}) => {
        setLoading(true)
        setClaims([])
        await ClaimService.getAllClaims(params).then(response => {
            setClaims(response.data.results)
            setNumberOfPages(Math.floor(response.data.count / entriesPerPage))
        });
        setLoading(false);
    }

    const goToPage = (event, page) => {
        fetchAllClaims({limit: entriesPerPage, offset:(page-1) * entriesPerPage});
        setActivePage(page)
      };


  return (
    <>
    <Row>
        <Col className='mb-3'>
        <Card>
            <Card.Body>
                <ClaimFilterForm search={(params) =>fetchAllClaims(params) } />
            </Card.Body>
        </Card>
          
        </Col>
    </Row>
    <Row>
        <Col>
            <Card>
                <Card.Body>
                {loading ==false && <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button btn btn-success mb-3"
                            table="table-to-xls"
                            filename="claim_export"
                            sheet="tablexls"
                            buttonText="Export Data to Excel Sheet"
                            /> }
                <Table id="table-to-xls">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ben.</th>
                                <th>Subscheme</th>
                                <th>Scheme Bill</th>
                                <th>Bill Total</th>
                                <th>Visit</th>
                                <th>Ward</th>
                                <th>Diagnosis</th>
                                <th>Status</th>
                                <th>Admission</th>
                                <th>Stay</th>
                                <th>Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {claims && claims.map((item, i) => (
                                <tr key={i+1}>
                                <td>{i + 1+ (entriesPerPage * (activePage - 1))}</td>
                                <td><Link to={`/claims/${item.id}`}>{item.member.firstname + ' ' + item.member.lastname}</Link></td>
                                <td>{item.subscheme}</td>
                                <td>{item.amount}</td>
                                <td>{item.bill_amount}</td>
                                <td>{item.visit}</td>
                                <td>{item.ward}</td>
                                <td>{item.diagnosis}</td>
                                <td><BadgeWidget text={item.status} /></td>
                                <td>{item.admission_date}</td>
                                <td>{getDayApart(item.admission_date, item.discharge_date)}</td>
                                <td>{moment(item.created).format('lll')}</td>
                            </tr>
                            ))}
                            
                        </tbody>
                    </Table>
                    
                    {loading && <center><Spinner animation="border" /></center>}
                    {loading == false && (
                    <Pager
                      numberOfPages={numberOfPages}
                      activePage={activePage}
                      goToPage={goToPage}
                    />
                  )}
                </Card.Body>
            </Card>
        </Col>
    </Row>

    </>
  )
}

export default ClaimsList