import React from 'react'
import { getDayApart } from '../common/helper_functions'
import moment from 'moment'
import { Link } from 'react-router-dom';
import BadgeWidget from './BadgeWidget'
import { useQuery } from '@tanstack/react-query';
import ClaimService from '../../services/provider/claims-service';
import { Spinner, Table } from 'react-bootstrap';

function RecentClaimsList() {
    const { isLoading, isError, error, data:claims, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["recent-claims"],
      queryFn: () => ClaimService.getAllClaims({limit:8}),
      keepPreviousData: true
    });
  return (
    <div>
         <Table id="table-to-xls">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ben.</th>
                                <th>Provider</th>
                                <th>Scheme Bill</th>
                                <th>Visit</th>
                                <th>Status</th>
                                <th>Stay</th>
                                <th>Created</th>
                            </tr>
                        </thead>
                        <tbody>
                        {isLoading ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ):isError ? <>Error Occurred</> :
                            claims && claims.data.results.map((item, i) => (
                                <tr key={i+1}>
                                <td>{i + 1}</td>
                                <td><Link to={`/claims/${item.id}`}>{item.member.firstname + ' ' + item.member.lastname}</Link></td>
                                <td>{item.provider}</td>
                                <td>{item.amount}</td>
                                <td>{item.visit}</td>
                                <td><BadgeWidget text={item.status} /></td>
                                <td>{getDayApart(item.admission_date, item.discharge_date)}</td>
                                <td>{moment(item.created).format('lll')}</td>
                            </tr>
                            ))}
                            
                        </tbody>
                    </Table>
    </div>
  )
}

export default RecentClaimsList