import { useState } from 'react';
import { Alert, ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import useAlert from './useAlert';


const AlertPopup = () => {
  const { text, type } = useAlert();

  if (text) {
    return (
      <ToastContainer className="p-5" position="top-center">
      <Toast autohide bg={type ? type.toLowerCase() : 'info'} delay={6000}>
      <Toast.Header closeButton={false}>
            
              <strong className="me-auto">System Info</strong>
              <small>now</small>
            </Toast.Header>
      <Toast.Body className={type ? 'text-white' : ''}>{text}</Toast.Body>
    </Toast>
    </ToastContainer>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;