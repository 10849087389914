import { useContext, useEffect, useState } from "react";
import {
    Button,
    Spinner,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import userService from "../../services/auth/user-service";
import helperService from "../../services/helpers/helper-service";
import AsyncSelect from 'react-select/async';
import UserEditForm from "./UserEditForm";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
const User = ({ currentUser, match }) => {
    const { userId, edit } = match.params;
    let {setAlert} = useContext(AlertContext);
    const [user, setUser] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [userUsername, setUserUsername] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [validated, setValidated] = useState(false);
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userType, setUserType] = useState("Org");
    const [userPassword, setUserPassword] = useState("");
    const [userPasswordRepeat, setUserPasswordRepeat] = useState("");
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [userIsStaff, setUserIsStaff] = useState(false);
    const [userReference, setUserReference] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [orgRefs, setOrgRefs] = useState([]);
    const [showPasswordForm, setShowPasswordForm] = useState(false);

    useEffect(() => {
        checkForEditInUrl();
        loadUser(userId);
        
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadUser = (id) => {
        userService.getUser(id).then(
            (response) => {
                
                setUserEmail(response.data.email);
                setUserFirstName(response.data.first_name);
                setUserLastName(response.data.last_name);
                setUserIsAdmin(response.data.is_superuser);
                setUserIsStaff(response.data.is_staff);
                setUserUsername(response.data.username);
                setUserType(response.data.type);
                setUserReference(response.data.ref_id)
                setUser(response.data);
                loadOptions(response.data.type, "");
                
            }
        ).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setAlert(msg, "danger")
        });
    };

    const loadOptions = (userType, inputValue) => {
        //get values based on client type chosen
        return helperService.getUserCategoryReferences(userType,inputValue).then(
            (response) => {
                setOrgRefs(response.data.results);
            },
            (error) => {

            }
        )
    }; 

    const handleInputChange = (newValue) => {
        const inputValue = newValue;//newValue.replace(/\W/g, '');
        setSearchInput(inputValue);        
        return inputValue;
    }; 
    

      const handleReferenceChange = (newValue) => {
          if(newValue !== null){
            setUserReference(newValue.id)   
          }     
       
      };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                username: userUsername,
                email: userEmail,
                first_name: userFirstName,
                last_name: userLastName,
                is_staff: userIsStaff,
                is_superuser: userIsAdmin,
                password: userPassword,
                password1: userPasswordRepeat,
                type: userType,
                ref_id: userReference,
            };
            userService.updateUser(payload, currentUser.id).then(
                (result) => {
                    setToastVariant("light");

                    loadUser(currentUser.id);
                    setShowToast(true);
                    setToastMessage("Successfully updated offer");
                    toggleEdit();
                    setValidated(false);
                },
                
            ).catch(error => {
                let msg = formatHttpErrorMessage(error)
                setAlert(msg, "danger")
            });
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            setEdit(true);
        }
    };

    const onPasswordChange = () => {
        setShowPasswordForm(false);
    }

    const handleOnDeactivate = (id) => {
        if(!window.confirm("Are you sure you want to disable this user?")){
            return;
        }
        userService.deactivateUserById(id).then(response=>{
            setAlert("User has been disabled successfully ")
            loadUser(id);
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setAlert(msg, "danger")
        })
    }

    const handleOnActivate = (id) => {
        if(!window.confirm("Are you sure you want to activate this user?")){
            return;
        }
        userService.activateUserById(id).then(response=>{
            setAlert("User has been enabled successfully ")
            loadUser(id);
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setAlert(msg, "danger")
        })
    }

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/users"> Users </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        {user ? user.username : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex">

                            <Col md={8}>
                                <h4 className="m-0">
                                    User:{" "}
                                    <strong className="text-capitalize">
                                        {user ? user.username : ""}
                                    </strong>
                                </h4>
                            </Col>
                            <Col md={5} className="text-right">
                            <Button variant="success" onClick={() => setShowPasswordForm(true)}>Change Password</Button> &nbsp;&nbsp;
                            <UserEditForm show={showPasswordForm} userId={user ? user.id : ""} onHide={() => setShowPasswordForm(false)} onSuccess={onPasswordChange} />
                            {user && <>
                                <Button variant="danger" onClick={() => handleOnDeactivate(userId)} disabled={user.is_active==false}>Disabled</Button> &nbsp;
                                <Button variant="info" onClick={() => handleOnActivate(userId)} disabled={user.is_active==true}>Activate</Button> &nbsp;&nbsp;
                            </>}
                        <Button
                        
                            variant="primary"
                            onClick={toggleEdit}
                            size="md"
                        >
                            Edit
                        </Button>
                            </Col>
                        
                        
                        
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" className="offset-md-3">
                <Card>
                    <Card.Body>
                        {!user && (
                            <Spinner animation="border" variant="warning" />
                        )}
                        {user && (
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                className="row g-3"
                            >
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={true}
                                        type="text"
                                        value={userUsername}
                                        onChange={(e) =>
                                            setUserUsername(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Firstname</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={userFirstName}
                                        onChange={(e) =>
                                            setUserFirstName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Lastname</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={userLastName}
                                        onChange={(e) =>
                                            setUserLastName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="email"
                                        value={userEmail}
                                        onChange={(e) =>
                                            setUserEmail(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    className="col-12"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>User Type</Form.Label>
                                    <Form.Select
                                        aria-label="select user type"
                                        disabled={true}
                                        value={userType}
                                        onChange={(e) =>
                                            setUserType(e.target.value)
                                        }
                                        required
                                    >
                                        <option value="Admin">Admin</option>
                                        <option value="Group">Group</option>
                                        <option value="Provider">
                                            Provider
                                        </option>
                                        <option value="Org">
                                            Organisation
                                        </option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid user type.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-12"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Reference</Form.Label>
                                
                                    <Form.Select
                                        aria-label="select Sub county"
                                        disabled={true}
                                        value={userReference}
                                        onChange={(e) =>
                                            setUserReference(e.target.value)
                                        }
                                        required
                                    >
                                        <option></option>
                                        {orgRefs.map((item, i) => (<option value={item.id} key={i}>{item.name}</option>))}
                                        
                                    </Form.Select>

                                </Form.Group>

                                {isEdit && (
                                    <div className="col-12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default User;
