import { useContext, useEffect, useState } from "react";
import {
    Grid,
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
    Pagination,
    Stack
} from "react-bootstrap";
import helperService from "../../services/helpers/helper-service";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { PencilSquare, Trash, EyeFill, Plus, PlugFill, Plug} from "react-bootstrap-icons";
import schemeService from "../../services/scheme/scheme-service";
import authService from "../../services/auth/auth-service";
import AsyncSelect from 'react-select/async';
import BadgeWidget from "../widgets/BadgeWidget";
import AuthDetailContext from "../common/AuthDetailContext";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
import Pager from "../common/Pager";

const SubSchemes = () => {
    const currentUser = useContext(AuthDetailContext);
    let {setAlert } = useContext(AlertContext);
    const [schemes, setSchemes] = useState([]);
    const [parentSchemes, setParentSchemes] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [schemeName, setSchemeName] = useState("");
    const [schemeParent, setSchemeParent] = useState("");
    const [schemeDistrict, setSchemeDistrict] = useState("");
    const [schemeSubcounty, setSchemeSubcounty] = useState("");
    const [schemeCounty, setSchemeCounty] = useState("");
    const [schemeParish, setSchemeParish] = useState("");
    const [schemeVillage, setSchemeVillage] = useState("");
    const [schemeTargetPopulation, setSchemeTargetPopulation] = useState(0);
    const [schemeYearFormed, setSchemeYearFormed] = useState("");
    const [validated, setValidated] = useState(false);
    const [schemeShortName, setSchemeShortName] = useState("");
    const [schemeBody, setSchemeBody] = useState("");
    const [districts, setDistricts] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [counties, setCounties] = useState([]);
    const [searchDistrictInput,setSearchInput] = useState("");
    const [loading, setLoading] = useState(true);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage,setEntriesPerPage] = useState(50);
    const [loggedInUser, setLoggedInUser] =useState({});
    const history = useHistory();
    const [qName, setQName] = useState("");
    const [billType, setBillType] = useState();

    useEffect(() => {
        setLoggedInUser(authService.getCurrentUser());
        loadSchemes(activePage);
        loadParentSchemes();
        loadDistricts();
        loadSubcounties();
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const loadSchemes = (nextPage=1,limit=50) => {
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
        setLoading(true);
        schemeService.getAllSubSchemes(offset,limit).then(
            (result) => {
                setSchemes(result.results);
                const pages = (result.count > 0) ? Math.ceil(result.count/entriesPerPage) : 0
                setNumberOfPages(pages);               
                setActivePage(nextPage);
                setLoading(false);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);

                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };

    const loadParentSchemes = () => {
        schemeService.getAllSchemes().then(
            (result) => {
                setParentSchemes(result.results);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };

    const loadDistricts = () => {
        helperService.getAllDistricts().then(
            (districts) => {
                setDistricts(districts);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };
    const loadSubcounties = () => {
        helperService.getAllSubcounties({limit:2000}).then(
            (subCounties) => {
                setSubcounties(subCounties);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };
    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            schemeService.deleteSubScheme(id).then(
                () => {
                   
                    setAlert("Successfully Deleted sub scheme");
                    loadSchemes();
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage, "danger");
                    setLoading(false);
                }
            );
        } else {
        }
    };

    const goToPage = (event,page) => {
        loadSchemes(page);
    }

    const filterDistricts = (inputValue) => {
        return districts.filter(district =>
            district.name.includes(String(inputValue).toUpperCase())
          )
      };

    
      const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
          callback(filterDistricts(inputValue));
        }, 100);
    }; 

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setSearchInput(inputValue);        
        return inputValue;
      };  

      const handleDistrictChange = (newValue) => {
        console.log(newValue);
        if(newValue !== null){
            setSchemeDistrict(newValue.name);
            //get subcounties by name
            helperService.getCountyByDistrictId(newValue.id).then(
                (counties) => {
                    setCounties(counties)
                },
                (error) => {

                }
            )
        }
        
       
      }; 
      
      const handleCountyChange = (event) => {
        console.log(event.target.options[event.target.selectedIndex].text)
        setSchemeCounty(event.target.options[event.target.selectedIndex].text)
        //get subcounties by name
        helperService.getSubcountyByCountyId(event.target.value).then(
            (subcounties) => {
                setSubcounties(subcounties)
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
            }
        )
        
      }; 

      function getFormValues(event) {
        const data = new FormData(event.currentTarget);
        return Object.fromEntries(data.entries());
      }

      const handleSearch = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        setLoading(true);
        setSchemes([]);
        event.preventDefault();
        var nextPage=1;
        var limit=50;
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
        var q = `name=${qName}`;
        const params = getFormValues(event);
        
        schemeService.searchSubSchemes(params).then(
            (result) => {
                setSchemes(result.results);
                const pages = (result.count > 0) ? Math.ceil(result.count/entriesPerPage) : 0
                setNumberOfPages(pages);               
                setActivePage(nextPage);
                setLoading(false);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
        
      }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let scheme = {
                name: schemeName,
                shortname: schemeShortName,
                scheme: schemeParent,
                year_formed: schemeYearFormed,
                target_population: schemeTargetPopulation,
                district: schemeDistrict,
                subcounty: schemeSubcounty,
                parish: schemeParish,
                village: schemeVillage,
                bill_type:billType
            };
            schemeService.addSubScheme(scheme).then(
                (result) => {
                    
                    setAlert("Successfully created scheme");
                    setSchemeName("");
                    setSchemeShortName("");
                    setSchemeParent("");
                    setSchemeYearFormed("");
                    setSchemeTargetPopulation("");
                    setSchemeDistrict("");
                    setSchemeSubcounty("");
                    setSchemeParish("");
                    setSchemeVillage("");
                    setBillType("");
                    setShowForm(false);
                    loadSchemes();
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                    setLoading(false);
                }
            );
        }
    };

    const handleSuspend = (id, name) => {
        if(window.confirm("Are you sure you want to Suspend subscheme '" + name + "'")){
            
            schemeService.suspendSubScheme(id).then(response =>{
                setAlert("Subscheme '" + name + "' has been suspended Successfully!")
                loadSchemes(activePage);
            }).catch(error => {
                let msg = formatHttpErrorMessage(error);
                setAlert(msg, "danger")
            })
        }
        return;
    }

    const handleActivate = (id, name) => {
        if(window.confirm("Are you sure you want to activate subscheme '" + name + "'")){
            schemeService.activateSubScheme(id).then(response =>{
                setAlert("Subscheme '" + name + "' has been activated Successfully!")
                loadSchemes(activePage);
            }).catch(error => {
                let msg = formatHttpErrorMessage(error);
                setAlert(msg, "danger")
            })
        }
        return;
    }

    return (
      <Row className="g-3">
        <Col xs="12" className="pt-3">
          <h4 className="m-0">Sub Schemes</h4>
          <Breadcrumb className="border-bottom mb-3">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/schemes"> Schemes </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Sub Schemes</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs="12">
          <Card>
            <Card.Body className="bg-white d-flex justify-content-between align-content-center">
              <Form noValidate validated={validated} onSubmit={handleSearch}>
                <Row>
                  <Col>
                    <Form.Control type="text" placeholder="name" name="name" />
                  </Col>

                  <Col md="2">
                    <Form.Select aria-label="select Scheme" name="scheme">
                      <option value="">Choose Scheme</option>
                      {parentSchemes.map((parent) => (
                        <option key={parent.id} value={parent.id}>
                          {parent.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md="2">
                    <Form.Select
                      aria-label="select District"
                      name="district"
                      required
                    >
                      <option value="">Choose District</option>
                      {districts.map((district) => (
                        <option key={district.id} value={district.name}>
                          {district.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md="2">
                    <Form.Select aria-label="select Subcounty" name="subcounty">
                      <option value="">Choose Subcounty</option>
                      {subcounties.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md="2">
                    <Form.Select aria-label="select County" name="status">
                      <option value="">Choose Status</option>
                      <option value="Suspended">Suspended</option>
                      <option value="Dropped">Dropped</option>
                      <option value="Active"> Active </option>
                    </Form.Select>
                  </Col>
                  <Col xs="1" md="1">
                    <Button variant="primary" type="submit" size="md">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        {showForm && (
          <Col xs="12">
            <Card>
              <Card.Body>
                <Card.Subtitle className="mb-3">
                  Add new Sub Scheme{" "}
                </Card.Subtitle>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="gx-3 gy-3">
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className=""
                      >
                        <Form.Control
                          type="text"
                          placeholder="schemeName"
                          value={schemeName}
                          onChange={(e) => setSchemeName(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid name for a scheme.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Short Name"
                        className=""
                      >
                        <Form.Control
                          type="text"
                          placeholder="schemeName"
                          value={schemeShortName}
                          onChange={(e) => setSchemeShortName(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid short name for a scheme.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Select Parent Scheme"
                      >
                        <Form.Select
                          aria-label="select Parent Scheme"
                          value={schemeParent}
                          onChange={(e) => setSchemeParent(e.target.value)}
                          required
                        >
                          <option value="">None</option>
                          {parentSchemes.map((parent) => (
                            <option key={parent.id} value={parent.id}>
                              {parent.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please select parent scheme.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Year Formed"
                        className=""
                      >
                        <Form.Control
                          type="number"
                          placeholder="2020"
                          value={schemeYearFormed}
                          onChange={(e) => setSchemeYearFormed(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Year.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Target Population"
                        className=""
                      >
                        <Form.Control
                          type="number"
                          min={0}
                          value={schemeTargetPopulation}
                          onChange={(e) =>
                            setSchemeTargetPopulation(e.target.value)
                          }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid number
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <AsyncSelect
                        cacheOptions
                        isClearable
                        placeholder="select district"
                        loadOptions={loadOptions}
                        name="district"
                        defaultOptions={districts}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option["id"]}`}
                        onInputChange={handleInputChange}
                        onChange={handleDistrictChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please select district.
                      </Form.Control.Feedback>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Select County"
                      >
                        <Form.Select
                          aria-label="select County"
                          onChange={(e) => handleCountyChange(e)}
                          required
                        >
                          <option value="">None</option>
                          {counties.map((county) => (
                            <option key={county.id} value={county.id}>
                              {county.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please select subcunty.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Select Subcounty"
                      >
                        <Form.Select
                          aria-label="select Subcounty"
                          value={schemeSubcounty}
                          onChange={(e) => setSchemeSubcounty(e.target.value)}
                          required
                        >
                          <option value="">None</option>
                          {subcounties.map((subcounty) => (
                            <option key={subcounty.id} value={subcounty.name}>
                              {subcounty.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please select subcunty.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Parish"
                        className=""
                      >
                        <Form.Control
                          type="text"
                          placeholder="parish"
                          value={schemeParish}
                          onChange={(e) => setSchemeParish(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid parish name.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Village"
                        className=""
                      >
                        <Form.Control
                          type="text"
                          placeholder="village"
                          value={schemeVillage}
                          onChange={(e) => setSchemeVillage(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid village name.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Billing Type"
                        className=""
                      >
                        <Form.Select
                          aria-label="select Billing type"
                          value={billType}
                          onChange={(e) => setBillType(e.target.value)}
                          required
                        >
                          <option>---Select---</option>
                          <option value="min_cover">Minimum cover</option>
                          <option value="copayment">Copayment</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please select Billing Type.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col md="3">
                      <Button variant="primary" type="submit" size="md">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col xs="12">
          <Card>
            <Card.Body>
              {currentUser && currentUser["is_superuser"] == true && (
                <Button
                  className="float-end"
                  variant="success"
                  onClick={toggleForm}
                  size="md"
                >
                  Create New
                </Button>
              )}
            </Card.Body>
            <Card.Body>
              {loading == true && (
                <center>
                  {" "}
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </center>
              )}
              {loading == false && (
                <ReactHtmlTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn btn-success mb-3"
                  table="table-to-xls"
                  filename="subscheme_export"
                  sheet="tablexls"
                  buttonText="Export Data to Excel Sheet"
                />
              )}
              <div className="table-responsive">
                <Table striped hover id="table-to-xls">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>SSIN</th>
                      <th>Year</th>
                      <th>T.Pop</th>
                      <th>Families</th>
                      <th>Beneficiaries</th>
                      <th>District</th>
                      <th>Parish</th>
                      <th>Status</th>
                      <th>P.Rate</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {schemes.map((scheme, index) => (
                      <tr>
                        <td>{index + 1 + 50 * (activePage - 1)}</td>
                        <td>
                          <Link to={`/sub-schemes/${scheme.id}`}>
                            {scheme.name}
                          </Link>
                        </td>
                        <td>{scheme.shortname}</td>
                        <td>{scheme.year_formed}</td>
                        <td>{scheme.target_population}</td>
                        <td>{scheme.family_count}</td>
                        <td>{scheme.member_count}</td>
                        <td>{scheme.district}</td>
                        <td>{scheme.parish}</td>
                        <td>
                          <BadgeWidget text={scheme.status} />
                        </td>
                        <td>
                          {scheme.member_count &&
                            (
                              (scheme.member_count / scheme.target_population) *
                              100
                            ).toFixed(4)}
                        </td>
                        <td>
                          {currentUser && currentUser["is_superuser"] && (
                            <>
                              <Stack direction="horizontal" gap={1}>
                                {scheme.status == 'Active' && <Button variant="link" onClick={() => handleSuspend(scheme.id, scheme.name)}>
                                  <Plug color="red" />
                                </Button>}
                                {(scheme.status == 'Suspended' || scheme.status == 'Dropped') && <Button variant="link" onClick={() => handleActivate(scheme.id, scheme.name)}>
                                  <PlugFill color="green" />
                                </Button>}
                                &nbsp;&nbsp;
                                <Link to={`/sub-schemes/${scheme.id}/edit`}>
                                  <PencilSquare />
                                </Link>{" "}
                                &nbsp;&nbsp;
                                <Button variant="link"
                                  onClick={(e) => {
                                    handleDelete(e, scheme.id);
                                  }}
                                >
                                  <Trash />
                                </Button>
                              </Stack>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div>
                <Pager
                  numberOfPages={numberOfPages}
                  activePage={activePage}
                  goToPage={goToPage}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
};
export default SubSchemes;
