import { useContext, useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, FloatingLabel,Spinner, Alert } from 'react-bootstrap';
import schemeService from "../../services/scheme/scheme-service";
import { formatHttpErrorMessage, humanFileSize } from "../common/helper_functions";
import AlertContext from "../widgets/alertPopup/AlertContext";

export default function MemberForm(props) {
    const family = props.family;
    const [validated, setValidated] = useState(false);
    const [families, setFamilies] = useState([]);
    const [memberFamily, setMemberFamily] = useState("");
	const [memberJoinedOn, setMemberJoinedOn] = useState("");
	const [memberFirstName, setMemberFirstName] = useState("");
	const [memberLastName, setMemberLastName] = useState("");
	const [memberOtherName, setMemberOtherName] = useState("");
	const [memberGender, setMemberGender] = useState("Male");
	const [memberDob, setMemberDob] = useState("");
	const [memberNin, setMemberNin] = useState(undefined);
	const [memberPhone, setMemberPhone] = useState(undefined);
	const [memberPhoto, setMemberPhoto] = useState(undefined);
	const [memberRelationship, setMemberRelationship] = useState("Head");
    const [showForm, setShowForm] = useState(true);
    const [validatedMember, setValidatedMember] = useState(false);
    const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [photoSize, setPhotoSize] = useState();
	let {setAlert} = useContext(AlertContext)
	

    const generateUniqueId = () => {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);
	};



	const handleFileSelect = (event) => {
      var size = event.target.files[0] ? event.target.files[0].size:0;
	  setPhotoSize(size)
      if(size > 1000000){
        setAlert("Uploaded file is too big!", "danger")
        setMessage("File size is greater than recommended limit of 1MB");
        return;
      }
		setMemberPhoto(event.target.files[0]);
        
	}

    const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		
		const MAX_FILE_SIZE = 2048 * 1024 // 5MB
        if(photoSize > MAX_FILE_SIZE){
            setMessage("File size is greater than maximum limit of 2MB");
            setLoading(false);
            return
        }

        setLoading(true);
		const member = new FormData();
		member.append("relationship", memberRelationship);
		member.append("family", family.guid);
		member.append("posted_on", memberJoinedOn);
		member.append("guid", generateUniqueId());
		member.append("firstname", memberFirstName);
		member.append("lastname", memberLastName);
        member.append("othername", memberOtherName);
		member.append("gender", memberGender);
		member.append("birthdate", memberDob);
		member.append("joined_on", memberJoinedOn);
		member.append("nin", memberNin);
		member.append("phone", memberPhone);
		member.append("photo", memberPhoto);
			
		schemeService.addMember(member).then(
				(result) => {
					setMemberLastName("");
					setMemberFirstName("");
					setMemberJoinedOn("");
					setMemberNin(undefined);
					setMemberPhone(undefined);
					setMemberPhoto(undefined);
					setShowForm(false);
					setValidatedMember(false);
                    props.onClose();
				}				
			).catch((error) => {
				const resMessage = formatHttpErrorMessage(error);
				setMessage(resMessage);
				
			});
	
		setLoading(false);
	};


    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
         
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
              Member Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please fill in the form below to create a Member entry</p>
			{message && <Alert variant="danger">{message}</Alert>}
            {loading ==true && (
                                        <center><Spinner
                                            animation="border"
                                            variant="warning"
                                        /></center>
                                    )}
            			
			<Form
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
							>
								<Row className="g-3">

									<Col md="4">
										<FloatingLabel
											controlId="floatingInput"
											label="First Name"
											className=""
										>
											<Form.Control
												type="text"
												placeholder="schemeName"
												value={memberFirstName}
												onChange={(e) =>
													setMemberFirstName(
														e.target.value
													)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid first
												name.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md="4">
										<FloatingLabel
											controlId="floatingInput"
											label="Surname"
											className=""
										>
											<Form.Control
												type="text"
												placeholder="Surname"
												value={memberLastName}
												onChange={(e) =>
													setMemberLastName(
														e.target.value
													)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid last
												name.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md="4">
										<FloatingLabel
											controlId="floatingInput"
											label="Other Name"
											className=""
										>
											<Form.Control
												type="text"
												placeholder="Other Name"
												value={memberOtherName}
												onChange={(e) =>
													setMemberOtherName(
														e.target.value
													)
												}
												
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid last
												name.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
                                    <Col md="6">
										<FloatingLabel
											controlId="floatingInput"
											label="Date Joined"
											className=""
										>
											<Form.Control
												type="date"
												value={memberJoinedOn}
												onChange={(e) =>
													setMemberJoinedOn(
														e.target.value
													)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid date.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md="6">
										<FloatingLabel
											controlId="floatingInput"
											label="Date of Birth"
											className=""
										>
											<Form.Control
												type="date"
												value={memberDob}
												onChange={(e) =>
													setMemberDob(e.target.value)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid date.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md="6">
										<FloatingLabel
											controlId="floatingInput"
											label="Gender"
										>
											<Form.Select
												aria-label="select Gender"
												value={memberGender}
												onChange={(e) =>
													setMemberGender(
														e.target.value
													)
												}
												required
											>
												<option value="Male">
													Male
												</option>
												<option value="Female">
													Female
												</option>
											</Form.Select>
										</FloatingLabel>
									</Col>
									<Col md="6">
										<FloatingLabel
											controlId="floatingInput"
											label="Relationship"
										>
											<Form.Select
												aria-label="select Sub Scheme"
												value={memberRelationship}
												onChange={(e) =>
													setMemberRelationship(
														e.target.value
													)
												}
												required
											>
												<option value="Head">
													Head
												</option>
												<option value="Spouse">
													Spouse
												</option>
												<option value="Child">
													Child
												</option>
												<option value="Dependant">
													Dependant
												</option>
											</Form.Select>
										</FloatingLabel>
									</Col>
									<Col md="6">
										<FloatingLabel
											controlId="floatingInput"
											label="Phone Number"
											className=""
										>
											<Form.Control
												type="number"
												value={memberPhone}
												onChange={(e) =>
													setMemberPhone(
														e.target.value
													)
												}
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid phone.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md="6">
										<FloatingLabel
											controlId="floatingInput"
											label="NIN"
											className=""
										>
											<Form.Control
												type="text"
												value={memberNin}
												onChange={(e) =>
													setMemberNin(e.target.value)
												}
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid NIN.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md="6">
									{photoSize && <>Size: {humanFileSize(photoSize)}</>}
                                    <FloatingLabel
																	controlId="floatingInput"
																	label="Photo"
																	className=""
																>
																	<Form.Control
																		type="file"
																		accept=".jpg,.png,.jpeg"
																		onChange={
																			handleFileSelect
																		}
																		required
																	/>

																	<Form.Control.Feedback type="invalid">
																		Please
																		provide
																		a valid
																		photo.
																	</Form.Control.Feedback>
																</FloatingLabel>
									</Col>

									<Col xs="12" className="d-grid gap-2">
										<Button
											variant="primary"
											type="submit"
											size="md"
                                            disabled={loading}
										>
											Save {loading ? "...." : ''}
										</Button>
									</Col>
								</Row>
							</Form>
					
          </Modal.Body>
        </Modal>
      )
}
