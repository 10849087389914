import React, { useEffect, useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { createInvoiceAdmission, createInvoiceEntry } from "../../services/provider/invoice-service";
import schemeService from "../../services/scheme/scheme-service";
import ClaimService from '../../services/provider/claims-service';
import { formatHttpErrorMessage } from "../common/helper_functions";
import ProviderSelectWidget from "./ProviderSelectWidget";
import SchemeSelectField from "../widgets/SchemeSelectField";

function InvoiceAdmissionsForm(props) {
    const [validated, setValidated] = useState(false);
    const [discountType, setDiscountType] = useState();
    const [invMonth, setInvMonth] = useState();
    const [schemes, setSchemes] = useState([]);
    const [message, setMessage] = useState();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        "start_date": null,
        "end_date": null,
        "provider": null,
        "scheme": null,
        "invoice_num": "",
        "invoice_date": null,
        "discount_type": "Fixed",
        "discount": null
    })

    const monthsOfYear = ["January","Febuary","March","April", "May","June","July","August","September","Octomber","November","December"]

    useEffect(() => {
        fetchSchemes();
        
    },[])

    const fetchSchemes = (params={}) => {
        setLoading(true);
        ClaimService.getMonthlyClaimsByScheme(params).then(resp => {
            setSchemes(resp.data.detail);
        });
        setLoading(false);
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

    const handleDateChange = (month, year) =>{
        console.log(invMonth)
        fetchSchemes({
            year:year,
            month:month
        }) 
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await createInvoiceAdmission(formData).then(resp => {
            history.push(`/invoices/${resp.data.id}`);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            let msg = formatHttpErrorMessage(err)
            setMessage(msg)
            setLoading(false);
        });
    }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Invoice Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            {loading && <center><Spinner animation="border" /></center>}
          <Form validated={validated} onSubmit={handleSubmit}>
            {message && <Alert variant="danger">{message}</Alert>}
            <Row className="gx-3 gy-3 mb-1">
               
                
            </Row>
            <Row className="gx-3 gy-3"> 
            <Col md={12}>
                <FloatingLabel
                                            controlId="floatingInput"
                                            label="Admission Start Date"
                                            className=""
                                        >
                                <ProviderSelectWidget name="provider" value={formData.provider} onChange={handleChange} />            
                                        </FloatingLabel>
                
                </Col>
                                <Col md="6">
                                <FloatingLabel
                                            controlId="floatingInput"
                                            label="Discharge Start Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="start_date"
                                                placeholder="Invoice Date"
                                                value={formData.start_date}
                                                onChange={handleChange}
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
               
                                    <Col md="6">
                                    <FloatingLabel
                                            controlId="floatingInput"
                                            label="Discharge End Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="end_date"
                                                placeholder="Invoice Date"
                                                value={formData.end_date}
                                                onChange={handleChange}
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
            <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Scheme"
                                        >
                                            <SchemeSelectField name="scheme"
                                                value={formData.scheme}
                                                onChange={handleChange}
                                                required/>
                                                                                   
                                        </FloatingLabel>
                                    </Col>

            <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Invoice Number"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                name="invoice_num"
                                                placeholder="Invoice Number"
                                                value={formData.invoice_num}
                                                onChange={handleChange}
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
                          <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Invoice Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="invoice_date"
                                                placeholder="Invoice Date"
                                                value={formData.invoice_date}
                                                onChange={handleChange}
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    

                                    

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Discount Type"
                                        >
                                            <Form.Select
                                                aria-label="select Discount Type"
                                                name="discount_type"
                                                value={formData.discount_type}
                                                onChange={handleChange}
                                                required
                                            >
                                                
                                                <option value="Fixed">No Discount</option>
                                                <option value="Rate">Percent</option>
                                            </Form.Select>
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Discount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                name="discount"
                                                placeholder="Discount"
                                                value={formData.discount}
                                                onChange={handleChange}
                                                readOnly={discountType =='Fixed'}
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Invoice Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Invoice Amount"
                                                value={formData.amount}
                                                onChange={handleChange}
                                            readOnly
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>


                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                            disabled={loading}
                                        >
                                            Continue
                                        </Button>
                                    </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InvoiceAdmissionsForm;
