import axios from "axios";
import authHeader from "../auth/auth-header";
import axiosAPI from "../axiosApi";
import { BASE_URL } from "../constants";
const API_URL = BASE_URL + "subscriptions/";

// For expired token
axios.interceptors.response.use(
  (res) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return res;
  },
  (error) => {
    console.log(error);
    const resMessage =
      (error.response && error.response.data && error.response.data.detail) ||
      error.message ||
      error.toString();
    if (resMessage.includes("token not valid")) {
      console.log("token expired ooo");
      localStorage.removeItem("basicauth");
      localStorage.removeItem("user");
      const currentUrl = window.location.href;
      const urlBits = currentUrl.split("/");
      const loginUrl = urlBits[0] + "//" + urlBits[2] + "/login";
      window.location.replace(loginUrl);
      return Promise.reject(error);
    }
    
  }
);

const getAllOffers = (params) => {
    return axios
        .get(API_URL + `offers/`, { headers: authHeader(), params:params })
        .then((response) => {
            return response.data.results;
        });
};

const getAllPayments = (params) => {
    return axios
        .get(
            API_URL +
                `payments/`,
            {params:params, headers: authHeader() }
        );
};

const filterOffers = (params) => {
    return axios
        .get(
            API_URL + `offers/`,
            { params:params, headers: authHeader() }
        );
};

const familyProviders = (
    offerGuid = "",
    providerId = "",
    created = ""
) => {
    return axios
        .get(
            API_URL +
                `providers/?offer=${offerGuid}&provider_id=${providerId}&created=${created}`,
            { headers: authHeader() }
        )
        .then((response) => {
            return response.data.results;
        });
};

const getFamilyProviders = (params) => {
    return axios.get(
            API_URL +
                `providers/`,
            { params:params, headers: authHeader() }
        )
};

const getAllPolicies = (params) => {
    return axios
        .get(
            API_URL + `policy/`,    { headers: authHeader(), params:params }
        );
};

const changePolicyStatus = (id, params) => {
    return axios
        .post(
            API_URL + `policy/${id}/change_status/`, params,  { headers: authHeader() }
        );
};

const getPolicySummary = (params) => {
    return axios
        .get(API_URL + `policy/summary/`, {params:params, headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getOffer = (offerId) => {
    return axios
        .get(API_URL + `offers/${offerId}`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getPaymentSummary = (params={}) => {
    return axios
        .get(API_URL + `payments/summary/`, {params, headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const activateOffer = (offerId) => {
    return axiosAPI.get(API_URL + `offers/${offerId}/activate`, { headers: authHeader() })
    
};

const bulkActivateOffer = (params) => {
    return axios.post(API_URL + `offers/bulk_activate/`, { params:params, headers: authHeader() })
}

const calculateOffer = (offerId) => {
    return axios
        .get(API_URL + `offers/${offerId}/calculate/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const addOffer = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axiosAPI.post(API_URL + "offers/", payload);
};

const attachProviderToFamily = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "providers/", payload)
        .then((response) => {
            return response.data;
        });
};
const detachProvider = (id) => {
    return axios
        .delete(API_URL + `providers/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};
const addPayment = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "payments/", payload)
        .then((response) => {
            return response.data;
        });
};

const deletePayment = (id) => {
    return axiosAPI.delete(API_URL + `payments/${id}/`)
};

const updateOffer = (payload, offerId) => {
        return axios
        .put(API_URL + `offers/${offerId}/`, payload, { headers: authHeader() })
        .then((response) => {
            console.log(response)
            return response;
        });
};

const deleteOffer = (id) => {
    return axiosAPI.delete(API_URL + `offers/${id}/`)
};


const getAllProviderSubscriptions = async (params) => {
    return await axios.get(API_URL + 'providers/', {params, headers: authHeader() });
}

const retrieveMemberRules = (facility, member) => {
    let payload = {
        member,
        facility
    }
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axiosAPI
        .post(API_URL + "policy/rules/", payload);
}

const regenerateFamilyCard = async (id) =>{
    return await axios.get(API_URL + 'offers/regenerate_qr/', {params:{family:id}, headers: authHeader() });
}


const getFilteredPaymentSummary = (params) => {
    return axios
        .get(API_URL + `payments/summary/`, {params:params, headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}

const getFilteredOfferSummary = (params) => {
    return axios
        .get(API_URL + `offers/summary/`, {params:params, headers: authHeader() })
        .then((response) => {
            return response.data;
        });
}

const validateMemberPolicy = (guid) => {
    
    return axiosAPI.post(API_URL + `policy/validate_member/`, {member:guid},{headers: authHeader() })
}




export default {
    getAllOffers,
    getAllPolicies,
    getPolicySummary,
    changePolicyStatus,
    getAllPayments,
    getOffer,
    filterOffers,
    addOffer,
    addPayment,
    deletePayment,
    getPaymentSummary,
    updateOffer,
    deleteOffer,
    activateOffer,
    bulkActivateOffer,
    calculateOffer,
    familyProviders,
    getFamilyProviders,
    attachProviderToFamily,
    detachProvider,
    retrieveMemberRules,
    regenerateFamilyCard,
    getAllProviderSubscriptions,
    getFilteredPaymentSummary,
    getFilteredOfferSummary,
    validateMemberPolicy,
};
