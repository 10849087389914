import React, { useState } from 'react'
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { createInvoicePayment } from '../../services/provider/payment-service';

function InvoicePaymentForm(props) {
    let invoiceId = props.invoiceId;
    let history = useHistory();
    const [payment, setPayment] = useState({
      "amount": props.amount,
      "payment_type": "Cash",
      "notes": "",
      "post_date": null,
      "invoice": invoiceId
  })
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);

    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      await createInvoicePayment(payment).then(resp => {
          props.onSuccess();
      }).catch(err => {
          console.log(err);
      });
      setLoading(false);
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        bg="primary"
     
    >
      <Modal.Header closeButton bg="primary">
        <Modal.Title id="contained-modal-title-vcenter" >
          Payment Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <center><Spinner animation='border' /></center>}
      <Form validated={validated} onSubmit={handleSubmit}>
            <Row className="gx-3 gy-3">
            <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Invoice Number"
                                                value={payment.amount}
                                                onChange={(e) => setPayment({...payment, amount: e.target.value})}
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
            <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Payment type"
                                        >
                                            <Form.Select
                                                aria-label="select Payment Type"
                                                value={payment.payment_type}
                                                onChange={(e) => setPayment({...payment, payment_type: e.target.value})}
                                                required
                                            >
                                                <option value="Cash">Cash</option>
                                                <option value="Cheque">Cheque</option>
                                                <option value="Bank">Bank</option>
                                                
                                            </Form.Select>
                                           
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Post Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                placeholder="Payment Date"
                                                value={payment.post_date}
                                                onChange={(e) => setPayment({...payment, post_date: e.target.value})}
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Notes"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Notes"
                                                value={payment.notes}
                                                onChange={(e) => setPayment({...payment, notes: e.target.value})}
                                                row={5}
                                                required
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                            disabled={parseInt(payment.amount) <= 0  || loading}
                                        >
                                            Continue
                                        </Button>
                                    </Col>
                </Row>
        </Form>
      </Modal.Body>
      </Modal>
    </>
  )
}

export default InvoicePaymentForm