import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form, FloatingLabel, Spinner, Alert } from 'react-bootstrap';
import claimsService from '../../services/provider/claims-service';
import subscriptionService from '../../services/subscription/subscription-service'

function ClaimEditForm(props) {
    const policy = props.claim;
    const facility = props.facility;
    const [ceiling, setCeiling] = useState(policy?.ceiling_opd)
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [message, setMessage] = useState();
    const [policyRule, setPolicyRule] = useState({"min_cover":0, "min_cover_ipd":0,"ceiling_opd":0,"ceiling_ipd":0,"product":{}});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
            "visit": "",
            "ward": "",
            "diagnosis": "",
            "patient_state": "",
            "is_referral": "",
            "referral_ref": "",
            "admission_date": "",
            "discharge_date": "",
            "amount": "",
            "bill_amount": "",
            "copay": "",
            "amount_paid": "",
            "penalty": "0.00"
    })
    
    useEffect(() => {
        setLoading(false);
        getPolicyRules();
        setFormData(props.claim)
        
    }, [props.claim]);

const getPolicyRules = () => {
    subscriptionService.retrieveMemberRules(props.claim.facility, props.member.id).then((response) => {
        setPolicyRule(response)
        setCeiling(response.ceiling_opd);
        setShowForm(true);
    }).catch(error => {
        console.log(error);
    });
}


const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
};

const handleSubmit = (event) => {
    const form = event.currentTarget;
    let invalid = false;
    
    event.preventDefault();
    if (form.checkValidity() === false) {
        //event.preventDefault();
        event.stopPropagation();
        invalid = true;
    }

    setValidated(true);
    setLoading(true);
    setShowForm(false);

    
    console.log("Claiming!");
    claimsService.updateClaim(formData, props.claim.id).then(
            (result) => {
                //redirect to family 
                const fId = result.id;
                form.reset();
                //setShowForm(true);
                setLoading(false);
                setIsSubmitted(true);
                setMessage("Claim has been posted successfully with reference number " + fId);
                props.onSuccess("Claim has been posted successfully with reference number " + fId);
            },
            (error) => {
                let msg = "An error occurred!"
                setLoading(false);
                setShowForm(true);
            }
    );
    
};
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      bg="primary"
     
    >
      <Modal.Header closeButton bg="primary">
        <Modal.Title id="contained-modal-title-vcenter" >
          Claim Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && <Alert variant='info'>{message}</Alert>}
        <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                
        {showForm &&   <Row className="gx-3 gy-3">
        <p>Fill in the form below {policyRule.bill_type}</p>
                                <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Admission Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                placeholder="admission Date"
                                                name="admission_date"
                                                value={formData.admission_date}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Diagnosis"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Diagnosis"
                                                name="diagnosis"
                                                value={formData.diagnosis}
                                                onChange={handleChange}
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
                                <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Visit"
                                        >
                                            <Form.Select
                                                aria-label="select Vist"
                                                value={formData.visit}
                                                name ="visit"
                                                onChange={handleChange}
                                                
                                            >
                                                <option value="OutPatient">OutPatient</option>
                                                <option value="InPatient">InPatient</option>
                                               
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select visit.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Ward"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Ward"
                                                value={formData.ward}
                                                name="ward"
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Ward
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                    <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Is referral"
                                        >
                                            <Form.Select
                                                aria-label="is_referral"
                                                value={formData.is_referral}
                                                onChange={handleChange}
                                                name="is_referral"
                                                required
                                            >
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                               
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                  <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Referral Number"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Referral Number"
                                                name="referralRef"
                                                value={formData.referralRef}
                                                readOnly={formData.is_referral == 'No'}
                                                onChange={handleChange}
                                            
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
                                   
                                  
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Discharge Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                placeholder="Discharge Date"
                                                name ="discharge_date"
                                                value={formData.discharge_date}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Patient State"
                                        >
                                            <Form.Select
                                                aria-label="select Patient State"
                                                name="patient_state"
                                                value={formData.patient_state}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="Discharged">Discharged</option>
                                                <option value="Referred">Referred</option>
                                                <option value="Dead">Dead</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select visit.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Bill Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Minimum Amount"
                                                name="bill_amount"
                                                value={formData.bill_amount}
                                                onChange={handleChange}
                                                
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Amount.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                        <em><strong>Ceiling:</strong> {ceiling}</em>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Start Cover"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Minimum Amount"
                                                value={formData.copay}
                                                name ="copay"
                                                onChange={handleChange}
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Claim Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Claim Amount"
                                                value={formData.amount}
                                                name="amount"
                                                onChange={handleChange}
                                               
                                            />
                                            
                                        </FloatingLabel>
                                    </Col>
                                

                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            disabled={parseInt(formData.amount) <= 0 }
                                            onClick={() => setShowForm(false)}
                                        >
                                            Continue
                                        </Button>
                                    </Col>

                                   
                                </Row>}
                            {showForm == false && <>
                            <Col md={12}>
                                <p>You are posting for Claims with details below.</p>
                                <p><strong>Bill Amount</strong>: {parseInt(formData.bill_amount).toLocaleString()}<br/>
                                <strong>Claim Amount</strong>: {formData.amount.toLocaleString()}<br/>
                                <strong>Amount received in Cash: </strong>{formData.copay.toLocaleString()}<br/>
                                </p>
                                </Col>
                                <Col md="12" className="d-grid gap-2">
                                {isSubmitted===false &&    <Button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                            disabled={loading}
                                        >
                                            Submit Claim
                                        </Button>} 
                                    </Col></>}
                            </Form> 
                {loading && <center><Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner></center>}
      </Modal.Body>
    </Modal>
  )
}

export default ClaimEditForm