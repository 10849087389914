import { API_URL } from "../constants";
import axiosAPI from '../axiosApi'
 
const getAllUsers = async () => {
    return await axiosAPI
        .get(API_URL + "users/user/");
};

const getUsersByUrl = async (url) => {
    return await axiosAPI.get(url);
}

const getUser = async (id) => {
    return await axiosAPI
        .get(API_URL + `users/user/${id}`);
};

const getProfile = async () => {
    return await axiosAPI
        .get(API_URL + `users/user/profile`);
};

const deactivateUserById = async (id) => {
    return await axiosAPI
        .get(API_URL + `users/user/${id}/deactivate/`);
}
const activateUserById = async (id) => {
    return await axiosAPI
        .get(API_URL + `users/user/${id}/activate/`);
}

const addUser = async (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return await axiosAPI
        .post(API_URL + "users/user/", {
            username: payload.username,
            email: payload.email,
            first_name: payload.first_name,
            last_name: payload.last_name,
            ref_id: payload.ref_id,
            is_staff: payload.is_staff,
            //is_superuser: false,
            password: payload.password,
            password1: payload.password1,
            type: payload.type,
        });
};

const updateUser = async (payload, id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return await axiosAPI
        .put(API_URL + `users/user/${id}/`, {
            username: payload.username,
            email: payload.email,
            first_name: payload.first_name,
            last_name: payload.last_name,
            ref_id: payload.ref_id,
            //is_staff: payload.is_staff,
            //is_superuser: payload.is_superuser,
            //password: payload.password,
            //password1: payload.password1,
            type: payload.type,
        });
};

const changePassword = async (payload, id) => {
    return await axiosAPI
        .post(API_URL + `users/user/${id}/set_password/`, payload);
};
const deleteUser = async (id) => {
    return await axiosAPI
        .delete(API_URL + `users/user/${id}/`);
};

export default {
    getAllUsers,
    getUser,
    getProfile,
    addUser,
    updateUser,
    deleteUser,
    changePassword,
    getUsersByUrl,
    deactivateUserById,
    activateUserById
};
