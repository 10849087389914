import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Row, Spinner, Stack, Table } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom'
import claimsService from '../../services/provider/claims-service';
import ClaimEditForm from './ClaimEditForm';
import AuthDetailContext from '../common/AuthDetailContext';
import AlertContext from '../widgets/alertPopup/AlertContext';
import { useHistory, useNavigate } from "react-router-dom";
import { formatHttpErrorMessage } from '../common/helper_functions';

function ClaimDetailsPage() {
    const {id} = useParams();
	let history = useHistory()
	const userData = useContext(AuthDetailContext);
	let {setAlert} = useContext(AlertContext)
    const [claim, setClaim] = useState({});
	const [showEditForm, setShowEditForm] = useState(false)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSelectedClaim();
    },[id]);

    const getSelectedClaim = () => {
        claimsService.getClaimById(id).then((response) => {
            setClaim(response);
            
        });
        setLoading(false);
    }
	const deleteClaim = async () => {
		if(!window.confirm("Are you sure you want to delete this claim")){
			return setAlert("Claim " + id + " has been deleted successfully!");;
		}
		
		await claimsService.deleteClaim(id).then(response => {
			setAlert("Claim " + id + " has been deleted successfully!","warning");
			history.goBack();
		}).catch(error => {
			let msg = formatHttpErrorMessage(error);
			setAlert("Claim Error: " + msg,"danger");
		});
	}
	const onClaimUpdate = (msg="Claim has been updated successfully!") => {
		getSelectedClaim();
		setAlert(msg)
		setShowEditForm(false);
		
	}
  return (
    <>
        <Row className="g-3">
        <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <Link to='/claims'>Claims</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {claim ? claim.id : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
			<Col xs={12} className='text-right'>
				<Stack direction="horizontal" gap={3}>
					{userData && (userData['type'] =='Provider' || userData['is_superuser']) && <Button variant='success' onClick={() => setShowEditForm(true)} disabled={!claim?.id}>Edit</Button>}
					{userData && userData['is_superuser'] && <Button variant='danger' onClick={() => deleteClaim()}>Delete Claim</Button>}
				</Stack>
				
				{showEditForm && claim && <ClaimEditForm claim={claim} member={claim.member} show={showEditForm} onHide={() => setShowEditForm(false)} onSuccess={onClaimUpdate} />}
			</Col>
            <Col xs={12}>
                <Card>
                    <Card.Header>#{claim && claim.guid}</Card.Header>
                    <Card.Body>
                        {loading && <center><Spinner animation="border"></Spinner></center>}
                        {claim && claim.member && <Row>
                            <Col md={4}>
                            <Table>
										<tbody>
										<tr>
												<th>Beneficiary</th>
												<td>{claim.member.firstname} {claim.member.othername} {claim.member.lastname}</td>
											</tr>
											<tr>
												<th>Family</th>
												<td>{claim.family}</td>
											</tr>
											<tr>
												<th>Code</th>
												<td>{claim.member.code}</td>
                        </tr> 
											
											<tr>
												<th>Amount</th>
												<td>{claim.amount} </td>
											</tr>
											<tr>
												<th>Bill Amount</th>
												<td>{claim.bill_amount}</td>
											</tr>
                                            <tr>
												<th>Out of Pocket</th>
												<td>{parseFloat(claim.copay) + parseFloat(claim.amount_paid)}</td>
											</tr>
										</tbody>
									</Table>
                            </Col>
                            <Col md={4}>
                            <Table>
										<tbody>
										<tr>
												<th>Amount Paid</th>
												<td>{claim.amount_paid}</td>
											</tr>
										<tr>
												<th>Diagnosis</th>
												<td>{claim.diagnosis}</td>
											</tr>
											<tr>
												<th>Patient state</th>
												<td>{claim.patient_state}</td>
											</tr>
											
											<tr>
												<th>Admission date</th>
												<td>{claim.admission_date}</td>
											</tr>
											<tr>
												<th>Date discharged </th>
												<td>{claim.discharge_date}</td>
											</tr>
                                            <tr>
												<th>Referral reference</th>
												<td>{claim.referral_ref}</td>
											</tr>
											
										</tbody>
									</Table>
                            </Col>
                            <Col md={4}>
                            <Table>
										<tbody>
										<tr>
												<th>Facility</th>
												<td>{claim.provider.name}</td>
											</tr>
										<tr>
												<th>Visit</th>
												<td>{claim.visit}</td>
											</tr>
										<tr>
												<th>Status</th>
												<td>{claim.status}</td>
											</tr>
											<tr>
												<th>Updated</th>
												<td>{claim.updated}</td>
											</tr>
											
											<tr>
												<th>Created</th>
												<td>{claim.created}</td>
											</tr>
											
										</tbody>
									</Table>
                            </Col>
                        </Row>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
  )
}

export default ClaimDetailsPage