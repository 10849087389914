import { useContext, useEffect, useState } from "react";
import {
    Grid,
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import schemeService from "../../services/scheme/scheme-service";
import SubSchemes from "./SubSchemes";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
const Scheme = ({ match }) => {
    const { schemeId, edit } = match.params;
    const {currentUser} = useContext(AuthDetailContext);
    const {setAlert} = useContext(AlertContext);
    const [scheme, setScheme] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [schemeName, setSchemeName] = useState("");
    const [schemeTargetPopulation ,setSchemeTargetPopulation] = useState(0);
    const [validated, setValidated] = useState(false);
    const [schemeShortName, setSchemeShortName] = useState("");
    const [schemeFoundingDate, setSchemeFoundingDate] = useState(null);
    const [schemeBody, setSchemeBody] = useState("");
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        checkForEditInUrl();
        loadScheme(schemeId);
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadScheme = (schemeId) => {
        schemeService.getScheme(schemeId).then(
            (result) => {
                setScheme(result);
                setSchemeName(result.name);
                setSchemeShortName(result.shortname);
                setSchemeTargetPopulation(result.target_population);
                setSchemeBody(result.body);
                setSchemeFoundingDate(result.founded_on);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
            }
        );
    };

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            schemeService.deleteScheme(id).then(
                () => {
                    
                    setAlert("Successfully Deleted scheme");
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage, "danger");
                }
            );
        } else {
        }
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let scheme = {
                name: schemeName,
                shortname: schemeShortName,
                target_population: schemeTargetPopulation,
                body: schemeBody,
                founded_on: schemeFoundingDate,
            };
            schemeService.updateScheme(scheme, schemeId).then(
                (result) => {
                    setShowForm(false);
                    loadScheme(schemeId);
                   
                    setAlert("Successfully updated scheme");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage, "danger");
                }
            );
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            setEdit(true);
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/schemes"> Schemes </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {scheme ? scheme.name : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="col-12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">
                            Scheme: <strong>{scheme ? scheme.name : ""}</strong>
                        </h4>
                        <div>
                            <Button
                                variant="outline-info"
                                onClick={history.goBack}
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            <Button
                                variant="primary"
                                size="sm"
                                className="m-0"
                                onClick={toggleEdit}
                            >
                                Edit
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        {!scheme && (
                            <Spinner animation="border" variant="warning" />
                        )}
                        {scheme && (
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                className="row g-3"
                            >
                                <Form.Group
                                    className="col-md-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Scheme name</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={schemeName}
                                        onChange={(e) =>
                                            setSchemeName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name for a
                                        scheme.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Short name</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={schemeShortName}
                                        onChange={(e) =>
                                            setSchemeShortName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid short name for a
                                        scheme.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Target Population</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        min={0}
                                        value={schemeTargetPopulation}
                                        onChange={(e) =>
                                            setSchemeTargetPopulation(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-4"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Founded on</Form.Label>
                                    <Form.Control
                                        readOnly={!isEdit}
                                        type="date"
                                        value={schemeFoundingDate}
                                        onChange={(e) =>
                                            setSchemeFoundingDate(
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="col-12"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        readOnly={!isEdit}
                                        as="textarea"
                                        rows={3}
                                        value={schemeBody}
                                        onChange={(e) =>
                                            setSchemeBody(e.target.value)
                                        }
                                    />
                                </Form.Group>
                                {isEdit && (
                                    <div className="col-12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <ListGroup>
                    <ListGroupItem action>
                        <Link
                            to="/sub-schemes"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            Subschemes
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem action>
                        <Link
                            to="/providers"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            Service Providers
                        </Link>
                    </ListGroupItem>
                </ListGroup>
      {/*          <SubSchemes match={{params:{chemeId:schemeId}}} currentUser={currentUser} basic={true}/> */}
            </Col>
           
        </Row>
    );
};
export default Scheme;
