import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { Alert, Badge, Breadcrumb, Button, Card, Col, Row, Spinner, Tab, Table, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import fundService from '../../services/fund/fund-service';
import { formatNumber } from '../common/helper_functions';
import FundSplitsList from './widgets/FundSplitsList';
import FundAdjustmentsList from './widgets/FundAdjustmentsList';
import AdjustmentForm from './widgets/AdjustmentForm';

function FundDetail() {
    let {id} = useParams();
    const [showAdjForm, setShowAdjForm] = useState(false)
    const { isLoading, isError, error, data:fund, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["funds", id],
      queryFn: () => fundService.getFund(id),
      keepPreviousData: true
    });
  return (
    <>
      <Row className="g-3">
        <Col xs="12" className="pt-3">
          <Breadcrumb className="border-bottom mb-3">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/funds/">Funds</Breadcrumb.Item>
            <Breadcrumb.Item active>{id}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : isError ? (
                <>
                  <Alert variant="danger">{error}</Alert>
                </>
              ) : (
                <>
                  <Row>
                    <Col className='text-right'>
                      <Button  className="float-end" variant='success' onClick={() => setShowAdjForm(true)}>Make Adjustment</Button>
                      <AdjustmentForm
                        fund={fund.data}
                        show={showAdjForm}
                        onHide={() => setShowAdjForm(false)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table>
                        <tbody>
                          <tr>
                            <th>Name</th>
                            <td>{fund.data.name}</td>
                          </tr>
                          <tr>
                            <th>Category</th>
                            <td>{fund.data.category}</td>
                          </tr>
                          <tr>
                            <th>Amount</th>
                            <td>{formatNumber(fund.data.amount)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col>
                      <Table>
                        <tbody>
                          <tr>
                            <th>Scheme</th>
                            <td>{fund.data.scheme}</td>
                          </tr>
                          <tr>
                            <th>Code</th>
                            <td>{fund.data.code}</td>
                          </tr>
                          <tr>
                            <th>Share Rate</th>
                            <td>{fund.data.share_rate}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col>
                      <Table>
                        <tbody>
                          <tr>
                            <th>Status</th>
                            <td>
                              {fund.data.is_active ? (
                                <Badge bg="success">Active</Badge>
                              ) : (
                                <Badge bg="warning">Inactive</Badge>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Updated</th>
                            <td>{fund.data.updated}</td>
                          </tr>
                          <tr>
                            <th>Created</th>
                            <td>{fund.data.created}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Tabs
                    defaultActiveKey="splits"
                    id="family-tab"
                    className="mb-3"
                  >
                    <Tab eventKey="splits" title="Fund Splits">
                      <FundSplitsList id={id} />
                    </Tab>
                    <Tab eventKey="adjustments" title="Adjustments">
                      <FundAdjustmentsList id={id} />
                    </Tab>
                  </Tabs>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default FundDetail