import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, FloatingLabel,Spinner } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import helperService from "../../services/helpers/helper-service";
import schemeService from "../../services/scheme/scheme-service";

export default function FamilyForm(props) {
    const [familyName, setFamilyName] = useState("");
    const [familyScheme, setFamilyScheme] = useState("");
    const [familyPostedOn, setFamilyPostedOn] = useState("");
    const [familyVillage, setFamilyVillage] = useState("");
    const [schemes, setSchemes] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        loadSchemes();
        loadDistricts();
        setLoading(false);
    }, []);

    const loadDistricts = () => {
        helperService.getAllDistricts().then(
            (districts) => {
                setDistricts(districts);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
            }
        );
    };

    const loadSchemes = () => {
            schemeService.getAllSubSchemes('0',300).then(
                (result) => {
                    setSchemes(result.results);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                }
            );
        
    };

    const generateUniqueId = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);
        setLoading(true);
        if (invalid == false) {
            let user = localStorage.getItem("user"); 
            let family = {
                name: familyName,
                subscheme: familyScheme,
                posted_on: familyPostedOn,
                guid: generateUniqueId(),
                owner: JSON.parse(user).id,
                village: familyVillage,
                joined_on: familyPostedOn,
            };
            schemeService.addFamily(family).then(
                (result) => {
                    setFamilyName("");
                    setFamilyScheme("");
                    setFamilyPostedOn("");
                    //redirect to family 
                    const fId = result.id;
                    setLoading(false);
                    history.push("/family/"+fId);
                   
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                }
            );
        }
    };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
     
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Family Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please fill in the form below to create a Family entry</p>
        {loading ==true && (
                                        <center><Spinner
                                            animation="border"
                                            variant="warning"
                                        /></center>
                                    )}
        <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="gx-3 gy-3">
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Family Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Family Name"
                                                value={familyName}
                                                onChange={(e) =>
                                                    setFamilyName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name for
                                                family.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Sub Scheme"
                                        >
                                            <Form.Select
                                                aria-label="select Sub Scheme"
                                                value={familyScheme}
                                                onChange={(e) =>
                                                    setFamilyScheme(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {schemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select sub scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Date Registered"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                value={familyPostedOn}
                                                onChange={(e) =>
                                                    setFamilyPostedOn(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Village"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="schemeName"
                                                value={familyVillage}
                                                onChange={(e) =>
                                                    setFamilyVillage(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid village.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn-block"
                                            disabled={loading}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
      </Modal.Body>
    </Modal>
  )
}
