import React, { useContext, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import userService from "../../services/auth/user-service";
import AlertContext from '../widgets/alertPopup/AlertContext';


function UserEditForm(props) {
    const userId = props.userId;
    const {setAlert} = useContext(AlertContext);
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [validated, setValidated] = useState(false);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                password: password,
                password1: passwordRepeat,
               
            };
            userService.changePassword(payload, userId).then(
                (result) => {
                   
                    setAlert("Successfully user password!");
                    props.onClose();
                    setValidated(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                        setAlert(resMessage, "danger");
                }
            );
        }
    };

    const closeForm =  () =>{
        props.close()
    }
  return (
    <>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
     
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          User Password Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                className="row g-3"
                            >
                               
                                <Form.Group
                                    className="col-md-12"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Enter Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-12"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Repeat Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        value={passwordRepeat}
                                        onChange={(e) =>
                                            setPasswordRepeat(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid repeat password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                             


                          
                                    <div className="col-12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="md"
                                            className='btn-block'
                                        >
                                            Change Password
                                        </Button>
                                    </div>
                            </Form>
        
            </Modal.Body>
            </Modal>
    </>
  )
}

export default UserEditForm