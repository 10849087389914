import React, { useState } from 'react'
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import fundService from '../../../services/fund/fund-service';
import { formatHttpErrorMessage } from '../../common/helper_functions';

function AdjustmentForm(props) {
    const [loading, setLoading] = useState(false);
    const [message,setMessage] = useState()
    const [adjAmount, setAdjAmount] = useState(0)
    const [formData, setFormData] = useState(
        {
            "amount": null,
            "reason": "",
            "fund": props.fund.id
        })
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAmountChange = (e) => {
        handleChange(e);
        setAdjAmount(props.fund.amount - e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        fundService.postAdjustment(formData).then(response => {
            setLoading(false);
            props.onHide()
        }).catch(error => {
            let msg = formatHttpErrorMessage(error);
            setMessage(msg)
        })
    }
  return (
    <div>
         <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
     
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Fees Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Fill in the form below</p>
        {loading==false && <Form
                                onSubmit={handleSubmit}
                            >
            {message && <Alert variant='error'>{message}</Alert>}
                                <Row className="gx-3 gy-3">
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                name="amount"
                                                placeholder="Amount"
                                                value={formData.amount}
                                                onChange={handleAmountChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name for
                                                fee.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                   

                                    

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Fund Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Fund Amount"
                                                value={props.fund.amount}
                                                readOnly
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Amount.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Fund Amount After"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Fund Amount"
                                                value={adjAmount}
                                                readOnly
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Amount.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Reason"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                name="reason"
                                                placeholder="Reason"
                                                value={formData.reason}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Amount.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form> }
                {loading && <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>}
      </Modal.Body>
    </Modal>
    </div>
  )
}

export default AdjustmentForm