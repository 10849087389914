import { useEffect, useState, useContext } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
    Pagination,
} from "react-bootstrap";
import { PencilSquare, Trash, EyeFill, Plus} from "react-bootstrap-icons";
import { Link, useHistory } from "react-router-dom";
import fundService from "../../services/fund/fund-service";
import FeesEditForm from "./FeesEditForm";
import FeesForm from "./FeesForm";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";

export default function Fees(props) {
    const currentUser = useContext(AuthDetailContext);
    const {setAlert} = useContext(AlertContext);
    const [fees, setFees] = useState([]);
    const [showFeesForm, setShowFeesForm] = useState(false);
    const [showFeesEditForm, setShowFeesEditForm] = useState(false);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [loading, setLoading] = useState(false);
    const [activeObject, setActiveObject] = useState(null);
    useEffect(() => {
        loadFees();
    }, []);

    const loadFees = (nextPage=1,limit=50) => {
        setLoading(true);
        fundService.getAllFees().then(
            (data) => {
                setFees(data);
                setLoading(false);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
            }
        );
    };

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            fundService.deleteFee(id).then(
                () => {
                    loadFees();
                    setAlert("Successfully Deleted fee");
                    
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage, "danger");
                    setLoading(false);
                }
            );
        } else {
        }
    };

    const closeForm = () =>{
        setShowFeesForm(false);
        setAlert("Fee created successfully", "success");
        loadFees();
    };
    const closeEditForm = () =>{
        setShowFeesEditForm(false);
        setAlert("Fee updated successfully", "success");
        loadFees();
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
            <h4 className="m-0">Scheme Fees</h4>
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Fees</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        
                
                        <Button variant="success" onClick={() => setShowFeesForm(true)}>
                        <Plus/>Add Fee
                    </Button>
                    <FeesForm show={showFeesForm}
        onHide={() => setShowFeesForm(false)} onClose={() => closeForm()} />
                    </Card.Body>
                </Card>
            </Col>
        
            <Col xs="12">
                <Card>
              
                    <Card.Body>
                        <Card.Title className="fs-6">
                            Current Fees
                        </Card.Title>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Criteria</th>
                                        <th>Base Amount</th> 
                                        <th>Amount</th>
                                        <th>Category</th> 
                                        <th>scheme</th>       
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading == true && 
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    }
                                    {fees.map((fee, index) => (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>{fee.name}</td>
                                            <td>{fee.criteria}</td>
                                            <td>{fee.base_amount}</td> 
                                            <td>{fee.amount}</td>  
                                            <td>{fee.category}</td>
                                            <td>{fee.scheme_name}</td>    
                                            <td>
                                            
                                            {currentUser && currentUser['is_staff'] == true ? 
                                                <div>
                                                    &nbsp;&nbsp;
                                                        <a href="#" 
                                                        onClick={() => {
                                                            setActiveObject(fee.id);
                                                            setShowFeesEditForm(true);
                                                        }}
                                                        >
                                                           <PencilSquare/>
                                                        </a> &nbsp;&nbsp;
                                                        
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                fee.id
                                                            );
                                                        }}
                                                    >
                                                        <Trash/>
                                                    </a>
                                                </div>
                                               :null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            { showFeesEditForm && <FeesEditForm id={activeObject} show={showFeesEditForm}
        onHide={() => setShowFeesEditForm(false)} onClose={() => closeEditForm()}/> }
                        </div>
                        
                    </Card.Body>
                </Card>
            </Col>
           
        </Row>
    );
}
