import { useEffect, useState, useContext } from "react";
import {
    Grid,
    Button,
    Form,
    FloatingLabel,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
    Pagination, 
    Tab,
    Tabs
} from "react-bootstrap";
import { FiSkipBack } from "react-icons/fi";
import { PencilSquare, Trash, EyeFill, Plus} from "react-bootstrap-icons";
import schemeService from "../../services/scheme/scheme-service";
import { Link, useHistory } from "react-router-dom";
import SchemeForm from './SchemeForm';
import SchemeGraphs from "./SchemeGraphs";
import authService from "../../services/auth/auth-service";
import SchemeMemberStatusGraphs from "./SchemeMemberStatusGraphs";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";

const Schemes = () => {
    const currentUser = useContext(AuthDetailContext);
    const {setAlert} = useContext(AlertContext);
    const [schemes, setSchemes] = useState([]);
    const [showSchemeForm, setShowSchemeForm] = useState(false);
     const [showForm, setShowForm] = useState(false);
    const [schemeName, setSchemeName] = useState("");   
    const [validated, setValidated] = useState(false);
    const [schemeShortName, setSchemeShortName] = useState("");
    const [schemeFoundingDate, setSchemeFoundingDate] = useState(null);
    const [schemeTargetPopulation, setSchemeTargetPopulation] = useState(0);
    const [schemeBody, setSchemeBody] = useState("");
    const [loading, setLoading] = useState(true);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [loggedInUser, setLoggedInUser] =useState({});
    const [entriesPerPage,setEntriesPerPage] = useState(50);
    const history = useHistory();

    useEffect(() => {
        loadSchemes();
        setLoggedInUser(authService.getCurrentUser());
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const loadSchemes = (nextPage=1,limit=50) => {
        console.log(loggedInUser);
        setLoading(true);
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
        schemeService.getAllSchemes(offset,limit).then(
            (result) => {
               
                setSchemes(result.results);
                const pages = (result.count > 0) ? Math.ceil(result.count/entriesPerPage) : 0
                setNumberOfPages(pages);
                setActivePage(nextPage);
                setLoading(false);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                   
                
            }
        );
        setLoading(false);
    };

    const goToPage = (event,page) => {
        loadSchemes(page);
    }

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            schemeService.deleteScheme(id).then(
                () => {
                                        
                    setAlert("Successfully Deleted scheme");
                    loadSchemes();
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                    setLoading(false);
                }
            );
        } else {
        }
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let user = localStorage.getItem("user");        
            let scheme = {
                name: schemeName,
                shortname: schemeShortName,                
                body: schemeBody,
                founded_on: schemeFoundingDate,
                target_population: schemeTargetPopulation
            };
            schemeService.addScheme(scheme).then(
                (result) => {
                    
                    setAlert("Successfully created scheme");                    
                    setSchemeName("");
                    setSchemeBody("");
                    setSchemeShortName("");
                    setShowForm(false);
                    loadSchemes();
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                    setLoading(false);
                }
            );
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Schemes</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">Schemes</h4>
                
                        {loggedInUser && loggedInUser['is_superuser'] == true &&  <Button variant="success" onClick={() => setShowSchemeForm(true)}>
                        <Plus/>Create
                    </Button>}
                    <SchemeForm show={showSchemeForm}
        onHide={() => setShowSchemeForm(false)} />
                    </Card.Body>
                </Card>
            </Col>
            {showForm && (
                <Col xs="12">
                    <Card>
                        <Card.Body>
                            <Card.Subtitle className="mb-3">
                                Add new Scheme
                            </Card.Subtitle>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="g-3">
                                    <Col md>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="schemeName"
                                                value={schemeName}
                                                onChange={(e) =>
                                                    setSchemeName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name for
                                                a scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Short Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="schemeName"
                                                value={schemeShortName}
                                                onChange={(e) =>
                                                    setSchemeShortName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid short
                                                name for a scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    
                                    <Col md>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Founded On"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                value={schemeFoundingDate}
                                                onChange={(e) =>
                                                    setSchemeFoundingDate(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Target Population"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"                                                
                                                min={0}
                                                value={schemeTargetPopulation}
                                                onChange={(e) =>
                                                    setSchemeTargetPopulation(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid number
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col className="col-12">
                                        <FloatingLabel
                                            controlId="floatingTextarea2"
                                            label="Description"
                                        >
                                            <Form.Control
                                                as="textarea"
                                                value={schemeBody}
                                                onChange={(e) =>
                                                    setSchemeBody(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="You Can Describe Scheme"
                                                style={{ height: "100px" }}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            type="submit"
                                            className=""
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            )}
            <Col xs="12">
                <Card>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Schemes">
                    <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>SIN</th>
                                        <th>Target Population</th> 
                                        <th>Subschemes</th> 
                                        <th>Families</th>  
                                        <th>Beneficiaries.</th> 
                                        <th>Rate</th>        
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading == true && 
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    }
                                    {schemes.map((scheme, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{scheme.name}</td>
                                            <td>{scheme.shortname}</td>   
                                            <td>{scheme.target_population}</td>
                                            <td>{scheme.subscheme_count}</td>    
                                            <td>{scheme.family_count}</td>  
                                            <td>{scheme.member_count}</td>   
                                            <td>{scheme.member_count && ((scheme.member_count / scheme.target_population) * 100).toFixed(4)}</td>                                      
                                            <td>
                                            
                                            {currentUser && currentUser['is_superuser'] ? 
                                                <div>
                                                     <Link
                                                            to={`/schemes/${scheme.id}`}
                                                        >
                                                           <EyeFill/>
                                                        </Link>&nbsp;&nbsp;
                                                        <Link
                                                            to={`/schemes/${scheme.id}/edit`}
                                                        >
                                                           <PencilSquare/>
                                                        </Link> &nbsp;&nbsp;
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                scheme.id
                                                            );
                                                        }}
                                                    >
                                                        <Trash/>
                                                    </a>
                                                </div>
                                               :null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <Pagination>
                        {(() => { let items = [];
                        for (let number = 1; number <= numberOfPages; number++) {
                            items.push(
                                <Pagination.Item onClick={(e) => {
                                    goToPage(
                                        e,
                                        number
                                    );
                                }} key={number} active={number === activePage}>
                                {number}
                                </Pagination.Item>
                                );
                            }
                            return items;
                        })()
                        }
                        </Pagination>
                        </div>
                     </Tab>
                    <Tab eventKey="gender" title="Schemes by Gender">
                        <SchemeGraphs />
                    </Tab>
                    <Tab eventKey="member" title="Schemes by Member Status">
                        <SchemeMemberStatusGraphs />
                    </Tab>
                    </Tabs>
                    
                </Card>
            </Col>
            
        </Row>
    );
};
export default Schemes;
