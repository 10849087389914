import React from 'react'
import fundService from '../../../services/fund/fund-service';
import { Alert, Spinner, Table } from 'react-bootstrap';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';

function FundAdjustmentsList(props) {
  let {id} = props;
  const { isLoading, isError, error, data:adjustments, isFetching, isPreviousData } =
  useQuery({
    queryKey: ["fund-adjustments", id],
    queryFn: () => fundService.getAllAdjustments({fund:id}),
    keepPreviousData: true
  });
  return (
    <div>
    {isLoading ? (
     <Spinner />
       ) : isError ? (
         <Alert variant='danger'>Error: {error.message}</Alert>
       ) : (<>
         <Table>
           <thead>
             <tr>
               <th>ID</th>
               <th>Amount</th>
               <th>Amount Before</th>
               <th>Narration</th>
               <th>Lot</th>
               <th>Created</th>
             </tr>
           </thead>
           <tbody>
             {adjustments.data.results.map((item, i) =>(
               <tr key={i}>
                 <td>{item.id}</td>
                 <td>{item.amount}</td>
                 <td>{item.amount_before}</td>
                 <td>{item.reason}</td>
                 <td>{item.lot}</td>
                 <td>{moment(item.created).format("lll")}</td>
               </tr>
             ))}
           </tbody>
         </Table>
       </>)}
 </div>
  )
}

export default FundAdjustmentsList