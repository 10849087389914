import { useEffect, useState, useContext } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
    Pagination,
} from "react-bootstrap";
import helperService from "../../services/helpers/helper-service";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import providerService from "../../services/provider/provider-service";
import fundService from "../../services/fund/fund-service";
import AsyncSelect from 'react-select/async';
import schemeService from "../../services/scheme/scheme-service";
import AuthDetailContext from "../common/AuthDetailContext";


const Funds = (props) => {
    const userData = useContext(AuthDetailContext);
    const [funds, setFunds] = useState([]);
    const [schemes, setSchemes] = useState([]);
    const [fundName,setFundName] = useState("");
    const [fundScheme, setFundScheme] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);    
    const [validated, setValidated] = useState(false);
    const [providerOwnership, setProviderOwnership] = useState("Private");
    const [districts, setDistricts] = useState([]);
    const [counties, setCounties] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [providerCounty, setProviderCounty] = useState("");
    const [searchDistrictInput,setSearchInput] = useState("");
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage,setEntriesPerPage] = useState(50);
    const history = useHistory();

    useEffect(() => {
        loadFunds();
        loadSchemes();
        
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const loadFunds = (nextPage=1,limit=50) => {
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
        fundService.getAllFunds(offset,limit).then(
            (result) => {
                setFunds(result.results);
                const pages = (result.count > 0) ? Math.ceil(result.count/entriesPerPage) : 0
                setNumberOfPages(pages);               
                setActivePage(nextPage);
                setLoading(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
                setLoading(false);
            }
        );
    };  

    const loadSchemes = (nextPage=1,limit=50) => {
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
        schemeService.getAllSchemes(offset,limit).then(
            (result) => {
               
                setSchemes(result.results);
                const pages = (result.count > 0) ? Math.ceil(result.count/entriesPerPage) : 0
                setNumberOfPages(pages);
                console.log("pages",numberOfPages)
                setActivePage(nextPage);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };
  
    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            fundService.deleteFund(id).then(
                () => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully Deleted fund");
                    loadFunds();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                    setLoading(false);
                }
            );
        } else {
        }
    };

    const goToPage = (event,page) => {
        loadFunds(page);
    }

    const filterDistricts = (inputValue) => {
        return districts.filter(district =>
            district.name.includes(String(inputValue).toUpperCase())
          )
      };

   

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                name: fundName,
                scheme: fundScheme,
            };
            fundService.addFund(payload).then(
                (result) => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully created provider");
                    setFundName("");

                    setShowForm(false);
                    loadFunds();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.detail) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                    setLoading(false);
                }
            );
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Funds</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">Funds </h4>
                        <div>
                         
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {showForm && (
                <Col xs="12">
                    <Card>
                        <Card.Body>
                            <Card.Subtitle className="mb-3">
                                Add new Fund
                            </Card.Subtitle>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="g-3">
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="FundName"
                                                value={fundName}
                                                onChange={(e) =>
                                                    setFundName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>                                   
                                    
                                    
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Scheme"
                                        >
                                            <Form.Select
                                                aria-label="select Scheme"
                                                value={fundScheme}
                                                onChange={(e) =>
                                                    setFundScheme(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {schemes.map(
                                                    (scheme) => (
                                                        <option
                                                            key={scheme.id}
                                                            value={
                                                                scheme.name
                                                            }
                                                        >
                                                            {scheme.name}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select scheme.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    
                                    

                                    <Col xs="12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            )}
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Card.Title>Current Funds </Card.Title>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Scheme</th>
                                        <th>Amount</th>
                                        <th>Active</th>
                                        <th>Share</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <Spinner
                                            animation="border"
                                            variant="warning"
                                        />
                                    )}
                                    {funds.map((fund, index) => (
                                        <tr>
                                            <td>{fund.id}</td>
                                            <td>{fund.name}</td>
                                            <td>
                                                {schemes.find(
                                                    ({ id }) =>
                                                        id === fund.scheme
                                                )?.name ?? "loading.."}
                                            </td>
                                            <td>{fund.amount}</td>
                                            <td>{fund.is_active}</td>
                                            <td>{fund.share_rate}</td>
                                            <td>
                                                {userData && userData['is_superuser'] && 
                                                <ButtonGroup
                                                    aria-label="Actions"
                                                    size="sm"
                                                >
                                                    <Button variant="secondary" as={Link} to={`/funds/${fund.id}/detail`}>
                                                        View
                                                    </Button>
                                                    <Button variant="warning" as={Link} to={`/funds/${fund.id}/edit`} className="text-white">
                                                        
                                                            {" "}
                                                            Edit
                                                        
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                fund.id
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </ButtonGroup>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <Pagination>
                        {(() => { let items = [];
                        for (let number = 1; number <= numberOfPages; number++) {
                            items.push(
                                <Pagination.Item onClick={(e) => {
                                    goToPage(
                                        e,
                                        number
                                    );
                                }} key={number} active={number === activePage}>
                                {number}
                                </Pagination.Item>
                                );
                            }
                            return items;
                        })()
                        }
                        </Pagination>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            
        </Row>
    );
};
export default Funds;
