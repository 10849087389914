import axios from "axios";
import authHeader from "../auth/auth-header";
import { API_URL } from "../constants";
import axiosAPI from "../axiosApi";

// For expired token
axios.interceptors.response.use(
    (res) => {
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl); 
        }
    }
 );


const getAllClaims = async (params={}) => {
    var response =  axios.get(API_URL + "providers/claim/", { headers: authHeader(), params:params });
    return response;
};

const getClaimById = (id) => {
    return axios
        .get(API_URL + `providers/claim/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const updateClaim = (payload, payloadId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .put(API_URL + `providers/claim/${payloadId}/`, payload)
        .then((response) => {
            return response.data;
        });
};

const deleteClaim = (id) => {
    return axiosAPI
        .delete(API_URL + `providers/claim/${id}/`)
};

const createClaim = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "providers/claim/", payload)
        .then((response) => {
            return response.data;
        });
};

const getMonthlyClaimsByScheme = (params={}) => {
    return axios.get(API_URL + 'providers/claim/monthly_claims/', {params:params, headers: authHeader() });
}

export default {
    getAllClaims,
    getClaimById,
    updateClaim,
    createClaim,
    deleteClaim,
    getMonthlyClaimsByScheme,
};
