import React, { useState, useEffect } from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import Header from "./components/common/Header";
import Welcome from "./components/Welcome";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Networks from "./components/networks/Networks";
import Schemes from "./components/schemes/Schemes";
import SubSchemes from "./components/schemes/SubSchemes";
import Families from "./components/schemes/Families";
import Members from "./components/schemes/Members";
import ImportFamily from "./components/schemes/ImportFamily";
import Providers from "./components/providers/Providers";
import Provider from "./components/providers/Provider";
import Products from "./components/products/Products";
import Services from "./components/products/Service";
import Offers from "./components/subscriptions/Offers";
import Payments from "./components/subscriptions/Payments";
import Users from "./components/users/Users";
import Reports from "./components/reports/Reports";
import Scheme from "./components/schemes/Scheme";
import SubScheme from "./components/schemes/SubScheme";
import Family from "./components/schemes/Family";
import Offer from "./components/subscriptions/Offer";
import Product from "./components/products/Product";
import User from "./components/users/User";
import Profile from "./components/users/Profile";
import Policies from "./components/subscriptions/Policies";
import Funds from "./components/funds/Funds";
import Fund from "./components/funds/Fund";
import Member from "./components/schemes/Member";
import Fees from "./components/funds/Fees";
import AuthDetailContext from "./components/common/AuthDetailContext";
import ClaimsPage from "./components/providers/ClaimsPage";
import ClaimDetailsPage from "./components/providers/ClaimDetailsPage";
import Invoices from "./components/providers/Invoices";
import InvoiceDetails from "./components/providers/InvoiceDetails";
import ProviderFamilyList from "./components/providers/ProviderFamilyList";
import AlertPopup from "./components/widgets/alertPopup/AlertPopup";
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import FundDetail from "./components/funds/FundDetail";


const queryClient = new QueryClient()

const App = () => {
    const [currentUser, setCurrentUser] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // const getTasks = async () => {
        //   const tasksFromServer = await fetchTasks()
        //   setTasks(tasksFromServer)
        // }

        // getTasks()
        let user = localStorage.getItem("user");
        if (user) {
            
            setCurrentUser(JSON.parse(user));
            setIsLoggedIn(true);
        }
    }, []);

    return (
        <>
          <Header
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
                currentUser={currentUser}
            />
<QueryClientProvider client={queryClient}>
            <Container fluid>
               
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <Welcome {...props} isUserLoggedIn={isLoggedIn} />
                        )}
                    />
                    <Route
                        exact
                        path="/login"
                        render={(props) => (
                            <Login
                                {...props}
                                isLoggedIn={isLoggedIn}
                                currentUser={currentUser}
                                setIsLoggedIn={setIsLoggedIn}
                                setCurrentUser={setCurrentUser}
                            />
                        )}
                    />
                    <AuthDetailContext.Provider value={currentUser}>
                   
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route
                        exact
                        path="/schemes/"
                        render={(props) => (
                            <Schemes {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/schemes/:schemeId"
                        render={(props) => (
                            <Scheme {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/schemes/:schemeId/edit"
                        render={(props) => (
                            <Scheme {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/sub-schemes"
                        render={(props) => (
                            <SubSchemes {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/sub-schemes/:schemeId"
                        render={(props) => (
                            <SubScheme {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/sub-schemes/:schemeId/edit"
                        render={(props) => (
                            <SubScheme {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/families/:subSchemeId?/:name?"
                        render={(props) => (
                            <Families {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/family/:familyId/:mId?"
                        render={(props) => (
                            <Family {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/family/:familyId/edit"
                        render={(props) => (
                            <Family {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/members"
                        render={(props) => (
                            <Members {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/members/:memberId"
                        render={(props) => (
                            <Member {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/member/import"
                        render={(props) => (
                            <ImportFamily {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/providers"
                        render={(props) => (
                            <Providers {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/providers/:providerId"
                        render={(props) => (
                            <Provider {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/providers/:providerId/edit"
                        render={(props) => (
                            <Provider {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/products"
                        render={(props) => (
                            <Products {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/product/:productId"
                        render={(props) => (
                            <Product {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/product/:productId/edit"
                        render={(props) => (
                            <Product {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/services"
                        render={(props) => (
                            <Services {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/offers/:familyId?/:productId?"
                        render={(props) => (
                            <Offers {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/offer/:offerId"
                        render={(props) => (
                            <Offer {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/offer/:offerId/edit"
                        render={(props) => (
                            <Offer {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/policies/:familyId?/:productId?"
                        render={(props) => (
                            <Policies {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/payments/:offerId?"
                        render={(props) => (
                            <Payments {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/users"
                        render={(props) => (
                            <Users {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/user/:userId"
                        render={(props) => (
                            <User {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/user/:userId/edit"
                        render={(props) => (
                            <User {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/profile"
                        render={(props) => (
                            <Profile {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/funds"
                        render={(props) => (
                            <Funds {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/funds/:fundId/edit"
                        render={(props) => (
                            <Fund {...props} currentUser={currentUser} />
                        )}
                    />
                     <Route
                        exact
                        path="/funds/:id/detail"
                        render={(props) => (
                            <FundDetail {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/fees/:feeId/edit"
                        render={(props) => (
                            <Fees {...props} currentUser={currentUser} />
                        )}
                    />

<Route
                        exact
                        path="/fees"
                        render={(props) => (
                            <Fees {...props} currentUser={currentUser} />
                        )}
                    />

                <Route
                        exact
                        path="/subscribers"
                        render={(props) => (
                            <ProviderFamilyList {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/claims"
                        render={(props) => (
                            <ClaimsPage {...props} currentUser={currentUser} />
                        )}
                    />

                    <Route
                        exact
                        path="/claims/:id"
                        render={(props) => (
                            <ClaimDetailsPage {...props} currentUser={currentUser} />
                        )}
                    />
                    
                     <Route
                        exact
                        path="/reports"
                        render={(props) => (
                            <Reports {...props} currentUser={currentUser} />
                        )}
                    />
                  
                    <Route
                        exact
                        path="/invoices"
                        render={(props) => (
                            <Invoices {...props} currentUser={currentUser} />
                        )}
                    />
                    <Route
                        exact
                        path="/invoices/:id"
                        render={(props) => (
                            <InvoiceDetails {...props} currentUser={currentUser} />
                        )}
                    />
                    </AuthDetailContext.Provider>
                     <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                            <p>Page Not Found!</p>
                            </main>
                        }
                        />
                </Switch>
                <AlertPopup />
            </Container>
            </QueryClientProvider>
        </>
    );
};
export default App;
