import { useEffect, useState } from "react";
import {
    Grid,
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Spinner,
    Tabs,
    Tab,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import helperService from "../../services/helpers/helper-service";
import providerService from "../../services/provider/provider-service";
import AsyncSelect from 'react-select/async';
import Offers from "../subscriptions/Offers";
import { formatHttpErrorMessage } from "../common/helper_functions";
const Provider = ({ currentUser, match }) => {
    const { providerId, edit } = match.params;
    const [provider, setProvider] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [members, setMembers] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [showClaimForm,setShowClaimForm] = useState(false);
    const [providerName, setProviderName] = useState("");
    const [providerBeds, setProviderBeds] = useState(0);
    const [providerDistrict, setProviderDistrict] = useState("");
    const [providerSubcounty, setProviderSubcounty] = useState("");
    const [providerParish, setProviderParish] = useState("");
    const [providerVillage, setProviderVillage] = useState("");
    const [providerLevel, setProviderLevel] = useState(1);
    const [providerBilling, setProviderBilling] = useState("Service");
    const [providerFirstContact,setProviderFirstContact] = useState(undefined);
    const [providerStartDate,setProviderStartDate] = useState(undefined);
    const [providerExpiryDate,setProviderExpiryDate] = useState(undefined);
    const [providerStatus,setProviderStatus] = useState("Active");
    const [providerOwnership, setProviderOwnership] = useState("Private");
    const [providerCounty, setProviderCounty] = useState("");
    const [validated, setValidated] = useState(false);    
    const [districts, setDistricts] = useState([]);
    const [counties, setCounties] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [searchDistrictInput,setSearchInput] = useState("");
    const [selectedDistrict,setDistrict] =useState(undefined);
    const [selectedCounty,setSelectedCounty] = useState(undefined);
    const [claims,setClaims] = useState([]);
    const [families,setFamilies] = useState([]);
    const [selectedFamily,setSelectedFamily] = useState(null);
    const [claimVisit, setClaimVisit] = useState("OutPatient");
    const [claimWard,setClaimWard] = useState("");
    const [claimDiagnosis,setClaimDiagnosis] = useState("");
    const [claimPatientState,setClaimPatientState] = useState("Discharged");
    const [claimIsReferral,setClaimIsReferral] = useState("No");
    const [claimFacility,setClaimFacility] = useState("");
    const [claimAdmissionDate,setClaimAdmissionDate] = useState(null);
    const [claimDischargeDate,setClaimDischargeDate] = useState(null);
    const [claimAmount,setClaimAmount] = useState(0);
    const [claimBillAmount,setClaimBillAmount] = useState(0);
    const [claimCopay,setClaimCopay] = useState(0);
    const [claimAmountPaid,setClaimAmountPaid] = useState(0);
    const [claimMember,setClaimMember] = useState(null);
    
    const history = useHistory();

    useEffect(() => {
        checkForEditInUrl();
        //loadParentSchemes();
        loadDistricts();
        //loadSubcounties();
        loadProvider(providerId);
        loadProviderClaims();
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadProvider = (providerId) => {
       providerService.getProvider(providerId).then(
            (result) => {
                
                
                setProvider(result);
                setProviderName(result.name);
                setProviderLevel(result.level);
                setProviderBeds(result.bed_no);
                setProviderBilling(result.bill_method);
                setProviderDistrict(result.district);
                setProviderStatus(result.status);
                setProviderExpiryDate(result.expiry_date);
                setProviderFirstContact(result.first_contact);
                setProviderStartDate(result.start_date);
                setProviderSubcounty(result.subcounty);
                setProviderParish(result.parish);
                setProviderOwnership(result.ownership);
                setProviderVillage(result.village);
                findDistrictByName(result.district,result.subcounty);
                
                
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };

    const toggleClaimForm = () => {
        setShowClaimForm(!showClaimForm);
    };
    const setClaimForm = (bstatus) => {
        setShowClaimForm(bstatus);
    }
    const showClaimEditForm = (claimId) => {
        const claim =
            claims !== null
                ? claims.find(({ id }) => id === claimId)
                : undefined;
        if (claim !== undefined) {
            setClaimVisit(claim.visit)
            setClaimForm(true);
        }
    };

    const deleteClaim = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            providerService.deleteClaim(id).then(
                () => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully Deleted Claim");
                    loadProviderClaims();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        } else {
        }
    };

    const loadDistricts = () => {
        helperService.getAllDistricts().then(
            (districts) => {
                setDistricts(districts);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };
    const loadSubcounties = () => {
        helperService.getAllSubcounties().then(
            (subCounties) => {
                setSubcounties(subCounties);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };
    const loadProviderClaims = () => {
        providerService.filterClaims('','',providerId).then( response => {
            console.log(response)
            // setClaims(response);
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            // alert(msg)
        })
    }
    const findDistrictByName = (name,subcountyName) => {
       helperService.getDistrictByName(name).then(
           (result) => {
                setDistrict(result[0]);
                helperService.getCountyByDistrictId(result[0].id).then(
                    (counties) => {
                        setCounties(counties)
                        //set selected subcounty
                        helperService.filterSubcounty(subcountyName).then(
                            (subcountyResults) => {
                                const countyId = (subcountyResults.length > 0) ? subcountyResults[0].county : 0;
                                setSelectedCounty(countyId);
                                // get subcounties of a county
                                helperService.getSubcountyByCountyId(countyId).then(
                                    (subs) =>{
                                        setSubcounties(subs);
                                    }
                                )
                                
                            },
                            (error) => {

                            }
                        )
                    },
                    (error) => {
        
                    }
                )
           },
           (error) => {

           }
       )
        
        
       
    }

    const filterDistricts = (inputValue) => {
        return districts.filter(district =>
            district.name.includes(String(inputValue).toUpperCase())
          )
      };

    
      const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
          callback(filterDistricts(inputValue));
        }, 100);
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setSearchInput(inputValue);        
        return inputValue;
      };  

      const handleDistrictChange = (newValue) => {
        console.log(newValue);
        if(newValue !== null){
            setProviderDistrict(newValue.name);
            //get subcounties by name
            helperService.getCountyByDistrictId(newValue.id).then(
                (counties) => {
                    setCounties(counties)
                },
                (error) => {

                }
            )
        }
        
       
      }; 
      
      const handleCountyChange = (event) => {
        console.log(event.target.options[event.target.selectedIndex].text)
        setProviderCounty(event.target.options[event.target.selectedIndex].text)
        setSelectedCounty(event.target.value);
        //get subcounties by name
        helperService.getSubcountyByCountyId(event.target.value).then(
            (subcounties) => {
                setSubcounties(subcounties)
            },
            (error) => {

            }
        )
        
      };
      const generateUniqueId = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };  

     
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let provider = {
                name: providerName,
                status: providerStatus,
                bed_no: providerBeds,
                level: providerLevel,
                bill_method: providerBilling,
                district: providerDistrict,
                county: providerCounty,
                subcounty: providerSubcounty,
                parish: providerParish,
                village: providerVillage,
                ownership: providerOwnership,
                first_contact: providerFirstContact,
                start_date: providerStartDate,
                expiry_date: providerExpiryDate,
            };
            providerService.updateProvider(provider, providerId).then(
                (result) => {
                    setToastVariant("light");

                    loadProvider(providerId);
                    setShowToast(true);
                    setToastMessage("Successfully updated provider");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        }
    };

    const submitClaim = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                visit: claimVisit,
                guid: generateUniqueId(),
                ward: claimWard,
                diagnosis: claimDiagnosis,
                patient_state: claimPatientState,
                is_referral: claimIsReferral,
                facility: claimFacility,
                admission_date: claimAdmissionDate,
                discharge_date: claimDischargeDate,
                amount: claimAmount,
                bill_amount: claimBillAmount,
                copay: claimCopay,
                amount_paid: claimAmountPaid,
                member: claimMember,
                provider: providerId
            };
            providerService.addClaim(payload).then(
                (result) => {
                    setToastVariant("light");

                    loadProvider(providerId);
                    loadProviderClaims();
                    setShowToast(true);
                    setToastMessage("Successfully added claim");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            setEdit(true);
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/providers"> Providers </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {provider ? provider.name : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">
                            Provider:{" "}
                            <strong>{provider ? provider.name : ""}</strong>
                        </h4>
                        <div>
                            <Button
                                variant="outline-info"
                                onClick={history.goBack}
                                size="sm"
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            <Button
                                variant="primary"
                                onClick={toggleEdit}
                                size="sm"
                            >
                                Edit
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Tabs defaultActiveKey="provider" id="provider-tab" className="mb-3">
                            <Tab eventKey="provider" title="Provider">
                                {subcounties.length < 1 && (
                                    <Spinner animation="border" variant="warning" />
                                )}
                                {subcounties.length > 0 && (
                                    <Form
                                        className="row gx-3 gy-2"
                                        noValidate
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Provider name</Form.Label>
                                            <Form.Control
                                                required
                                                readOnly={!isEdit}
                                                type="text"
                                                value={providerName}
                                                onChange={(e) =>
                                                    setProviderName(e.target.value)
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name 
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>No Of Beds</Form.Label>                                    
                                            <Form.Control
                                                required
                                                readOnly={!isEdit}
                                                type="number"
                                                value={providerBeds}
                                                onChange={(e) =>
                                                    setProviderBeds(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                                
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid number
                                            </Form.Control.Feedback>                                    
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Provider Status</Form.Label>                                    
                                            <Form.Select
                                                disabled={!isEdit}
                                                value={providerStatus}
                                                onChange={(e) =>
                                                    setProviderStatus(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Active"> Active</option>
                                                <option value="Inactive">Inactive</option>
                                                <option value="Suspended">Suspended</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Provider Level</Form.Label>                                    
                                            <Form.Select
                                                disabled={!isEdit}
                                                value={providerLevel}
                                                onChange={(e) =>
                                                    setProviderLevel(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="1">I</option>
                                                <option value="2">II</option>
                                                <option value="3">III</option>
                                                <option value="4">IV</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="parentschemeId"
                                        >
                                            <Form.Label>Ownership</Form.Label>
                                            
                                            <Form.Select
                                                aria-label="select"
                                                disabled={!isEdit}
                                                value={providerOwnership}
                                                onChange={(e) =>
                                                    setProviderOwnership(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Private">
                                                    Private
                                                </option>
                                                <option value="Public">
                                                    Public
                                                </option>
                                                <option value="PNP">
                                                    Private Non Profit
                                                </option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select 
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="parentschemeId"
                                        >
                                            <Form.Label>Billing</Form.Label>
                                            <Form.Select
                                                aria-label="select"
                                                disabled={!isEdit}
                                                value={providerBilling}
                                                onChange={(e) =>
                                                    setProviderBilling(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Service">
                                                    Fee for service
                                                </option>
                                                <option value="Flat">
                                                    Flat Fees
                                                </option>
                                                <option value="Capitation">
                                                    Capitation
                                                </option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select 
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>First Contact</Form.Label>                                    
                                            <Form.Control
                                                required
                                                readOnly={!isEdit}
                                                type="date"
                                                value={providerFirstContact}
                                                onChange={(e) =>
                                                    setProviderFirstContact(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                                
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid date
                                            </Form.Control.Feedback>                                    
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Start Date</Form.Label>                                    
                                            <Form.Control
                                                required
                                                readOnly={!isEdit}
                                                type="date"
                                                value={providerStartDate}
                                                onChange={(e) =>
                                                    setProviderStartDate(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                                
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid date
                                            </Form.Control.Feedback>                                    
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Expiry Date</Form.Label>                                    
                                            <Form.Control
                                                required
                                                readOnly={!isEdit}
                                                type="date"
                                                value={providerExpiryDate}
                                                onChange={(e) =>
                                                    setProviderExpiryDate(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                                
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid date
                                            </Form.Control.Feedback>                                    
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="districtchemeId"
                                        >
                                            <Form.Label>District</Form.Label>                                    
                                            <AsyncSelect
                                                        cacheOptions
                                                        isClearable
                                                        placeholder="select district"
                                                        defaultValue={selectedDistrict}
                                                        loadOptions={loadOptions}
                                                        defaultOptions={districts}
                                                        getOptionLabel={(option) => `${option.name}`}
                                                        getOptionValue={(option) => `${option['id']}`}
                                                        onInputChange={handleInputChange}
                                                        onChange={handleDistrictChange}
                                                        />
                                            <Form.Control.Feedback type="invalid">
                                                Please select district.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="countychemeId"
                                        >
                                            <Form.Label>County</Form.Label>
                                            <Form.Select
                                                aria-label="select County"
                                                disabled={!isEdit}
                                                value={selectedCounty}                                        
                                                onChange={(e) =>
                                                    handleCountyChange(
                                                        e
                                                    )
                                                }
                                                
                                            >
                                                <option value="">None</option>
                                                {counties.map(
                                                    (county) => (
                                                        <option
                                                            key={county.id}
                                                            value={
                                                                county.id
                                                            }
                                                        >
                                                            {county.name}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select subcounty.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="subcountychemeId"
                                        >
                                            <Form.Label>Subcounty</Form.Label>
                                            <Form.Select
                                                aria-label="select Sub county"
                                                disabled={!isEdit}
                                                value={providerSubcounty}
                                                onChange={(e) =>
                                                    setProviderSubcounty(e.target.value)
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {subcounties.map((subcounty) => (
                                                    <option
                                                        key={subcounty.id}
                                                        value={subcounty.name}
                                                    >
                                                        {subcounty.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select subcounty.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="schemeparishid"
                                        >
                                            <Form.Label>Parish</Form.Label>
                                            <Form.Control
                                                required
                                                readOnly={!isEdit}
                                                type="text"
                                                value={providerParish}
                                                onChange={(e) =>
                                                    setProviderParish(e.target.value)
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid parish
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="col-md-3"
                                            controlId="schemevillagehid"
                                        >
                                            <Form.Label>Village</Form.Label>
                                            <Form.Control
                                                required
                                                readOnly={!isEdit}
                                                type="text"
                                                value={providerVillage}
                                                onChange={(e) =>
                                                    setProviderVillage(e.target.value)
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid village
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {isEdit && (
                                            <div className="col-md-3 mt-3">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    size="sm"
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        )}
                                    </Form>
                                    )}
                                </Tab>
                                <Tab eventKey="claims" title="Claims">
                                    <Row className="g-3">
                                        <Col xs="12">                                               
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                onClick={toggleClaimForm}
                                            >
                                                Add Claim
                                            </Button>
                                        </Col>
                                        {showClaimForm && (
                                        <Col xs="12">
                                            <Form
                                                noValidate
                                                validated={validated}
                                                onSubmit={submitClaim}
                                            >
                                                <Row className="g-3">
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Visit"
                                                            className=""
                                                        >
                                                            <Form.Select
                                                                aria-label="select"
                                                                value={claimVisit}
                                                                onChange={(e) =>
                                                                    setClaimVisit(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                <option value="OutPatient">Outpatient Care</option>
                                                                <option value="InPatient">Inpatient Care</option>                                                                
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Ward"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="ward"
                                                                type="text"
                                                                value={claimWard}
                                                                onChange={(e) =>
                                                                    setClaimWard(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Diagnosis"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="diagnosis"
                                                                type="text"
                                                                value={claimDiagnosis}
                                                                onChange={(e) =>
                                                                    setClaimDiagnosis(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Patient State"
                                                            className=""
                                                        >
                                                            <Form.Select
                                                                aria-label="select"
                                                                value={claimPatientState}
                                                                onChange={(e) =>
                                                                    setClaimPatientState(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                <option value="Discharged">Discharged</option>
                                                                <option value="Referred">Referred</option> 
                                                                <option value="Died">Died</option>                                                               
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Is Referral"
                                                            className=""
                                                        >
                                                            <Form.Select
                                                                aria-label="select"
                                                                value={claimIsReferral}
                                                                onChange={(e) =>
                                                                    setClaimIsReferral(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>                                                               
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Facility"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="facility"
                                                                type="text"
                                                                value={claimFacility}
                                                                onChange={(e) =>
                                                                    setClaimFacility(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Admission Date"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="admission date"
                                                                type="date"
                                                                value={claimAdmissionDate}
                                                                onChange={(e) =>
                                                                    setClaimAdmissionDate(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Discharge Date"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="admission date"
                                                                type="date"
                                                                value={claimDischargeDate}
                                                                onChange={(e) =>
                                                                    setClaimDischargeDate(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Amount"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="amount"
                                                                type="number"
                                                                value={claimAmount}
                                                                onChange={(e) =>
                                                                    setClaimAmount(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Bill Amount"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="bill amount"
                                                                type="number"
                                                                value={claimBillAmount}
                                                                onChange={(e) =>
                                                                    setClaimBillAmount(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Co pay"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="copay"
                                                                type="number"
                                                                value={claimCopay}
                                                                onChange={(e) =>
                                                                    setClaimCopay(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Amount Paid"
                                                            className=""
                                                        >
                                                            <Form.Control
                                                                aria-label="amount paid"
                                                                type="number"
                                                                value={claimAmountPaid}
                                                                onChange={(e) =>
                                                                    setClaimAmountPaid(
                                                                        e.target.value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                            
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid option.
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md="3">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Member"
                                                            className="">
                                                                <Form.Select
                                                                    aria-label="select member"
                                                                    value={claimMember}
                                                                    onChange={(e) =>
                                                                        setClaimMember(e.target.value)
                                                                    }
                                                                    
                                                                >
                                                                    <option value="">None</option>
                                                                    {members.map((member) => (
                                                                        <option
                                                                            key={member.id}
                                                                            value={member.id}
                                                                        >
                                                                            {member.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please select member.
                                                                </Form.Control.Feedback>

                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col xs="12">
                                                        <Button
                                                            variant="primary"
                                                            type="submit"
                                                            size="sm"
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                        <div className="table-responsive">
                                            <Table striped hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Visit</th>
                                                        <th>Ward</th>
                                                        <th>Patient State</th>
                                                        <th>Is Referall</th>
                                                        <th>Admission Date</th>
                                                        <th>Discharge Date</th>
                                                        <th>Amount</th>
                                                        <th>Bill Amount</th>
                                                        <th>Copay</th>
                                                        <th>Amount Paid</th>
                                                        <th>Member</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {claims.map((claim, index) => (
                                                        <tr>
                                                            <td>{claim.id}</td>
                                                            <td>{claim.visit}</td>
                                                            <td>{claim.ward}</td>
                                                            <td>{claim.patient_state}</td>
                                                            <td>{claim.is_referral}</td>
                                                            <td>{claim.admission_date}</td>
                                                            <td>{claim.discharge_date}</td>
                                                            <td>{claim.amount}</td>
                                                            <td>{claim.bill_amount}</td>
                                                            <td>{claim.copay}</td>
                                                            <td>{claim.amount_paid}</td>
                                                            <td>{claim.member}</td>
                                                            <td>
                                                            <ButtonGroup
                                                                    aria-label="Actions"
                                                                    size="sm"
                                                                >
                                                                    <Button
                                                                        variant="warning"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            showClaimEditForm(
                                                                                claim.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            deleteClaim(
                                                                                e,
                                                                                claim.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        </Col>
                                    </Row>
                                    
                                </Tab>
                                
                        </Tabs>
                    </Card.Body>
                </Card>
            </Col>
           
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default Provider;
