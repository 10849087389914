import { useEffect, useState } from "react";
import {
	Button,
	Form,
	Spinner,
	FloatingLabel,
	ButtonGroup,
	Card,
	Breadcrumb,
	Toast,
	Table,
	Row,
	Col,
} from "react-bootstrap";
import userService from "../../services/auth/user-service";
import { Link } from "react-router-dom";
import helperService from "../../services/helpers/helper-service";
import AsyncSelect from "react-select/async";

const Users = ({ currentUser }) => {
	const [users, setUsers] = useState([]);

	const [ToastMessage, setToastMessage] = useState("");
	const [showToast, setShowToast] = useState(false);
	const [toastVariant, setToastVariant] = useState("light");
	const [showForm, setShowForm] = useState(false);
	const [userUsername, setUserUsername] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [validated, setValidated] = useState(false);
	const [userFirstName, setUserFirstName] = useState("");
	const [userLastName, setUserLastName] = useState("");
	const [userType, setUserType] = useState("Org");
	const [userPassword, setUserPassword] = useState("");
	const [userPasswordRepeat, setUserPasswordRepeat] = useState("");
	const [userIsAdmin, setUserIsAdmin] = useState(false);
	const [userIsStaff, setUserIsStaff] = useState(false);
	const [userReference, setUserReference] = useState(null);
	const [searchInput, setSearchInput] = useState("");
	const [defaultUserTypes, setDefaultUserTypes] = useState([]);
	const [refOptions, setRefOptions] = useState([]);
	const [itemsLoading, setItemsLoading] = useState("Choose")
	const [nextPage, setNextPage] = useState()
	const [previousPage, setPreviousPage] = useState()

	useEffect(() => {
		loadUsers();
		loadDefaultOptions("Org");
	}, []);
	const toggleForm = () => {
		setShowForm(!showForm);
	};

	const loadUsers = () => {
		
		userService.getAllUsers().then(
			(payload) => {
				setUsers(payload.data.results);
				setNextPage(payload.data.next)
				setPreviousPage(payload.data.previous)
			},
			(error) => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setToastVariant("danger");
				setShowToast(true);
				setToastMessage(resMessage);
			}
		);
	};

	const loadPage = (url) => {
		userService.getUsersByUrl(url.replace('http', 'https')).then(response => {
			setNextPage(response.data.next)
			setPreviousPage(response.data.previous)
			setUsers(response.data.results)
		}).catch(error => {
			console.log(error)
		})
	}

	const loadOptions = (inputValue) => {
		//get values based on client type chosen
		return helperService
			.getUserCategoryReferences(userType, inputValue)
			.then(
				(results) => {
					console.log(results)
					setRefOptions(results);
					return results;
				},
				(error) => {}
			);
	};
	const loadDefaultOptions = (userType) => {
		
		setRefOptions([]);
		setItemsLoading("Please wait...")
		return helperService.getUserCategoryReferences(userType, userType).then(
			(response) => {
				setRefOptions(response.data.results);
				setDefaultUserTypes(response.data.results);
				setItemsLoading("Choose");
			},
			(error) => {}
		);
	};

	const handleInputChange = (newValue) => {
		const inputValue = newValue; //newValue.replace(/\W/g, '');
		setSearchInput(inputValue);
		return inputValue;
	};

	const handleReferenceChange = (newValue) => {
		if (newValue !== null) {
			setUserReference(newValue.id);
		}
	};
	const changeUserType = (event) => {
		setUserType(event.target.value);
		loadDefaultOptions(event.target.value);
	};
	const handleDelete = (event, id) => {
		var result = window.confirm("are you sure you want to delete? ");
		if (result == true) {
			userService.deleteUser(id).then(
				() => {
					setToastVariant("light");
					setShowToast(true);
					setToastMessage("Successfully Deleted user");
					loadUsers();
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setToastVariant("danger");
					setShowToast(true);
					setToastMessage(resMessage);
				}
			);
		} else {
		}
	};
	const handleSubmit = (event) => {
		const form = event.currentTarget;
		let invalid = false;
		event.preventDefault();
		if (form.checkValidity() === false) {
			//event.preventDefault();
			event.stopPropagation();
			invalid = true;
		}

		setValidated(true);

		if (invalid == false) {
			let payload = {
				username: userUsername,
				email: userEmail,
				first_name: userFirstName,
				last_name: userLastName,
				is_staff: userIsStaff,
				//is_superuser: userIsAdmin,
				password: userPassword,
				password1: userPasswordRepeat,
				type: userType,
				ref_id: userReference,
			};
			userService.addUser(payload).then(
				(result) => {
					setToastVariant("light");
					setShowToast(true);
					setToastMessage("Successfully created user");
					setUserUsername("");
					setUserEmail("");
					setUserFirstName("");
					setUserLastName("");
					setUserPassword("");
					setUserPasswordRepeat("");
					setUserType("Org");
					setUserIsAdmin(0);
					setUserIsStaff(0);
					setUserReference(null);
					setShowForm(false);
					loadUsers();
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.non_field_errors) ||
						error.message ||
						error.toString();
					// console.log("messageError", error.response);
					setToastVariant("danger");
					setShowToast(true);
					setToastMessage(resMessage);
				}
			);
		}
	};

	return (
		<Row className="g-3">
			<Col xs="12" className="pt-3">
				<Breadcrumb className="border-bottom mb-3">
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Users</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col xs="12">
				<Card>
					<Card.Body className="bg-white d-flex justify-content-between align-content-center">
						<h4 className="m-0">Users</h4>
						<div>
							<Button
								className="float-end"
								variant="primary"
								onClick={toggleForm}
								size="sm"
							>
								Add
							</Button>
						</div>
					</Card.Body>
				</Card>
			</Col>
			{showForm && (
				<Col xs="12">
					<Card>
						<Card.Body>
							<Card.Subtitle className="mb-2">
								Add new User
							</Card.Subtitle>
							<Form
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
							>
								<Row className="gx-3 gy-3">
									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="Username"
											className=""
										>
											<Form.Control
												type="text"
												placeholder="userName"
												value={userUsername}
												onChange={(e) =>
													setUserUsername(
														e.target.value
													)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid username.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>
									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="Email"
											className=""
										>
											<Form.Control
												type="email"
												placeholder=""
												value={userEmail}
												onChange={(e) =>
													setUserEmail(e.target.value)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid email
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>

									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="First Name"
											className=""
										>
											<Form.Control
												type="text"
												value={userFirstName}
												onChange={(e) =>
													setUserFirstName(
														e.target.value
													)
												}
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid name.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>

									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="Surname"
											className=""
										>
											<Form.Control
												type="text"
												value={userLastName}
												onChange={(e) =>
													setUserLastName(
														e.target.value
													)
												}
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid name.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>

									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="User type"
										>
											<Form.Select
												aria-label="select type"
												value={userType}
												onChange={changeUserType}
												required
											>
												<option value="Org">
													Scheme Admin
												</option>
												<option value="Sales">
													Scheme Team
												</option>
												<option value="Group">
													Subscheme Agent
												</option>
												<option value="Provider">
													Provider
												</option>
												<option value="ProviderFinance">
													Provider Accountant
												</option>
											</Form.Select>
										</FloatingLabel>
									</Col>
									<Col md="3">
								
                                    <FloatingLabel
											controlId="floatingInput"
											label="Reference"
											className=""
										>
											
                                    <Form.Select
                                        aria-label="select Sub Reference"
                                        value={userReference}
                                        onChange={(e) =>
                                            setUserReference(e.target.value)
                                        }
                                        required
                                    >
                                        <option>{itemsLoading}</option>
                                        {refOptions && refOptions.map((item, i) => (<option value={item.id} key={i}>{item.name}</option>))}
                                        
                                    </Form.Select>
									
										</FloatingLabel>
                               
										<Form.Control.Feedback type="invalid">
											Please select reference.
										</Form.Control.Feedback>
									</Col>

									<Col xs="12">
										<hr />
									</Col>
									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="Password"
											className=""
										>
											<Form.Control
												type="password"
												value={userPassword}
												onChange={(e) =>
													setUserPassword(
														e.target.value
													)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid password.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>

									<Col md="3">
										<FloatingLabel
											controlId="floatingInput"
											label="Repeat Password"
											className=""
										>
											<Form.Control
												type="password"
												value={userPasswordRepeat}
												onChange={(e) =>
													setUserPasswordRepeat(
														e.target.value
													)
												}
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please provide a valid password.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Col>

									<Col xs="12">
										<Button
											variant="primary"
											type="submit"
											size="sm"
										>
											Save
										</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			)}
			<Col xs="12">
				<Card>
					<Card.Body>
						<Card.Title>Current Users </Card.Title>
						<div className="table-responsive">
							<Table striped hover>
								<thead>
									<tr>
										<th>#</th>
										<th>username</th>
										<th>Email</th>
										<th>First Name</th>
										<th>Surname</th>
										<th>User Type</th>
										<th>Is Staff</th>
										<th>Is Admin</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{users.length < 1 && (
										<Spinner
											animation="border"
											variant="warning"
										/>
									)}
									{users.map((user, index) => (
										<tr>
											<td>{user.id}</td>
											<td>{user.username}</td>
											<td>{user.email}</td>
											<td>{user.first_name}</td>
											<td>{user.last_name}</td>
											<td>{user.type}</td>
											<td>
												{user.is_staff ? "Yes" : "No"}
											</td>
											<td>
												{user.is_superuser
													? "Yes"
													: "No"}
											</td>

											<td>
												<ButtonGroup
													aria-label="Actions"
													size="sm"
												>
													<Button variant="secondary">
														<Link
															to={`/user/${user.id}`}
															style={{
																textDecoration:
																	"none",
																color: "white",
															}}
														>
															{" "}
															View
														</Link>
													</Button>
													<Button variant="warning">
														<Link
															to={`/user/${user.id}/edit`}
															style={{
																textDecoration:
																	"none",
																color: "white",
															}}
														>
															{" "}
															Edit
														</Link>
													</Button>
													<Button
														variant="danger"
														onClick={(e) => {
															handleDelete(
																e,
																user.id
															);
														}}
													>
														Delete
													</Button>
												</ButtonGroup>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							{previousPage && <Button variant="secondary" onClick={() => loadPage(previousPage)} >Previous</Button>} 
							{nextPage && <Button variant="secondary" onClick={() => loadPage(nextPage)} >Next</Button>}
						</div>
					</Card.Body>
				</Card>
			</Col>
			<Toast
				onClose={() => setShowToast(false)}
				show={showToast}
				delay={6000}
				autohide
				bg={toastVariant}
			>
				<Toast.Header>
					<img
						src="holder.js/20x20?text=%20"
						className="rounded me-2"
						alt=""
					/>
					<strong className="me-auto">Infomation</strong>
					<small>now</small>
				</Toast.Header>
				<Toast.Body>{ToastMessage}</Toast.Body>
			</Toast>
		</Row>
	);
};
export default Users;
