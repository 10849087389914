import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../constants";
import axiosAPI from '../axiosApi'

const register = (username, email, password) => {
    return axiosAPI.post("users/signup", {
        username,
        email,
        password,
    });
};

const login = async (username, password) => {
    let token = username + ":" + password;
    let hash = btoa(token);
    let accessToken = null;
    let refreshToken = null;
    //localStorage.setItem("basicauth", hash);

    return await axiosAPI
        .post("/users/login/", { username: username, password: password })
        .then(async (result) => {
            accessToken = result.data.access;
            refreshToken = result.data.refresh;
            console.log("access",result.data.access)
                let user = {
                    access: accessToken,
                    refresh: refreshToken,
                    id: result.data.user.id,
                    first_name: result.data.user.first_name,
                    last_name: result.data.user.last_name,
                    email: result.data.user.email,
                    username: result.data.user.username,
                    type: result.data.user.type,
                    ref_id: result.data.user.ref_id,
                    is_staff: result.data.user.is_staff,
                    is_superuser: result.data.user.is_superuser,
                    last_login: result.data.user.last_login,
                };
            localStorage.setItem("user", JSON.stringify(user));

            // axiosAPI.defaults.headers.common["Authorization"] =
            //     "Bearer " + accessToken;
            // const headers = {
            //     "Authorization":"Bearer " + accessToken
            // }
            // return await axiosAPI.get("users/user/profile/", {headers:headers}).then((result) => {
            //     let user = {
            //         access: accessToken,
            //         refresh: refreshToken,
            //         id: result.data.id,
            //         first_name: result.data.first_name,
            //         last_name: result.data.last_name,
            //         email: result.data.email,
            //         username: result.data.username,
            //         type: result.data.type,
            //         ref_id: result.data.ref_id,
            //         is_staff: result.data.is_staff,
            //         is_superuser: result.data.is_superuser,
            //         last_login: result.data.last_login,
            //     };
            //     localStorage.setItem("user", JSON.stringify(user));
            // });
        });
};

const logout = () => {
    localStorage.removeItem("basicauth");
    localStorage.removeItem("user");
};

const getCurrentUser = async() => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    register,
    login,
    logout,
    getCurrentUser,
};
