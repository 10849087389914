import authHeader from "../auth/auth-header";
import axiosAPI from "../axiosApi";

const getAllReports = (params) => {
    return axiosAPI
        .get(`reporting/report/`, { headers: authHeader(), params });
};
const getReport = (id) => {
    return axiosAPI
        .get(`reporting/report/${id}`, { headers: authHeader() })
};

const addReport = (report) => {
    return axiosAPI
        .post("reporting/report/", report);
};



const deleteReport = (reportId) => {
    return axiosAPI
        .delete(`reporting/report/${reportId}/`, { headers: authHeader() })
};


export default {
    getAllReports,
    getReport,
    addReport,
    deleteReport
};
