import { useEffect, useState } from "react";
import schemeService from "../../services/scheme/scheme-service";
import BarGraph from "./BarGraph";

export default function SchemeMemberStatusGraphs() {
    const [genderData, setGenderData] = useState({});
    const [statusData, setStatusData] = useState({});
    const [labels, setLabels] = useState([]);
    const [statusLabels, setStatusLabels] = useState([]);
    const [showGraph, setShowGraph] =  useState(false);
    useEffect(() => {
        loadSchemeMemberStatusSummary();
    }, []);


const loadSchemeMemberStatusSummary = () => {
    schemeService.getSchemeMemberStatusSummary().then(
        (data) => {
            console.log(data);
            let labels = data.map((item) => item.scheme.shortname);
            console.log(statusLabels);
            let graph_data = {
                labels,
                datasets: [
                  {
                    label: 'Active',
                    data: data.map((item) => item.summary.active),
                    backgroundColor: 'rgba(53, 160, 100, 0.9)',
                  },
                  {
                    label: 'Pending',
                    data: data.map((item) => item.summary.pending),
                    backgroundColor: 'rgba(53, 162, 235, 0.9)',
                  },
                  {
                    label: 'Suspended',
                    data: data.map((item) => item.summary.suspended),
                    backgroundColor: 'rgba(255, 99, 132, 0.9)',
                  },
                ],
              }
              console.log(graph_data);
            setStatusData(graph_data);
            console.log(Object.keys(statusData).length);
        }
    )
     
}

  return (
    <div>
        <div style={{minHeight:600, overFlow:'scroll', maxWidth:1000}}>
        {Object.keys(statusData).length > 0 &&  <BarGraph data={statusData} title="Member Status" key={1} />}
        </div>
    </div>
  )
}
