import { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import productService from "../../services/product/product-service";
import subscriptionService from "../../services/subscription/subscription-service";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
import AuthDetailContext from "../common/AuthDetailContext";
const Product = ({ match }) => {
    const currentUser = useContext(AuthDetailContext);
    const { setAlert } = useContext(AlertContext);
    const { productId, edit } = match.params;
    const [product, setProduct] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [productName, setProductName] = useState("");
    const [productCriteria, setProductCriteria] = useState("");
    const [validated, setValidated] = useState(false);
    const [productBasePremium, setProductBasePremium] = useState("");
    const [productPremium, setProductPremium] = useState(null);
    const [productCopayIpd, setProductCopayIpd] = useState("");
    const [productCopayOpd, setProductCopayOpd] = useState("");
    const [productCeilingIpd, setProductCeilingIpd] = useState("");
    const [productCeilingOpd, setProductCeilingOpd] = useState("");
    const [productCeilingPharm, setProductCeilingPharm] = useState(0.0);
    const [productCeilingReferral, setProductCeilingReferral] = useState(0.0);
    const [productVisitMember, setProductVisitmember] = useState(1);
    const [productVisitFamily, setProductVisitFamily] = useState(1);
    const [productFacilityNumber, setProductFacilityNumber] = useState(1);
    const [productMaxFacilityLevel, setProductMaxFacilityLevel] = useState("");
    const [productChronicRate, setProductChronicRate] = useState(0.0);
    const [productDrugAccess, setProductDrugAccess] = useState("No");
    const [productExclusions, setProductExclusions] = useState("");
    const [productCoverage, setProductCoverage] = useState("");
    const [penaltyFee, setPenaltyFee] = useState(0);
    const history = useHistory();

    useEffect(() => {
        checkForEditInUrl();
        loadProduct(productId);
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadProduct = (productId) => {
        productService.getProduct(productId).then(
            (result) => {
                setProduct(result);
                setProductName(result.name);
                setProductCriteria(result.criteria);
                setProductBasePremium(result.base_premium);
                setProductPremium(result.premium);
                setProductCopayIpd(result.copay_ipd);
                setProductCopayOpd(result.copay_opd);
                setProductCeilingIpd(result.ceiling_ipd);
                setProductCeilingOpd(result.ceiling_opd);
                setProductCeilingPharm(result.ceiling_pharm);
                setProductCeilingReferral(result.ceiling_referral);
                setProductVisitFamily(result.visit_family);
                setProductVisitmember(result.visit_member);
                setProductFacilityNumber(result.facility_no);
                setProductMaxFacilityLevel(result.max_facility_level);
                setProductChronicRate(result.chronic_rate);
                setProductDrugAccess(result.drug_access);
                setProductExclusions(result.exclusions);
                setProductCoverage(result.coverage);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
            }
        );
    };

    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            
            let payload = {
                name: productName,
                criteria: productCriteria,
                base_premium: productBasePremium,
                premium: productPremium,
                copay_opd: productCopayOpd,
                copay_ipd: productCopayIpd,
                ceiling_opd: productCeilingOpd,
                ceiling_ipd: productCeilingIpd,
                ceiling_pharm: productCeilingPharm,
                ceiling_referral: productCeilingReferral,
                visit_member: productVisitMember,
                visit_family: productVisitFamily,
                facility_no: productFacilityNumber,
                max_facility_level: productMaxFacilityLevel,
                chronic_rate: productChronicRate,
                drug_access: productDrugAccess,
                coverage: productCoverage,
                exclusions: productExclusions,
                penalty_fee:penaltyFee,
                // owner: currentUser.id,
            };
            productService.updateProduct(payload, productId).then(
                (result) => {

                    loadProduct(productId);
                    setAlert("Product Successfully updated!", "success");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error)
                    setAlert(resMessage, "error");
                }
            );
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            // setEdit(true);
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/products"> Products </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>
                        {product ? product.name : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">
                            Product:{" "}
                            <strong>{product ? product.name : ""}</strong>
                        </h4>
                        <div>
                            <Button
                                className="float-end"
                                variant="primary"
                                onClick={toggleEdit}
                                size="sm"
                                disabled={!currentUser.is_superuser}
                            >
                                Edit
                            </Button>
                            <Button
                                className="float-end"
                                variant="outline-info"
                                onClick={history.goBack}
                                size="sm"
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        {!product && (
                            <Spinner animation="border" variant="warning" />
                        )}
                        {product && (
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                className="row gx-3 gy-3"
                            >
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={productName}
                                        onChange={(e) =>
                                            setProductName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Criteria</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={productCriteria}
                                        onChange={(e) =>
                                            setProductCriteria(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid criteria.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Base Premium</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        value={productBasePremium}
                                        onChange={(e) =>
                                            setProductBasePremium(
                                                e.target.value
                                            )
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Premium</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        value={productPremium}
                                        onChange={(e) =>
                                            setProductPremium(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Copay OPD</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        value={productCopayOpd}
                                        onChange={(e) =>
                                            setProductCopayOpd(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Copay IPD</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        value={productCopayIpd}
                                        onChange={(e) =>
                                            setProductCopayIpd(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Ceiling IPD</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        value={productCeilingIpd}
                                        onChange={(e) =>
                                            setProductCeilingIpd(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Ceiling OPD</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="number"
                                        value={productCeilingOpd}
                                        onChange={(e) =>
                                            setProductCeilingOpd(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Ceiling Pharmacy</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="0.00"
                                        readOnly={!isEdit}
                                        value={productCeilingPharm}
                                        onChange={(e) =>
                                            setProductCeilingPharm(
                                                e.target.value
                                            )
                                        }
                                    
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>                                        
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Ceiling Referral</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="0.00"
                                        readOnly={!isEdit}
                                        value={productCeilingReferral}
                                        onChange={(e) =>
                                            setProductCeilingReferral(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.PenaltyFee"
                                >
                                    <Form.Label>Penalty Fee</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="100.00"
                                        min="0.00"
                                        readOnly={!isEdit}
                                        value={penaltyFee}
                                        onChange={(e) =>
                                            setPenaltyFee(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Visit Member</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="1"
                                        min="1"
                                        readOnly={!isEdit}
                                        value={productVisitMember}
                                        onChange={(e) =>
                                            setProductVisitmember(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Visit Family</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="1"
                                        min="1"
                                        readOnly={!isEdit}
                                        value={productVisitFamily}
                                        onChange={(e) =>
                                            setProductVisitFamily(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>    
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Facility Number</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="1"
                                        min="1"
                                        readOnly={!isEdit}
                                        value={productFacilityNumber}
                                        onChange={(e) =>
                                            setProductFacilityNumber(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>    
                                </Form.Group>
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Max Facility Level</Form.Label>
                                    <Form.Control
                                        type="text"
                                        readOnly={!isEdit}
                                        value={productMaxFacilityLevel}
                                        onChange={(e) =>
                                            setProductMaxFacilityLevel(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group>  
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Chronic Rate</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="0.00"
                                        readOnly={!isEdit}
                                        value={productChronicRate}
                                        onChange={(e) =>
                                            setProductChronicRate(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid number.
                                    </Form.Control.Feedback>
                                </Form.Group> 
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Drug Access</Form.Label>
                                    <Form.Select
                                        aria-label="Drug Access"
                                        disabled={!isEdit}
                                        value={
                                            productDrugAccess
                                        }
                                        onChange={(
                                            e
                                        ) =>
                                            setProductDrugAccess(
                                                e
                                                    .target
                                                    .value
                                            )
                                        }
                                        required
                                    >
                                        <option value="No">
                                            No
                                        </option>
                                        <option value="Yes">
                                            Yes
                                        </option>
                                    </Form.Select>    
                                </Form.Group>  
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Exclusions</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        readOnly={!isEdit}
                                        value={productExclusions}
                                        onChange={(e) =>
                                            setProductExclusions(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid .
                                    </Form.Control.Feedback>
                                </Form.Group> 
                                <Form.Group
                                className="col-md-3"
                                controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Coverage</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        readOnly={!isEdit}
                                        value={productCoverage}
                                        onChange={(e) =>
                                            setProductCoverage(
                                                e.target.value
                                            )
                                        }
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid .
                                    </Form.Control.Feedback>
                                </Form.Group>         

                                {isEdit && (
                                    <div className="col-12">
                                        <Button variant="primary" type="submit">
                                            Update
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            
          
        </Row>
    );
};
export default Product;
