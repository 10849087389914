import { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
    Pagination,
} from "react-bootstrap";
import helperService from "../../services/helpers/helper-service";
import { Link, useHistory } from "react-router-dom";
import providerService from "../../services/provider/provider-service";
import subscriptionService from "../../services/subscription/subscription-service";
import schemeService from "../../services/scheme/scheme-service";
import productService from "../../services/product/product-service";
import AuthDetailContext from "../common/AuthDetailContext";
import FamilyProviderForm from "./FamilyProviderForm";
import AlertContext from '../widgets/alertPopup/AlertContext';
import { formatHttpErrorMessage, isExpiredDate, formatShortDate, formatDate } from "../common/helper_functions";

const FamilyProviders = (props) => {
    const currentUser = useContext(AuthDetailContext);
    const { setAlert } = useContext(AlertContext);
    
    const {familyId,providerId,familyGuid} = props;    
    const [providers, setProviders] = useState([]);
    const [familyProviders, setFamilyProviders] = useState([]);
    const [products, setProducts] = useState([]);
    const [family,setFamily] = useState({});
    const [offers, setOffers] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [providerList, setProviderList] = useState([]);
    const [showProviderForm, setShowProviderForm] = useState(false);
    

    useEffect(() => {
        //loadFamily();
       // loadOffers();        
        //loadProducts();  
        loadProviderOptions();  
        loadProviders(); 
        setLoading(false);
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

   

   const loadProviders = () => {
        let params = {family:familyGuid}
        subscriptionService.getFamilyProviders(params).then(
            (payload) => {
                console.log(payload);
                //setFamilyProviders(payload.data.results);
                let tempProviders = [];
                setProviders(payload.data.results);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                    setAlert("An error Occurred!" + resMessage, "danger");
            }
        );
   }

    const loadOffers = () => {
        let fGuid = familyGuid !== undefined ? familyGuid : "";
        let provider = providerId !== undefined ? providerId : "";
        if (fGuid == 0) fGuid = "";
        subscriptionService.filterOffers(fGuid, provider).then(
            (payload) => {
                setOffers(payload);
                if(payload.length > 0)
                {
                    payload.forEach(p =>(
                        loadProviders(p.guid)
                    ))
                    
                }
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);

                setAlert(resMessage, "danger");
              
                setLoading(false);
            }
        );
    };

    const loadFamily = () => {
        schemeService.getFamily(familyId).then(
            (result) => {
                setFamily(result)
            },
            (error) => {

            }
        )
    }

  
  
    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to detach ");
        if (result === true) {
            //get id 
            const fpid = familyProviders.find(fp => fp.provider === id)
            if(id !== undefined)
            {
                subscriptionService.detachProvider(id).then(
                    () => {
                        //loadOffers();
                        //loadProviderOptions();
                        loadProviders(); 
                        setAlert("Successfully Detached provider from family");
                    },
                    (error) => {
                        const resMessage = formatHttpErrorMessage(error);
                        setAlert(resMessage, "danger")
                    }
                );
                
            }
            else {
                
                setAlert("Unable to get provider", "danger");
                setLoading(false);
            }
            
        } else {
        }
    };

     const loadProviderOptions = async () => {
        //get values based on client type chosen
      let item = await providerService.getAllProviders().then((response) => {
          setFamilyProviders(response.results);
          setProviderList(response.results);
        });
    }; 

    
    const onCreateSuccess = () => {
        loadOffers();
        loadProviderOptions();
        setShowProviderForm(false);
        loadProviders();
        setAlert("A new Facility has been added to the Family", "Success");
    }

    return (
        <Row className="g-3">            
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        
                        <div>    
                        {currentUser && (currentUser['is_superuser'] || currentUser['type'] !='Provider') && (<>                        
                            
                            <Button variant="success" onClick={() => setShowProviderForm(true)}>Register Facility</Button>
                            <FamilyProviderForm fGuid={familyGuid}  show={showProviderForm} onHide={() => { setShowProviderForm(false);
                                }} onClose={() => onCreateSuccess()}  />
                            </>)}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Card.Title>Family Providers </Card.Title>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Level</th>
                                        <th>Ownership</th>
                                        <th>Location</th>
                                        <th>Choice</th>
                                        <th>Coverage</th>
                                        <th>opd Ceiling</th>
                                        <th>IPD Ceiling</th>
                                        <th>Status</th>
                                       
                                        <th>Expires On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <Spinner
                                            animation="border"
                                            variant="warning"
                                        />
                                    )}
                                    {
                                    providers.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.provider.name}</td>
                                            <td>{item.provider.level}</td>
                                            <td>{item.provider.ownership}</td>
                                            <td>{item.provider.subcounty},{item.provider.district} </td>
                                            <td>{item.level}</td>
                                            <td>{item.min_cover}</td>
                                            <td>{item.ceiling_opd}</td>
                                            <td>{item.ceiling_ipd}</td>
                                            <td>{isExpiredDate(item.offer.end_date) ?'Expired':'Active'}</td>
                                             
                                            <td><strong>{formatDate(item.offer.end_date)}</strong></td>
                                            <td>
                                                
                                                <div>
                                                <ButtonGroup
                                                    aria-label="Actions"
                                                    size="sm"
                                                >
                                                   
                                                    <Button
                                                        variant="danger"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                item.id
                                                            );
                                                        }}
                                                        disabled={isExpiredDate(item.offer.end_date)}
                                                    >
                                                        Detach
                                                    </Button>
                                                </ButtonGroup>
                                                </div>
                                                
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                        
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};
export default FamilyProviders;
