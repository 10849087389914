import { Row, Col, Badge } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import schemeService from "../services/scheme/scheme-service";
import fundService from "../services/fund/fund-service";
import subscriptionService from "../services/subscription/subscription-service";
import providerService from "../services/provider/provider-service";
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from "react-router-dom";
import AuthDetailContext from "./common/AuthDetailContext";
import AdminDashboard from "./AdminDashboard";
import ProviderDashboard from "./ProviderDashboard";
import StaffDashboard from "./StaffDashboard";

const Dashboard = () => {
    const  currentUser = useContext(AuthDetailContext);

    return (
        <>
            <Row>
            <Col xs="12">
                <h5 className="mt-3">Hello {currentUser && currentUser['username']} <small><Badge pill>{currentUser['type']}</Badge></small></h5>
            </Col>
            </Row>
            
            {currentUser && currentUser['is_staff'] && <AdminDashboard />}
            {currentUser && (currentUser['type'] == 'Org' || currentUser['type'] == 'Sales') && <StaffDashboard />}
            {currentUser && (currentUser['type'] == 'Provider' || currentUser['type'] == 'ProviderFinance') && <ProviderDashboard />}
            
        </>
    );
};
export default Dashboard;
