import axios from "axios";
import authHeader from "../auth/auth-header";
import {API_URL} from "../constants";
import axiosAPI from "../axiosApi";
// For expired token
axios.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 200) {
          //console.log('Posted Successfully');
       }
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl); 
        }
    }
 );

export function getAllInvoices(params={}){
    return axios.get(API_URL + `providers/invoice/`, {params:params, headers: authHeader() });
}

export function getInvoiceById(id){
    return axios.get(API_URL + `providers/invoice/${id}/`, {headers: authHeader() });
}

export function submitInvoice(id){
    return axios.get(API_URL + `providers/invoice/${id}/submit_invoice/`, {headers: authHeader() });
}


export function markAsPaid(id){
    return axios.get(API_URL + `providers/invoice/${id}/mark_as_paid/`, {headers: authHeader() });
}

export function markAsRejected(id){
    return axios.get(API_URL + `providers/invoice/${id}/mark_as_rejected/`, {headers: authHeader() });
}

export function getInvoiceClaims(id){
    return axios.get(API_URL + `providers/invoice/${id}/group_claims/`, {headers: authHeader() });
}

export function createInvoiceEntry(data){
    return axios.post(API_URL + `providers/invoice/`, data ,{headers: authHeader() });
}

export function createInvoiceAdmission(data){
    return axiosAPI.post(API_URL + `providers/invoice/invoice_admission_claims/`, data);
}

export function updateInvoiceEntry(id, data){
    return axios.post(API_URL + `providers/invoice/${id}/`, data ,{headers: authHeader() });
}

export async function deleteInvoiceEntry(id) {
    return await axiosAPI.delete(API_URL + `providers/invoice/${id}/`);
}

export function deleteClaimAttachment(id) {
    return axiosAPI.delete(API_URL + `providers/invoice-attachments/${id}/`);
}