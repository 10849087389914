import axios from "axios";
import authHeader from "../auth/auth-header";
import { API_URL } from "../constants";

// For expired token
axios.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 200) {
          //console.log('Posted Successfully');
       }
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl); 
        }
    }
 );

const getAllProducts = () => {
    return axios
        .get(API_URL + "products/product/", { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};
const getProduct = (id) => {
    return axios
        .get(API_URL + `products/product/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};
const getAllServices = () => {
    return axios
        .get(API_URL + "products/service/", { headers: authHeader() })
        .then((response) => {
            return response.data.results;
        });
};

const addProduct = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "products/product/", {
            name: payload.name,
            criteria: payload.criteria,
            base_premium: payload.base_premium,
            premium: payload.premium,
            copay_opd: payload.copay_opd,
            copay_ipd: payload.copay_ipd,
            ceiling_opd: payload.ceiling_opd,
            ceiling_ipd: payload.ceiling_ipd,
            owner: payload.owner,
        })
        .then((response) => {
            return response.data;
        });
};
const addService = (payload) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .post(API_URL + "products/service/", {
            name: payload.name,
            product: payload.product,
            owner: payload.owner,
        })
        .then((response) => {
            return response.data;
        });
};

const updateProduct = (payload, id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.access;
    return axios
        .put(API_URL + `products/product/${id}`, {
            name: payload.name,
            criteria: payload.criteria,
            base_premium: payload.base_premium,
            premium: payload.premium,
            copay_opd: payload.copay_opd,
            copay_ipd: payload.copay_ipd,
            ceiling_opd: payload.ceiling_opd,
            ceiling_ipd: payload.ceiling_ipd,
            //owner: payload.owner,
        })
        .then((response) => {
            return response.data;
        });
};

const deleteProduct = (id) => {
    return axios
        .delete(API_URL + `products/product/${id}/`, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

export default {
    getAllProducts,
    getProduct,
    getAllServices,
    addProduct,
    addService,
    updateProduct,
    deleteProduct,
};
