import { useContext, useEffect, useState } from "react";
import {
	Grid,
	Button,
	ButtonGroup,
	Card,
	Breadcrumb,
	Toast,
	Table,
	Image,
	Row,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Spinner,
	Tabs,
	Tab
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import moment from "moment";
import schemeService from "../../services/scheme/scheme-service";
import { Camera, CloudPlus, Pencil, ShieldCheck, ShieldFill, ShieldFillX, StackOverflow } from "react-bootstrap-icons";
import MemberEditForm from "./MemberEditForm";
import MemberPhotoForm from "./MemberPhotoForm";
import AuthDetailContext from "../common/AuthDetailContext";
import ClaimForm from "../providers/ClaimForm";
import subscriptionService from "../../services/subscription/subscription-service";
import productService from "../../services/product/product-service";
import ClaimService from '../../services/provider/claims-service';
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage,getDayApart } from "../common/helper_functions";
import BadgeWidget from "../widgets/BadgeWidget";

const Member = ({ match }) => {
	const currentUser = useContext(AuthDetailContext);
	const {setAlert} = useContext(AlertContext);
	const { memberId, edit } = match.params;
	const [member, setMember] = useState(null);
	const [product, setProduct] = useState();
	const [policy, setPolicy] = useState();
	const [isEdit, setEdit] = useState(false);
	const [ToastMessage, setToastMessage] = useState("");
	const [showToast, setShowToast] = useState(false);
	const [toastVariant, setToastVariant] = useState("light");
	const [claims, setClaims] = useState([]);
	const [showMemberEditForm, setShowMemberEditForm] = useState(false);
	const [showMemberPhotoForm, setShowMemberPhotoForm] = useState(false);
	const [showClaimForm, setShowClaimForm] = useState(false);
	const [providerSettings, setProviderSettings] = useState();
	const history = useHistory();

	useEffect(() => {
		checkForEditInUrl();
		loadMember(memberId);
		
	}, [memberId]);

	const toggleEdit = () => {
		setEdit(!isEdit);
	};

	const loadMember = (memberId) => {
		schemeService.getMember(memberId).then(
			(response) => {
				
				setMember(response.data);
				fetchAllClaims(response.data.guid);
				if(response.data.status == 'Active'){
					loadMemberPolicy(response.data.family.id);
					fetchProviderSettings(response.data.family.guid)
				}
				
			}
		).catch(error =>{
			const resMessage = formatHttpErrorMessage(error);
				setAlert(resMessage,"danger");
		});
		
	};

	const fetchAllClaims = async (memberId) => {
		let params = {member:memberId};
        await ClaimService.getAllClaims(params).then(response => setClaims(response.data.results)).catch((err) =>{
			
			let message = formatHttpErrorMessage(err)
			setAlert("Claims Error: " + message, "danger")
		});
        
    }

	const fetchProviderSettings = async (familyId) => {
		await subscriptionService.getFamilyProviders({family:familyId}).then(response => {
			
			setProviderSettings(response.data.results)
		})
	}

	const loadMemberPolicy = (familyId) => {
		console.log("Loading policy");
		subscriptionService.getAllPolicies({family:familyId}).then(response => {
				if(response.data.results.length){
					setPolicy(response.data.results[0]);
					loadMemberProduct(response.data.results[0].product);
				}
				
		}).catch((err) =>{
			console.log(err)
			let message = formatHttpErrorMessage(err)
			setAlert(message, "danger")
		});
	}

	const loadMemberProduct = (id) => {
		productService.getProduct(id).then(data => setProduct(data));
	}
	
		
	const checkForEditInUrl = () => {
		if (window.location.href.indexOf("edit") > -1) {
			setEdit(true);
		}
	};

	const suspendMember = () => {
    if (member.status == "Active") {
      if (
        window.confirm("Are you sure you want to suspend this Beneficiary?")
      ) {
        schemeService
          .setMemberStatus(memberId, "suspend")
          .then((response) => {
            setAlert("Member has been suspended!");
          })
          .catch((error) => {
            setAlert(
              "Something went wrong, please contact the administrator",
              "danger"
            );
          });
      }
    } else {
      if (
        window.confirm("Are you sure you want to Activate this Beneficiary?")
      ) {
        schemeService
          .setMemberStatus(memberId, "activate")
          .then((response) => {
            setAlert("Member has been Activated!");
          })
          .catch((error) => {
            setAlert(
              "Something went wrong, please contact the administrator",
              "danger"
            );
          });
      }
    }
    loadMember(memberId);
  };

	const onCloseMemberEditForm = () => {
		loadMember(memberId);
		setShowMemberEditForm(false);
	}

	const onCloseMemberPhotoForm = () => {
		loadMember(memberId);
		setShowMemberPhotoForm(false);
	}

	const onCloseClaimForm = () => {
		setShowClaimForm(false);
	}

	return (
		<>
		<Row className="g-1">
			<Col xs="12" className="pt-3">
				<h4>Member Details</h4>
				<Breadcrumb className="border-bottom mb-3">
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/members"> Beneficiary </Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{member ? member.firstname : ""}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
		</Row>
		
		
			{member && <>
		<Row className="g-1 mb-2">
				<Col xs={2} >
							<Button
								variant="outline-info"
								onClick={history.goBack}
							>
								<FiArrowLeft></FiArrowLeft>
							</Button>
			 </Col>
				<Col xs={10} className="text-end">
				{currentUser && (currentUser['is_superuser'] || ["Org", "Provider"].includes(currentUser.type)) && policy && <>
					<Button onClick={() => setShowClaimForm(true)} disabled={member.status !== 'Active'}><CloudPlus /> Add Claim</Button>&nbsp;&nbsp;
					<ClaimForm memberGuid={member.guid} policy={policy} facility={currentUser.ref_id} show={showClaimForm} onHide={() => setShowClaimForm(false)} onClose={onCloseClaimForm} />
					</>
				}
				
					{currentUser && (currentUser['is_superuser'] || ["Org", "Clerk","Sales"].includes(currentUser.type)) && (<>
									<Button variant="success" onClick={() => setShowMemberEditForm(true)}>
										<Pencil /> Edit
									</Button>&nbsp;&nbsp;

									{member.status =='Active' && <Button variant={member.status=='Active' ? 'danger':'info'} onClick={() => suspendMember()}>
										<ShieldFillX /> Suspend
									</Button>}
									{member && member.status =='Suspended' &&  <Button variant={member.status=='Active' ? 'danger':'info'} onClick={() => suspendMember()}>
										<ShieldCheck /> Activate
									</Button>}
								{showMemberEditForm && <MemberEditForm memberId={member.id} show={showMemberEditForm} onHide={() => setShowMemberEditForm(false)} onClose={onCloseMemberEditForm} />}
								</>)}
				</Col>
			</Row>{/**button row */}
			<Row className="g-2">
			<Col sm={4} md={3} lg={3}>
				<Card>
				{member.photo && <Image
											fluid
											src={member.photo}
											
										></Image>}
					<Card.Body>
					{currentUser && (currentUser['is_superuser'] || ["Org", "Clerk","Sales"].includes(currentUser.type)) && (<>
						<Button onClick={()=>setShowMemberPhotoForm(true)}>
							<Camera /> Change Photo
						</Button>
						{member && showMemberPhotoForm && <MemberPhotoForm memberId={member.id} subscheme={member.family.subscheme} onClose={onCloseMemberPhotoForm} show={showMemberPhotoForm} onHide={()=>setShowMemberPhotoForm(false)} />}
						</>)}
					</Card.Body>
				</Card>
			</Col>
			<Col sm={8} md={9} lg={9}>
			
				<Card className="mb-3">
					<Card.Body>
						
						{!member && (
							<Spinner animation="border" variant="warning" />
						)}
						
						<Row>
							<Col md={10}>
								<Card.Title>{member.firstname} {member.othername} {member.lastname}</Card.Title>
							</Col>
							<Col md={12}>
								<Row>
								<Col md={4}>
									<Table>
										<tbody>
										<tr>
												<th>Code</th>
												<td>{member.code}</td>
											</tr>
											<tr>
												<th>Gender</th>
												<td>{member.gender}</td>
											</tr>
											
											<tr>
												<th>Relationship</th>
												<td>{member.relationship}</td>
											</tr>
											<tr>
												<th>Scheme</th>
												<td><Link to={currentUser['is_superuser'] ? `/sub-schemes/${member.family.subscheme}`:"#"}
													
												>{member.family.subscheme_shortname}</Link></td>
											</tr>
										</tbody>
									</Table>
								</Col>
								<Col md={4}>
								<Table>
										<tbody>
											<tr>
												<th>Date of Birth</th>
												<td>{member.birthdate}</td>
											</tr>
											<tr>
												<th>Phone Number</th>
												<td>{member.phone}</td>
											</tr>
											<tr>
												<th>Identification</th>
												<td>{member.nin}</td>
											</tr>
											<tr>
												<th>Village</th>
												<td>{member.family.village}</td>
											</tr>
										</tbody>
									</Table>
								</Col>
								<Col md={4}>
								<Table>
										<tbody>
										{currentUser && (currentUser['is_superuser'] || ["Org", "Clerk","Sales"].includes(currentUser.type)) && (<>
											<tr>
												<th>Family</th>
												<td><Link to={`/family/${member.family.id}`}>{member.family.name}</Link></td>
											</tr>
											</>)}
											<tr>
												<th>Status</th>
												<td><BadgeWidget text={member.status} /></td>
											</tr>
											<tr>
												<th>Joined</th>
												<td>{member.joined_on}</td>
											</tr>
											<tr>
												<th>Updated</th>
												<td>{moment(member.updated).format("LLL")}</td>
											</tr>
										</tbody>
									</Table>
								</Col>
								</Row>
							</Col>
						</Row>
						
					</Card.Body>
				</Card>
				<Card className="mb-3">
					<Card.Body>
					<Tabs defaultActiveKey="policy" id="uncontrolled-tab-example" className="mb-3">
					<Tab eventKey="policy" title="Policy">
						{providerSettings &&  providerSettings.length && <>
						<h5>Provider Policy Settings</h5>
							<Table>
										<tbody>
										<tr>
												<th>Level</th>
												<td>{providerSettings[0].level}</td>
											</tr>
										<tr>
												<th>Ceiling InPatient</th>
												<td>{providerSettings[0].ceiling_ipd}</td>
											</tr>
											<tr>
												<th>ceiling OutPatient</th>
												<td>{providerSettings[0].ceiling_opd}</td>
											</tr>
											
											<tr>
												<th>Co-pay InPatient</th>
												<td>{providerSettings[0].min_cover}</td>
											</tr>
											<tr>
												<th>Copay OutPatient</th>
												<td>{providerSettings[0].min_cover}</td>
											</tr>
											<tr>
												
												<td colSpan="2">
												<h6>Exclusions</h6>
													{providerSettings[0].exclusions}</td>
											</tr>
										</tbody>
									</Table>
						</>}
						{policy && providerSettings===undefined && <>
							<Table>
										<tbody>
										
										<tr>
												<th>Ceiling InPatient</th>
												<td>{policy.ceiling_ipd}</td>
											</tr>
											<tr>
												<th>ceiling OutPatient</th>
												<td>{policy.ceiling_opd}</td>
											</tr>
											
											<tr>
												<th>Co-pay InPatient</th>
												<td>{policy.copay_ipd}</td>
											</tr>
											<tr>
												<th>Copay OutPatient</th>
												<td>{policy.copay_opd}</td>
											</tr>
											
										</tbody>
									</Table>
						</>}
					</Tab>
					<Tab eventKey="product" title="Product">
						{product && <><h4>Product: {product.name}</h4>
						<Row>
							<Col md={6}>
								<h6>Exclusions</h6>
								{product.exclusions}
							</Col>
							<Col md={6}>
								<h6>coverage</h6>
								{product.coverage}
							</Col>
						</Row>
						</>}
					</Tab>
					<Tab eventKey="Claims" title="Member Claims">
						<h4>Claims</h4>
						<Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Amount</th>
                                <th>Visit</th>
                                <th>Status</th>
                                <th>Admission</th>
                                <th>Stay</th>
                                <th>Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {claims && claims.map((item, i) => (
                                <tr key={i+1}>
                                <td><Link to={`/claims/${item.id}`}>{i + 1}</Link></td>
                                <td>{item.amount}</td>
                                <td>{item.visit}</td>
                                <td>{item.status}</td>
                                <td>{item.admission_date}</td>
                                <td>{getDayApart(item.admission_date, item.discharge_date)}</td>
                                <td>{moment(item.created).format('lll')}</td>
                            </tr>
                            ))}
                            
                        </tbody>
                    </Table>
					</Tab>
					</Tabs>
					</Card.Body>
				</Card>
			</Col></Row>
			</>}
			
		
		</>);
};
export default Member;
