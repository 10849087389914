import React from 'react'
import { Pagination } from 'react-bootstrap';

function Pager(props) {
    const {numberOfPages, activePage, goToPage} =props;
    let pagesCutCount = 9;
    const getDisplayPages = () => {
        const ceiling = Math.ceil(pagesCutCount / 2);
        const floor = Math.floor(pagesCutCount / 2);
      
        if (numberOfPages < pagesCutCount) {
          return { start: 1, end: numberOfPages + 1 };
        } else if (activePage >= 1 && activePage <= ceiling) {
          return { start: 1, end: pagesCutCount + 1 };
        } else if (activePage + floor >= numberOfPages) {
          return { start: numberOfPages - pagesCutCount + 1, end: numberOfPages };
        } else {
          return { start: activePage - ceiling + 1, end: activePage + floor + 1 };
        }
    }
    let page = getDisplayPages()
    
  return (
    <div>
      <p>
        <em>{activePage}/{numberOfPages} pages</em>
      </p>
      <Pagination>
      <Pagination.Item
                onClick={(e) => {
                  goToPage(e, activePage - 1);
                }}
                key={`previous`}
                disabled={1 === activePage}
              >
                Previous
              </Pagination.Item>
      <Pagination.Item
                onClick={(e) => {
                  goToPage(e, 1);
                }}
                key={`first`}
                disabled={1 === activePage}
              >
                First
              </Pagination.Item>
     

        {(() => {
          let items = [];
          for (let number = page.start; number <= page.end; number++) {
            items.push(
              <Pagination.Item
                onClick={(e) => {
                  goToPage(e, number);
                }}
                key={number}
                active={number === activePage}
              >
                {number}
              </Pagination.Item>
            );
          }
          return items;
        })()}
         <Pagination.Item
                onClick={(e) => {
                  goToPage(e, numberOfPages);
                }}
                key={`last`}
                disabled={numberOfPages === activePage}
              >
                Last
              </Pagination.Item>
        <Pagination.Item
                onClick={(e) => {
                  goToPage(e, activePage + 1);
                }}
                key={`next`}
                disabled={numberOfPages === activePage}
              >
                Next
              </Pagination.Item>
      </Pagination>
    </div>
  );
}

export default Pager