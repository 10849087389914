import axiosAPI from "../axiosApi";

export async function createScheme(payload) {
    const response = await axiosAPI.post("/schemes/scheme/", payload);
    return response;
  }

export async function fetchSchemes(params = {}) {
  const response = await axiosAPI.get("/schemes/scheme/", { params: params });
  return response;
}

export async function fetchSchemeById(id) {
  const response = await axiosAPI.get(`/schemes/scheme/${id}/`);
  return response;
}

export async function updateSchemeById(id, payload) {
  const response = await axiosAPI.put(`/schemes/scheme/${id}/`, payload);
  return response;
}


export async function deleteSchemeById(id) {
    const response = await axiosAPI.delete(`/schemes/scheme/${id}/`);
    return response;
}