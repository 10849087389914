export function formatHttpErrorMessage(error) {
  // console.log("error is ", error)

  if (!error.response) {
    let msg =""
    if(error.response?.data){
      for (const [key, value] of Object.entries(error.response?.data)) {
        msg = `${key}: ${value} `;
      }
    }
    return "Something went wrong! Please make sure you have the correct credentials for this action" + msg;
  }

  if (error.response.status === 400) {
    let result = "";
    Object.keys(error.response.data).forEach((i) => {
      if(i =='message' || i=='detail'){
        result += `${error.response.data[i]},\n`;
      }else{
        result += `[${i}] ${error.response.data[i]},\n`;
      }
      
    });
    return result;
  } else if (error.response.status === 401) {
    window.location.replace("/", false);
    return "401: Invalid login tokens were provided.";
  } else if (error.response.status === 403) {
    let msg = "403: You do not have permission to perform this action."
    if(error.response?.data){
      for (const [key, value] of Object.entries(error.response?.data)) {
        msg = `${key}: ${value} `;
      }
    }
    return msg;
  } else if (error.response.status == 404 || error.response.status == 405 ) {
    let msg = "404: Resource not Found!"
    if(error.response?.data){
      for (const [key, value] of Object.entries(error.response?.data)) {
        msg = `${key}: ${value} `;
      }
    }
    return msg;
  } else if (error.response.status === 500) {
    let msg = "We experience an difficuly in processing you request with error 500."
    if(error.response?.data){
      for (const [key, value] of Object.entries(error.response?.data)) {
        msg = `${key}: ${value} `;
      }
    }
    return msg;
  } else {
    return "Could not reach the server! Please check your Internet Connection.";
  }
}

export function getFormValues(event) {
  const data = new FormData(event.currentTarget);
  return Object.fromEntries(data.entries());
}

export const getDayApart = (admission, discharge) => {
  var date1 = new Date(admission);
  var date2 = new Date(discharge);
  // To calculate the time difference of two dates
  var difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);

  return difference_In_Days + 1;
};

export function formatNumber(number){
  return parseInt(number).toLocaleString(undefined, {maximumFractionDigits:2})
}

export function isAdmin(){
   return true
}

export const isExpiredDate = (d1) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date().getTime();
  return date1 <= date2
}



export function formatShortDate(date) {
  const options = {
    year: "numeric", month: "short", day: "numeric",hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
    const intDateFormat = new Intl.DateTimeFormat("en-GB", options);
    return intDateFormat.format(new Date(date));
}

export function formatDate(date) {
  const options = {
    year: "numeric", month: "short", day: "numeric",
    hour12: true
  };
    const intDateFormat = new Intl.DateTimeFormat("en-GB", options);
    return intDateFormat.format(new Date(date));
}


export function humanFileSize(size) {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}