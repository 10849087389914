import React from 'react'
import { Breadcrumb, Card, Col, Row, Table } from 'react-bootstrap'
import ClaimsList from './ClaimsList'

function ClaimsPage() {
  return (
    <>
        <Row>
        <Col xs="12" className="pt-3">
            <h4 className="m-0">Claims</h4>
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Claims</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
         <Col xs={12}>
            <ClaimsList />
             
         </Col>
        </Row>
    </>
  )
}

export default ClaimsPage