import { useEffect, useState } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import productService from "../../services/product/product-service";
const Services = ({ currentUser }) => {
    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [productName, setProductName] = useState("");
    const [serviceProduct, setServiceProduct] = useState("");
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        loadProducts();
        loadServices();
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const loadProducts = () => {
        productService.getAllProducts().then(
            (payload) => {
                setProducts(payload);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };
    const loadServices = () => {
        productService.getAllServices().then(
            (payload) => {
                setServices(payload);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            productService.deleteProduct(id).then(
                () => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully Deleted Product service");
                    loadServices();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        } else {
        }
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                name: productName,
                product: serviceProduct,
                owner: currentUser.id,
            };
            productService.addService(payload).then(
                (result) => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully created product service");
                    setProductName("");
                    setServiceProduct("");
                    setShowForm(false);
                    loadServices();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/products"> Products</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Services</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="col-12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">Services</h4>
                        <div>
                            <Button
                                variant="primary"
                                onClick={toggleForm}
                                size="sm"
                            >
                                Add
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {showForm && (
                <Col xs="col-12">
                    <Card>
                        <Card.Body>
                            <Card.Subtitle className="mb-3">
                                Add new Service
                            </Card.Subtitle>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="g-3">
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="product Name"
                                                value={productName}
                                                onChange={(e) =>
                                                    setProductName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name .
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Product"
                                        >
                                            <Form.Select
                                                aria-label="select Product"
                                                value={serviceProduct}
                                                onChange={(e) =>
                                                    setServiceProduct(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {products.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select product.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col xs="12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            )}
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Card.Title>Current Services </Card.Title>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Product</th>

                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {services.map((service, index) => (
                                        <tr>
                                            <td>{service.id}</td>
                                            <td>{service.name}</td>
                                            <td>
                                                {products.find(
                                                    ({ id }) =>
                                                        id === service.product
                                                )?.name ?? "loading.."}
                                            </td>
                                            <td>
                                                <ButtonGroup
                                                    aria-label="Actions"
                                                    size="sm"
                                                >
                                                    <Button variant="secondary">
                                                        View
                                                    </Button>
                                                    <Button variant="warning">
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                service.id
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default Services;
