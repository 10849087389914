import { useQuery } from '@tanstack/react-query';
import React from 'react'
import fundService from '../../../services/fund/fund-service';
import { Alert, Spinner, Table } from 'react-bootstrap';
import moment from 'moment';

function FundSplitsList(props) {
  let {id} = props;
  const { isLoading, isError, error, data:splits, isFetching, isPreviousData } =
  useQuery({
    queryKey: ["fundsplits", id],
    queryFn: () => fundService.getAllSplits({fund:id}),
    keepPreviousData: true
  });
  return (
    <div>
       {isLoading ? (
        <Spinner />
          ) : isError ? (
            <Alert variant='danger'>Error: {error.message}</Alert>
          ) : (<>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Narration</th>
                  <th>Lot</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {splits.data.results.map((item, i) =>(
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.amount}</td>
                    <td>{item.narration}</td>
                    <td>{item.lot}</td>
                    <td>{moment(item.created).format("lll")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>)}
    </div>
  )
}

export default FundSplitsList