import { useEffect, useState } from "react";
import {
    Grid,
    Button,
    Form,
    Spinner,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import userService from "../../services/auth/user-service";
const Profile = ({ currentUser, match }) => {
    const { userId, edit } = match.params;
    const [user, setUser] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [userUsername, setUserUsername] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [validated, setValidated] = useState(false);
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userType, setUserType] = useState("Client");
    const [userPassword, setUserPassword] = useState("");
    const [userPasswordRepeat, setUserPasswordRepeat] = useState("");
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [userIsStaff, setUserIsStaff] = useState(false);

    useEffect(() => {
        checkForEditInUrl();
        loadUser();
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadUser = () => {
        userService.getProfile().then(
            (result) => {
                setUser(result);
                setUserEmail(result.email);
                setUserFirstName(result.first_name);
                setUserLastName(result.last_name);
                setUserIsAdmin(result.is_superuser);
                setUserIsStaff(result.is_staff);
                setUserUsername(result.username);
                setUserType(result.type);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            userService.deleteUser(id).then(
                () => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully Deleted scheme");
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        } else {
        }
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                username: userUsername,
                email: userEmail,
                first_name: userFirstName,
                last_name: userLastName,
                is_staff: userIsStaff,
                is_superuser: userIsAdmin,
                password: userPassword,
                password1: userPasswordRepeat,
                type: userType,
            };
            userService.updateUser(payload, currentUser.id).then(
                (result) => {
                    setToastVariant("light");

                    loadUser();
                    setShowToast(true);
                    setToastMessage("Successfully updated user");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.detail) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                }
            );
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            setEdit(true);
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {user ? user.username : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">
                            Profile for:{" "}
                            <strong className="text-capitalize">
                                {user ? user.username : ""}
                            </strong>
                        </h4>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" className="offset-md-3">
                <Card>
                    <Card.Body>
                        {!user && (
                            <Spinner animation="border" variant="warning" />
                        )}
                        {user && (
                            <Form className="row gx-3 gy-2">
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={userUsername}
                                        onChange={(e) =>
                                            setUserUsername(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Firstname</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={userFirstName}
                                        onChange={(e) =>
                                            setUserFirstName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Lastname</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="text"
                                        value={userLastName}
                                        onChange={(e) =>
                                            setUserLastName(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="email"
                                        value={userEmail}
                                        onChange={(e) =>
                                            setUserEmail(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    className="col-md-6"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>User Type</Form.Label>
                                    <Form.Select
                                        aria-label="select Sub county"
                                        disabled={!isEdit}
                                        value={userType}
                                        onChange={(e) =>
                                            setUserType(e.target.value)
                                        }
                                        required
                                    >
                                        <option value="Client">Client</option>
                                        <option value="Group">Group</option>
                                        <option value="Provider">
                                            Provider
                                        </option>
                                        <option value="Org">
                                            Organisation
                                        </option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {isEdit && (
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        size="sm"
                                    >
                                        Update
                                    </Button>
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" className="offset-md-3">
                <Card>
                    <Card.Body>
                        <Card.Subtitle className="mb-2">
                            Change Password
                        </Card.Subtitle>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            className="row g-3"
                        >
                            <Form.Group
                                className="col-md-6"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    value={userPassword}
                                    onChange={(e) =>
                                        setUserPassword(e.target.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter password
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="col-md-6"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Repeat Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    value={userPasswordRepeat}
                                    onChange={(e) =>
                                        setUserPasswordRepeat(e.target.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please match password
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="col-12">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    size="sm"
                                >
                                    Update
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default Profile;
