import axios from "axios";
import authHeader from "../auth/auth-header";
import {API_URL} from "../constants";

// For expired token
axios.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 200) {
          //console.log('Posted Successfully');
       }
       return res;
    },
    (error) => {
        const resMessage =
        (error.response &&
            error.response.data &&
            error.response.data.detail) ||
        error.message ||
        error.toString();
        if(resMessage.includes("token not valid") )
        {
            console.log("token expired ooo")
            localStorage.removeItem("basicauth");
            localStorage.removeItem("user");
            const currentUrl = window.location.href;
            const urlBits = currentUrl.split("/");
            const loginUrl = urlBits[0]+"//"+urlBits[2]+"/login"          
            window.location.replace(loginUrl); 
        }
    }
 );

export function getAllInvoicePayments(params={}){
    return axios.get(API_URL + `providers/payments/`, {params:params, headers: authHeader() });
}

export function getInvoicePaymentById(id){
    return axios.get(API_URL + `providers/payments/${id}/`, {headers: authHeader() });
}


export function createInvoicePayment(data){
    return axios.post(API_URL + `providers/payments/`, data ,{headers: authHeader() });
}

export function updateInvoicePayment(id, data){
    return axios.post(API_URL + `providers/payments/${id}`, data ,{headers: authHeader() });
}