import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import subscriptionService from '../../services/subscription/subscription-service';
import Pager from '../common/Pager';

function ProviderFamilyList() {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    let entriesPerPage = 50

    useEffect(()=>{
        getSelectedProviderSubscriptions();
    },[]);

    const getSelectedProviderSubscriptions = (params={}) => {
        subscriptionService.getAllProviderSubscriptions(params).then((resp) => {
            setSubscriptions(resp.data.results);
            setNumberOfPages(Math.floor(resp.data.count / entriesPerPage))
        }).catch(err =>{
            console.log(err);
        });
        setLoading(false);
    }


    function getFormValues(event) {
        const data = new FormData(event.currentTarget);
        return Object.fromEntries(data.entries());
      }

    const handleSearch = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        var nextPage = 1;
        setLoading(true);
        const params = getFormValues(event);
        getSelectedProviderSubscriptions(params);
        setLoading(false);
      };

      const goToPage = (event, page) => {
        getSelectedProviderSubscriptions({limit: entriesPerPage, offset:(page-1) * entriesPerPage});
        setActivePage(page)
      };
  return (
    <>
       <Row className="g-3">
       <Col xs="12" className="pt-3">
          <h4 className="m-0">Families</h4>
          <Breadcrumb className="border-bottom mb-3">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Subscribers</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col md="12">
        <Card>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSearch}>
              <Row>
                <Col md="4">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="family__name"
                    required
                  />
                </Col>
                <Col md="3">
                  <Form.Select
                    aria-label="select Chosen Level"
                    name="level"
                  >
                    <option value="">Choose Level</option>
                    <option value="First">First</option>
                    <option value="Second">Second</option>
                    <option value="Referral">Referral</option>
                  </Form.Select>
                </Col>
                
                <Col xs="1">
                  <Button variant="primary" type="submit" size="md">
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
        <Col md={12}>
            <Card>
                <Card.Body>
                <Card.Title>Subscribed Families </Card.Title>
                <Table striped bordered hover>
                <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Product</th>
                        <th>Level</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Members</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                        {subscriptions.map((item, i) => (
                            <tr>
                                <td>{i + 1+ (entriesPerPage * (activePage - 1))}</td>
                                <td>{item.offer.family_name}</td>
                                <td>{item.offer.product_name}</td>
                                <td>{item.level}</td>
                                <td>{item.offer.start_date}</td>
                                <td>{item.offer.end_date}</td>
                                <td>{item.offer.members}</td>
                                <td>{item.offer.status}</td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                        ))}
                        
                    </tbody>
                </Table>
                <Pager
                      numberOfPages={numberOfPages}
                      activePage={activePage}
                      goToPage={goToPage}
                    />
                </Card.Body>
            </Card>
        </Col>
        </Row> 
    </>
  )
}

export default ProviderFamilyList