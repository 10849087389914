import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form, FloatingLabel, Spinner, Alert } from 'react-bootstrap';
import claimsService from '../../services/provider/claims-service';
import subscriptionService from '../../services/subscription/subscription-service'
import AuthDetailContext from '../common/AuthDetailContext';
import providerService from '../../services/provider/provider-service';
import { formatHttpErrorMessage } from '../common/helper_functions';
import { useHistory } from "react-router-dom";

function ClaimForm(props) {
    const policy = props.policy;
    const [facility, setFacility] = useState(props.facility);
    const currentUser = useContext(AuthDetailContext);
    const history = useHistory();
    const [member, setMember] = useState(props.memberGuid);
    const [subscheme, setSubscheme] = useState(props.subscheme);
    const [visit, setVisit] = useState("OutPatient");
    const [ward, setWard] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [patientState, setPatientState] = useState("Discharged");
    const [isReferral, setIsReferral] = useState("No");
    const [referralRef, setReferralRef] = useState("");
    const [admissionDate, setAdmissionDate] = useState([]);
    const [dischargeDate, setDischargeDate] = useState("");
    const [billAmount, setBillAmount] = useState(0);
    const [copay, setCopay] = useState(policy.copay_opd);
    const [amount, setAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [cash, setCash] = useState(0);
    const [ceiling, setCeiling] = useState(policy.ceiling_opd)
    const [penalty, setPenalty] = useState(0);
    const [billType, setBillType] = useState("Service")
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [message, setMessage] = useState();
    const [policyRule, setPolicyRule] = useState({"min_cover":0, "min_cover_ipd":0,"ceiling_opd":0,"ceiling_ipd":0,"product":{}});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState([])
    
    useEffect(() => {
        setLoading(false);
        getPolicyRules(facility, member);
        getProvidersList();
    }, [member]);


const getProvidersList = async () => {
    await providerService.getAllProviders(0,100).then(
        (response) => {
            setProviders(response.results);
        });
}
const getPolicyRules = (facility, member) => {
    if(facility === undefined){
        return;
    }
    subscriptionService.retrieveMemberRules(facility, member).then((response) => {
        setPolicyRule(response.data)
        setCopay(response.data.min_cover);
        setCeiling(response.data.ceiling_opd);
        setShowForm(true);
        setMessage("")
    }).catch(error => {
        let msg = formatHttpErrorMessage(error)
         setMessage(msg)
    });
}

const onProviderChange = (choice) =>{
    setProvider(choice)
    getPolicyRules(choice, member);
}

const onVisitChange = (choice) => {
    setVisit(choice);
    if(choice =='OutPatient'){
        setCopay(policyRule.min_cover);
        setCeiling(policyRule.ceiling_opd);
    }else{
        setCopay(policyRule.min_cover_ipd);
        setCeiling(policyRule.ceiling_ipd);
    }
}

   const onBillAmountChange = (currentBill) => {
       setBillAmount(currentBill)
       if(policyRule.bill_type =='copayment'){
            if(parseInt(currentBill) <= copay){
                setAmount(0);
                setAmountPaid(currentBill)
            }else{
                let claimAmount = currentBill - copay;
                if(claimAmount >= ceiling){
                    claimAmount = ceiling - copay;
                }
                setAmount(claimAmount);
                setAmountPaid(billAmount - claimAmount);
            }
            
       }else{
            if(parseInt(currentBill) <= copay){
                setAmount(0);
                setAmountPaid(currentBill)
            }else{
               
                let claimAmount = currentBill - copay;
                if(claimAmount >= ceiling){
                    claimAmount = ceiling;
                }
                setAmount(claimAmount);
                setAmountPaid(billAmount - claimAmount);
            }
       }
       
      
    }

    const generateUniqueId = () => {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);
	};

const handleSubmit = (event) => {
    const form = event.currentTarget;
    let invalid = false;
    
    event.preventDefault();
    if (form.checkValidity() === false) {
        //event.preventDefault();
        event.stopPropagation();
        invalid = true;
    }

    setValidated(true);
    setLoading(true);
    setShowForm(false);

    let item = {
        guid: generateUniqueId(),
        visit:visit,
        ward: ward,
        diagnosis: diagnosis,
        patient_state: patientState,
        is_referral: isReferral,
        referral_ref: referralRef,
        admission_date: admissionDate,
        discharge_date: dischargeDate,
        amount: amount,
        bill_amount: billAmount,
        copay: copay,
        amount_paid: amountPaid,
        penalty,
        subscheme: subscheme,
        member: member,
        provider:provider
    };

    claimsService.createClaim(item).then(
            (result) => {
                //redirect to family 
                const fId = result.id;
                form.reset();
                //setShowForm(true);
                setLoading(false);
                setIsSubmitted(true);
                // setMessage("Claim has been posted successfully with reference number " + fId)
                history.push(`/claims/${fId}`);
                props.onHide()

               
            }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setMessage(msg)
            setLoading(false);
            setShowForm(true);
        });
    
};
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      bg="primary"
     
    >
      <Modal.Header closeButton bg="primary">
        <Modal.Title id="contained-modal-title-vcenter" >
          Claim Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && <Alert variant='danger'>{message}</Alert>}
        <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                
        {showForm &&   <Row className="gx-3 gy-3">
        <p>Fill in the form below {policyRule.bill_type}</p>
                             
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Provider"
                                        >
                                            <Form.Select
                                                aria-label="select Provider"
                                                value={provider}
                                                onChange={(e) =>
                                                    onProviderChange(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option></option>
                                                {providers && providers.map((item, i) => (<option value={item.id} key={i}>{item.name}</option>))}
                                                
                                                
                                                
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select visit.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                
                                <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Admission Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                placeholder="admission Date"
                                                value={admissionDate}
                                                onChange={(e) =>
                                                    setAdmissionDate(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Diagnosis"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Diagnosis"
                                                value={diagnosis}
                                                onChange={(e) =>
                                                    setDiagnosis(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
                                <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Visit"
                                        >
                                            <Form.Select
                                                aria-label="select Vist"
                                                value={visit}
                                                onChange={(e) =>
                                                    onVisitChange(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="OutPatient">OutPatient</option>
                                                <option value="InPatient">InPatient</option>
                                               
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select visit.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Ward"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Ward"
                                                value={ward}
                                                onChange={(e) =>
                                                    setWard(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Ward
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                    <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Is referral"
                                        >
                                            <Form.Select
                                                aria-label="is_referral"
                                                value={isReferral}
                                                onChange={(e) =>
                                                    setIsReferral(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                               
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                  <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Referral Number"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Referral Number"
                                                value={referralRef}
                                                readOnly={isReferral == 'No'}
                                                onChange={(e) =>
                                                    setReferralRef(
                                                        e.target.value
                                                    )
                                                }
                                            
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>
                                   
                                  
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Discharge Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                placeholder="Discharge Date"
                                                value={dischargeDate}
                                                onChange={(e) =>
                                                    setDischargeDate(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Patient State"
                                        >
                                            <Form.Select
                                                aria-label="select Patient State"
                                                value={patientState}
                                                onChange={(e) =>
                                                    setPatientState(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Discharged">Discharged</option>
                                                <option value="Referred">Referred</option>
                                                <option value="Dead">Dead</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select visit.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Billing Type"
                                        >
                                            <Form.Select
                                                aria-label="select Vist"
                                                value={billType}
                                                onChange={(e) =>
                                                    setBillType(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >   <option></option>
                                                <option value="Flat">Flat Rate</option>
                                                <option value="Service">Fee for Service</option>
                                                <option value="Capitation">Capitation</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select visit.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Bill Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Minimum Amount"
                                                value={billAmount}
                                                onChange={(e) =>
                                                    setBillAmount(
                                                        e.target.value
                                                    )
                                                }
                                                onBlur={(e) => onBillAmountChange(e.target.value)}
                                                
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Amount.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                        <em><strong>Ceiling:</strong> {ceiling}</em>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Start Cover"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Minimum Amount"
                                                value={copay}
                                                readOnly={!currentUser.is_staff}
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Claim Amount"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Claim Amount"
                                                value={amount}
                                                onChange={(e) =>
                                                    setAmount(
                                                        e.target.value
                                                    )
                                                }
                                                disabled={billType !='Flat'}
                                            />
                                            
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Out of Pocket"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Out of Pocket"
                                                value={amountPaid}
                                                readOnly={!currentUser.is_staff}
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Total Cash Receivable"
                                            className=""
                                        >
                                            <Form.Control
                                                type="number"
                                                placeholder="Total Cash Receivable"
                                                value={parseInt(cash) + parseInt(amountPaid)}
                                                readOnly={!currentUser.is_staff}
                                            />
                                           
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="12" className="d-grid gap-2">
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            disabled={parseInt(amount) <= 0 }
                                            onClick={() => setShowForm(false)}
                                        >
                                            Continue
                                        </Button>
                                    </Col>

                                   
                                </Row>}
                            {showForm == false && <>
                            <Col md={12}>
                                <p>You are posting for Claims with details below.</p>
                                <p><strong>Bill Amount</strong>: {parseInt(billAmount).toLocaleString()}<br/>
                                <strong>Claim Amount</strong>: {amount.toLocaleString()}<br/>
                                <strong>Amount received in Cash: </strong>{cash.toLocaleString()}<br/>
                                </p>
                                </Col>
                                <Col md="12" className="d-grid gap-2">
                                {isSubmitted===false &&    <Button
                                            variant="primary"
                                            type="submit"
                                            size="lg"
                                            disabled={loading}
                                        >
                                            Submit Claim
                                        </Button>} 
                                    </Col></>}
                            </Form> 
                {loading && <center><Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner></center>}
      </Modal.Body>
    </Modal>
  )
}

export default ClaimForm