import { useEffect, useState } from "react";
import schemeService from "../../services/scheme/scheme-service";
import BarGraph from "./BarGraph";

export default function SchemeGraphs() {
    const [genderData, setGenderData] = useState({});
    const [statusData, setStatusData] = useState({});
    const [labels, setLabels] = useState([]);
    const [statusLabels, setStatusLabels] = useState([]);
    const [showGraph, setShowGraph] =  useState(false);
    useEffect(() => {
        loadSchemeMemberSummary();  
    }, []);

const loadSchemeMemberSummary = () => {
    schemeService.getSchemeMemberGenderSummary().then(
        (data) => {
          let my_labels = [];
          let females = [];
          let males = [];
            my_labels = data.map((item) => item.scheme.shortname);
            data.forEach((item) =>{
              females.push(item.summary.female);
              males.push(item.summary.male);
            })
            
            let graph_data = {
              labels:my_labels,
                datasets: [
                  {
                    label: 'Male',
                    data: males,
                    backgroundColor: 'rgba(255, 99, 132, 0.8)',
                  },
                  {
                    label: 'Female',
                    data: females,
                    backgroundColor: 'rgba(53, 162, 235, 0.8)',
                  },
                ],
              }
            setGenderData(graph_data);
            setShowGraph(true);
            
        }
    )
     
}

  return (
    <div>
        <div style={{minHeight:600, overFlow:'scroll', maxWidth:1000}}>
        {Object.keys(genderData).length > 0 &&  <BarGraph data={genderData} title="Member Gender Graph" key={1} />}
        </div>
    </div>
  )
}
