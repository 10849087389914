import { useEffect, useState, useContext } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Spinner,
    Pagination,
} from "react-bootstrap";
import helperService from "../../services/helpers/helper-service";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import providerService from "../../services/provider/provider-service";
import AsyncSelect from 'react-select/async';
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";

const Providers = (props) => {
    const userData = useContext(AuthDetailContext);
    const { setAlert } = useContext(AlertContext);
    const {familyId,searchName} = props.match.params;    
    const [providers, setProviders] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [providerName, setProviderName] = useState("");
    const [providerBeds, setProviderBeds] = useState(0);
    const [providerDistrict, setProviderDistrict] = useState("");
    const [providerSubcounty, setProviderSubcounty] = useState("");
    const [providerParish, setProviderParish] = useState("");
    const [providerVillage, setProviderVillage] = useState("");
    const [providerLevel, setProviderLevel] = useState(1);
    const [providerBilling, setProviderBilling] = useState("Service");
    const [providerFirstContact,setProviderFirstContact] = useState(undefined);
    const [providerStartDate,setProviderStartDate] = useState(undefined);
    const [providerExpiryDate,setProviderExpiryDate] = useState(undefined);
    const [providerStatus,setProviderStatus] = useState("Active");
    const [validated, setValidated] = useState(false);
    const [providerOwnership, setProviderOwnership] = useState("Private");
    const [districts, setDistricts] = useState([]);
    const [counties, setCounties] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [providerCounty, setProviderCounty] = useState("");
    const [searchDistrictInput,setSearchInput] = useState("");
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage,setEntriesPerPage] = useState(50);
    const history = useHistory();

    useEffect(() => {
        loadProviders();
        loadDistricts();
       
    }, []);
    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const loadProviders = (nextPage=1,limit=50) => {
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
        providerService.getAllProviders(offset,limit).then(
            (result) => {
                setProviders(result.results);
                const pages = (result.count > 0) ? Math.ceil(result.count/entriesPerPage) : 0
                setNumberOfPages(pages);               
                setActivePage(nextPage);
                setLoading(false);
                
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };  

    const loadDistricts = () => {
        helperService.getAllDistricts().then(
            (districts) => {
                setDistricts(districts);
            },
            (error) => {
                const resMessage = formatHttpErrorMessage(error);

                setAlert(resMessage, "danger");
                setLoading(false);
            }
        );
    };
  
    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            providerService.deleteProvider(id).then(
                () => {
                    setAlert("Successfully Deleted provider", "success");
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert("A error Occurred!", "danger");
                }
            );
        } else {
        }
    };

    const goToPage = (event,page) => {
        loadProviders(page);
    }

    const filterDistricts = (inputValue) => {
        return districts.filter(district =>
            district.name.includes(String(inputValue).toUpperCase())
          )
      };

    
      const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
          callback(filterDistricts(inputValue));
        }, 100);
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setSearchInput(inputValue);        
        return inputValue;
      };  

      const handleDistrictChange = (newValue) => {
        console.log(newValue);
        if(newValue !== null){
            setProviderDistrict(newValue.name);
            //get subcounties by name
            helperService.getCountyByDistrictId(newValue.id).then(
                (counties) => {
                    setCounties(counties)
                },
                (error) => {

                }
            )
        }
        
       
      }; 
      
      const handleCountyChange = (event) => {
        console.log(event.target.options[event.target.selectedIndex].text)
        setProviderCounty(event.target.options[event.target.selectedIndex].text)
        //get subcounties by name
        helperService.getSubcountyByCountyId(event.target.value).then(
            (subcounties) => {
                setSubcounties(subcounties)
            },
            (error) => {

            }
        )
        
      };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let provider = {
                name: providerName,
                status: providerStatus,
                bed_no: providerBeds,
                level: providerLevel,
                bill_method: providerBilling,
                district: providerDistrict,
                county: providerCounty,
                subcounty: providerSubcounty,
                parish: providerParish,
                village: providerVillage,
                ownership: providerOwnership,
                first_contact: providerFirstContact,
                start_date: providerStartDate,
                expiry_date: providerExpiryDate,
            };
            providerService.addProvider(provider).then(
                (result) => {
                    setToastVariant("light");
                    setShowToast(true);
                    setToastMessage("Successfully created provider");
                    setProviderName("");
                    setProviderLevel(1);
                    setProviderDistrict("");
                    setProviderSubcounty("");
                    setProviderParish("");
                    setProviderVillage("");
                    setProviderBilling("Service");
                    setProviderStatus("Active");

                    setShowForm(false);
                    loadProviders();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setToastVariant("danger");
                    setShowToast(true);
                    setToastMessage(resMessage);
                    setLoading(false);
                }
            );
        }
    };

    return (
        <Row className="g-3">
            {!props.basic &&
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Providers</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            }
            <Col xs="12">
                <Card>
                
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">Providers </h4>
                        
                        <div>
                            <Button
                                variant="outline-info"
                                size="sm"
                                onClick={history.goBack}
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={toggleForm}
                            >
                                Add
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {showForm && (
                <Col xs="12">
                    <Card>
                        <Card.Body>
                            <Card.Subtitle className="mb-3">
                                Add new Provider
                            </Card.Subtitle>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="g-3">
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="schemeName"
                                                value={providerName}
                                                onChange={(e) =>
                                                    setProviderName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Number of Beds"
                                        >
                                            <Form.Control
                                                aria-label="select Network"
                                                type="number"
                                                value={providerBeds}
                                                onChange={(e) =>
                                                    setProviderBeds(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                              
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid number
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Level"
                                        >
                                            <Form.Select
                                                aria-label="select"
                                                value={providerLevel}
                                                onChange={(e) =>
                                                    setProviderLevel(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="1">I</option>
                                                <option value="2">II</option>
                                                <option value="3">III</option>
                                                <option value="4">IV</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Ownership"
                                        >
                                            <Form.Select
                                                aria-label="select"
                                                value={providerOwnership}
                                                onChange={(e) =>
                                                    setProviderOwnership(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Private">
                                                    Private
                                                </option>
                                                <option value="Public">
                                                    Public
                                                </option>
                                                <option value="PNP">
                                                    Private Non Profit
                                                </option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Billing Method"
                                        >
                                            <Form.Select
                                                aria-label="select"
                                                value={providerBilling}
                                                onChange={(e) =>
                                                    setProviderBilling(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Service">
                                                    Fee for service
                                                </option>
                                                <option value="Flat">
                                                    Flat Fees
                                                </option>
                                                <option value="Capitation">
                                                    Capitation
                                                </option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Status"
                                        >
                                            <Form.Select
                                                aria-label="select"
                                                value={providerStatus}
                                                onChange={(e) =>
                                                    setProviderStatus(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="Active">
                                                    Active
                                                </option>
                                                <option value="Inactive">
                                                    Inactive
                                                </option>
                                                <option value="Suspended">
                                                    Suspended
                                                </option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="First Contact"
                                        >
                                            <Form.Control
                                                aria-label="first contact"
                                                type="date"
                                                value={providerFirstContact}
                                                onChange={(e) =>
                                                    setProviderFirstContact(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                              
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid date
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Start Date"
                                        >
                                            <Form.Control
                                                aria-label="start date"
                                                type="date"
                                                required
                                                value={providerStartDate}
                                                onChange={(e) =>
                                                    setProviderStartDate(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                              
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid date
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Expiry Date"
                                        >
                                            <Form.Control
                                                aria-label="expiry date"
                                                type="date"
                                                value={providerExpiryDate}
                                                onChange={(e) =>
                                                    setProviderExpiryDate(
                                                        e.target.value
                                                    )
                                                }
                                                
                                            >
                                              
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please input valid date
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                    <AsyncSelect
                                                cacheOptions
                                                isClearable
                                                placeholder="select district"
                                                loadOptions={loadOptions}
                                                defaultOptions={districts}
                                                getOptionLabel={(option) => `${option.name}`}
                                                getOptionValue={(option) => `${option['id']}`}
                                                onInputChange={handleInputChange}
                                                onChange={handleDistrictChange}
                                                />
                                            <Form.Control.Feedback type="invalid">
                                                Please select district.
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Please select district.
                                            </Form.Control.Feedback>
                                       
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select County"
                                        >
                                            <Form.Select
                                                aria-label="select County"
                                                
                                                onChange={(e) =>
                                                    handleCountyChange(
                                                        e
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {counties.map(
                                                    (county) => (
                                                        <option
                                                            key={county.id}
                                                            value={
                                                                county.id
                                                            }
                                                        >
                                                            {county.name}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select subcunty.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Subcounty"
                                        >
                                            <Form.Select
                                                aria-label="select Subcounty"
                                                value={providerSubcounty}
                                                onChange={(e) =>
                                                    setProviderSubcounty(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">None</option>
                                                {subcounties.map(
                                                    (subcounty) => (
                                                        <option
                                                            key={subcounty.id}
                                                            value={
                                                                subcounty.name
                                                            }
                                                        >
                                                            {subcounty.name}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select subcunty.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Parish"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="parish"
                                                value={providerParish}
                                                onChange={(e) =>
                                                    setProviderParish(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid parish
                                                name.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Village"
                                            className=""
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="village"
                                                value={providerVillage}
                                                onChange={(e) =>
                                                    setProviderVillage(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid village
                                                name.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col xs="12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            )}
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Card.Title>Current Providers </Card.Title>
                        <div className="table-responsive">
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Level</th>
                                        <th>Ownership</th>
                                        <th>Status</th>
                                        <th>Bill Method</th>
                                        <th>District</th>
                                        <th>Subcounty</th>
                                        <th>Parish</th>
                                        <th>Village</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <Spinner
                                            animation="border"
                                            variant="warning"
                                        />
                                    )}
                                    {providers.map((provider, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{provider.name}</td>
                                            <td>{provider.level}</td>
                                            <td>{provider.ownership}</td>
                                            <td>{provider.status}</td>
                                            <td>{provider.bill_method}</td>
                                            <td>{provider.district}</td>
                                            <td>{provider.subcounty}</td>
                                            <td>{provider.parish}</td>
                                            <td>{provider.village}</td>
                                            <td>
                                                {!props.basic &&
                                                <div>
                                            {userData && userData.is_superuser && 
                                                <ButtonGroup
                                                    aria-label="Actions"
                                                    size="sm"
                                                >
                                                    <Button variant="warning">
                                                        <Link
                                                            to={`/providers/${provider.id}/edit`}
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {" "}
                                                            Details
                                                        </Link>
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                provider.id
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </ButtonGroup>
                                                }
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <Pagination>
                        {(() => { let items = [];
                        for (let number = 1; number <= numberOfPages; number++) {
                            items.push(
                                <Pagination.Item onClick={(e) => {
                                    goToPage(
                                        e,
                                        number
                                    );
                                }} key={number} active={number === activePage}>
                                {number}
                                </Pagination.Item>
                                );
                            }
                            return items;
                        })()
                        }
                        </Pagination>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default Providers;
